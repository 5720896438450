import React from "react";
import NavBar from "../../components/NavBar";
import { Container, Row } from "reactstrap";
import UserSidebarChat from "./UserSidebarChat";

function ChatModule() {
  return (
    <>
      <Container fluid className="overflow-hidden">
        <Row>
          <NavBar />
        </Row>
        <div className="contai">
          <UserSidebarChat />
        </div>
      </Container>
    </>
  );
}

export default ChatModule;
