import React, { useContext, useEffect, useState } from "react";
import CoursePreview from "./CoursePreview";
import { PurchasedCoursesApi } from "../../constants/API";
import axios from "axios";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { Language } from "../../App";

const PurchasedCourses = () => {
  const { user } = JSON.parse(sessionStorage.getItem("profileData")) || {};
  const user_id = user?.id;
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  const { lang } = useContext(Language);

  const fetchPurchasedCourses = () => {
    setSpin(true);

    fetch(`${PurchasedCoursesApi}${user_id}/${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setData(data.data.data);
        } else {
          toast.error(data.action);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
        toast.error(error.message || lang?.an_error_occured_fetching_date);
      })
      .finally(() => {
        setSpin(false);
      });
  };

  useEffect(() => {
    if (user_id) {
      fetchPurchasedCourses();
    }
  }, [user_id]);

  return (
    <>
      {spin ? (
        <div className="profilejobspinner align-items-start">
          <Spinner />
        </div>
      ) : (
        <>
          <div>
            <h1 className="fs-20 fs-md-16 roboto-medium">{lang?.purchased_courses}</h1>
          </div>
          <div
            className="d-flex gap-2"
            style={{ flexWrap: "wrap", flexDirection: "row" }}
          >
            {data?.map((course, index) => (
              <div key={index}>
                <CoursePreview
                  certificates={course.certificates}
                  owner_username={course.owner_username}
                  owner_image={course.owner_image}
                  thumbnail={course.thumbnail}
                  image={course.cover}
                  price={course.price}
                  name={course.title}
                  course_id={course.id}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default PurchasedCourses;
