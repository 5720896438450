import React, { Fragment, useContext, useEffect, useState } from "react";
import EventPreview from "./EventPreview";
import { JoinedEventsApi } from "../../constants/API";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { Language } from "../../App";

const JoinedEvents = () => {
  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;

  const [eventsData, setEventsData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [status, setStatus] = useState("0");
  const { lang } = useContext(Language);

  const handleEvents = () => {
    setSpin(true);
    fetch(`${JoinedEventsApi}${user_id}/${status}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setEventsData(data.data);
        } else {
          toast.error(data.error);
        }
        setSpin(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    handleEvents(formattedDate);
  }, [status]);
  return (
    <>
      <div>
        <h1 className="fs-20 fs-md-16 roboto-medium">{lang?.joined_events}</h1>
      </div>
      <div className="d-flex mb-5 mt-3 justify-content-center">
        <button
          onClick={() => {
            setStatus("0");
          }}
          className={`px-lg-5 px-md-4 px-sm-3 px-3 fs-18 fs-md-16 roboto-medium br-tl-12 br-bl-12 py-2 border border-1 border-inherit ${
            status === "0" ? "text-white bg-blue" : "bg-white text-black"
          }`}
        >
          {lang?.upcoming}
        </button>
        <button
          onClick={() => {
            setStatus("1");
          }}
          className={`px-lg-5 px-md-4 px-sm-3 px-3 fs-18 fs-md-16 roboto-medium br-tr-12 br-br-12 py-2 border border-1 border-inherit ${
            status === "1" ? "text-white bg-blue" : "bg-white text-black"
          }`}
        >
          {lang?.completed}
        </button>
      </div>

      {spin ? (
        <div className="profilejobspinner align-items-start">
          <Spinner />
        </div>
      ) : (
        eventsData?.map((value, index) => {
          return (
            <>
              <div key={index} className="my-4">
                <h2 className="fs-20 fs-md-16 roboto-regular">
                  {formatDate(value.date)}
                </h2>
              </div>
              <div className="d-flex gap-2 overflowX-auto scroll-hide">
                {value.data &&
                  value?.data.map((event) => (
                    <Fragment key={event.id}>
                      <EventPreview
                        id={event.id}
                        image={event.cover}
                        title={event.title}
                        location={event.short_location}
                        time={event.start_timestamp}
                        available={event.availability}
                      />
                    </Fragment>
                  ))}
              </div>
            </>
          );
        })
      )}
    </>
  );
};

export default JoinedEvents;

function formatDate(dateString) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}
