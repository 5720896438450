// CustomTooltip.js
import React, { useState } from "react";

const CustomTooltip = ({
  text,
  children,
  compClassName,
  topMargin,
  account_class,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={`custom-tooltip ${compClassName}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
          className={`tooltip-text px-3 py-1 rounded-pill position-absolute ${
            account_class ? account_class : ""
          }`}
          style={{
            whiteSpace: "nowrap",
            marginTop: topMargin ? topMargin : "1.3rem",
            backgroundColor: "rgba(53,53,53,0.82 )",
            filter: "drop-shadow(0px 0px 3px rgba(0,0,0,0.5 ))",
            boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.16 )",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
