import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { Language, Sponsorpost } from "../../App";
// import { CONTEST_LIST } from "../../constants/RoutesUrl";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar";
import { CONTEST_DETAILS } from "../../constants/RoutesUrl";

// Individual contest item component
const ContestItem = ({ title, content }) => {
  const { postImage } = useContext(Sponsorpost);
  return (
    <Col md={12} className="border border-1 border-inherit rounded p-2">
      <h5 style={{ color: postImage?.color_code }} className="roboto-bold">
        {title}
      </h5>
      <p
        className="roboto-medium mb-0"
        dangerouslySetInnerHTML={{ __html: content }}
      ></p>
    </Col>
  );
};

// Contest details component
const ContestDetails = ({ details }) => (
  <Row className="justify-content-center gap-3 m-3">
    {details.map((item, index) => (
      <ContestItem key={index} {...item} />
    ))}
  </Row>
);

const NewContest = () => {
  const { lang } = useContext(Language);
  const Navigate = useNavigate();
  const { postImage } = useContext(Sponsorpost);
  const contestDetails = [
    {
      title: "CALENDARIO",
      content:
        "-Fecha limite 15 mayo <br/> -Comunicación de los finalistas: 31 de mayo  <br/> -Presentación 20 junio online (plataforma MR)  <br/> -El jurado decidirá in situ la clasificación",
    },
    {
      title: "REQUISITOS",
      content:
        "El contenido del caso sobre Uveitis debe ser: <br/> -Anónimo <br/> -Formato PDF <br/> -Estructura: titulo, Introducción, Caso, Conclusiones <br/> -Se debe aportar iconografía relevante <br/> -Tamaño 3 páginas",
    },
    {
      title: "3 PREMIOS",
      content:
        "-1er PREMIO Inscripción y presentación del caso en congreso SEIOC (14-15/11 Barcelona) <br/> -2° PREMIO Inscripción congreso SEIOd <br/> -3er PREMIO Inscripción congreso SEIOC",
    },
    {
      title: "JURADO",
      content:
        "Dr. Alex Fonollosa (Hospital Universitario Cruces, Universidad del País Vasco Instituto Oftalmológico Bilbao) <br/> Dra. Ester Carreño (Vicepresidenta SEIOC) <br/> Dra. Carmen Alba (Secretaria SEIOC)",
    },
  ];

  return (
    <>
      <Container fluid className="vh-100 overflowY-auto scroll-hide bg-grey">
        <Row>
          <NavBar />
        </Row>
        <Row className="justify-content-center space-top">
          <Col lg={6} className="py-3">
            <div className="br-16 bg-white py-3">
              <Row>
                <Col className="text-center">
                  <h5 className="mb-0 roboto-bold">CONCURSO UVEÍTIS SEIOC</h5>
                </Col>
              </Row>
              <ImageComponent
                src={postImage?.image}
                compClassName="img-fluid rounded"
                loader_height="405px"
              />
              <ContestDetails details={contestDetails} />
              <Col md={12} className="text-center">
                <button
                  className="btn-blue border-0 w-75 py-3"
                  style={{ backgroundColor: postImage?.color_code }}
                  onClick={() => {
                    Navigate(CONTEST_DETAILS + "CONCURSO UVEÍTIS SEIOC");
                  }}
                >
                  {lang?.participate}
                </button>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewContest;
