import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { BaseURL } from "../../constants/API";
import { MdKeyboardArrowRight } from "react-icons/md";

const SubcategoryCards = () => {
  const { home } = JSON.parse(sessionStorage.getItem("SplashData"));
  const [splashData, setSplashCard] = useState([]);

  useEffect(() => {
    setSplashCard(home?.subcategories || []);
  }, []);

  const renderCard = (group, groupIndex) => (
    <Col
      className="my-2 br-12"
      key={`${group[1].name}-${groupIndex}`}
      style={{
        border: `2px solid rgba(${group[0]} 1)`,
        backgroundColor: `rgba(${group[0]} 0.12)`,
        height: "101px",
        minWidth: "192px",
      }}
    >
      <div>
        {/* Title */}
        <div className="position-relative" style={{ paddingBottom: "25%" }}>
          <h1
            className="fs-14 roboto-medium pb-3 ps-3 position-absolute"
            style={{ color: `rgba(${group[0]} 1)`, paddingTop: "0.7rem" }}
          >
            {group[1].name}
          </h1>
        </div>
        {/* Arrow and the Icon */}
        <div className="d-flex justify-content-between ps-3">
          <div className="align-self-end mb-4">
            <div
              className="d-flex align-items-center rounded-circle mt-3"
              style={{ backgroundColor: `rgba(${group[0]}1)` }}
            >
              <MdKeyboardArrowRight color="white" size={20} />
            </div>
          </div>
          <div>
            <div
              className="ps-2 pt-2"
              style={{
                backgroundColor: `rgba(${group[0]}0.2)`,
                borderRadius: "100% 0px 10px 0px",
              }}
            >
              <div
                className="ps-2 pt-2"
                style={{
                  backgroundColor: `rgba(${group[0]}0.5)`,
                  borderRadius: "100% 0px 10px 0px",
                  paddingLeft: "0.5rem",
                  paddingBottom: "0.3rem",
                }}
              >
                <img
                  src={BaseURL + group[1].image}
                  alt="picture"
                  width={25}
                  height={25}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );

  const itemsPerGroup = 1;
  const combinedData = [];

  for (let i = 0; i < splashData?.length; i += itemsPerGroup) {
    const cardIndex = i % CardData?.length;
    const group = [
      CardData[cardIndex],
      ...splashData.slice(i, i + itemsPerGroup),
    ];
    combinedData.push(group);
  }

  const midIndex = Math.floor(combinedData.length / 2);
  const firstHalf = combinedData.slice(0, midIndex);
  const secondHalf = combinedData.slice(midIndex);

  return (
    <div className="scroll-hide overflowX-auto overflowY-hidden">
      <div className="d-flex gap-3">
        {firstHalf.map((group, groupIndex) => renderCard(group, groupIndex))}
      </div>
      <div className="d-flex gap-3">
        {secondHalf
          .reverse()
          .map((group, groupIndex) => renderCard(group, groupIndex))}
      </div>
    </div>
  );
};

SubcategoryCards.defaultProps = {
  home: {
    subcategories: [],
  },
};

const CardData = [
  "192, 88, 230,",
  "89, 129, 217,",
  "101, 186, 146,",
  "221, 146, 90,",
  "29, 193, 221,",
  "201, 109, 103,",
];

export default SubcategoryCards;
