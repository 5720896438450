import React, { useContext, useState } from "react";
import {
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";
// Components
import { BaseURL } from "../../constants/API.js";
import { CLINICAL_SESSIONS, FEED, PROFILE } from "../../constants/RoutesUrl.js";
// Images
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Language } from "../../App.js";

const AddClinicalSession = () => {
  const { lang } = useContext(Language);

  const Navigate = useNavigate();
  // sessionStorage
  const SplashData = JSON.parse(sessionStorage.getItem("SplashData"));
  const subcategories = SplashData?.home?.subcategories;
  const user_id = SplashData?.user?.id;

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [invitePage, setInvitePage] = useState(1);
  const [inviteSpin, setInviteSpin] = useState(false);
  const [clinicalId, setClinicalId] = useState("");
  const [searchArr, setSearchArr] = useState([]);
  const [formInput, setFormInput] = useState({
    caption: null,
    schedule_date: "",
    schedule_time: null,
    subcategory_id: null,
    title: null,
    visibility: null,
    duration: 0,
    type: "clinical-session",
  });
  const [inviteData, setInviteData] = useState({});

  // Functions
  function isValidFutureDate(inputDate) {
    // Parse the input date
    const userDate = new Date(inputDate);

    // Check if the parsed date is a valid date
    if (isNaN(userDate.getTime())) {
      toast.error(lang?.error_note_number);
      return false;
    }

    // Get today's date
    const today = new Date();

    // Set the time to midnight for both dates to compare only the dates
    userDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // Compare the dates
    if (userDate < today) {
      toast.error(lang?.date_should_greater_todays_date);
      return false;
    }
    return true;
  }

  const handleInvitationList = (clinical_id) => {
    setIsLoading(true);
    axios
      .get(
        BaseURL +
          "api/clinical-session/users/" +
          clinical_id +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(invitePage)
      )
      .then((res) => {
        if (inviteList.length) {
          setInviteList(inviteList.concat(res.data.data.data));
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          return 0;
        }
        if (res.data.status) {
          setInviteList(res.data.data.data);
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          setInviteSpin(false);
        } else {
          toast.error(res.data.error);
          setInviteSpin(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInvite = (userId) => {
    setIsLoading(true);
    axios
      .get(BaseURL + "api/clinical-session/invite/" + clinicalId + "/" + userId)
      .then((res) => {
        if (res.data.status) {
          const update = inviteList.map((value) => {
            if (value.id === userId) {
              return { ...value, invite: !value.invite };
            }
            return value;
          });
          setInviteList(update);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSearchInvite = (userId) => {
    setIsLoading(true);
    axios
      .get(BaseURL + "api/clinical-session/invite/" + clinicalId + "/" + userId)
      .then((res) => {
        if (res.data.status) {
          const update = searchArr.map((value) => {
            if (value.id === userId) {
              return { ...value, invite: !value.invite };
            }
            return value;
          });
          setSearchArr(update);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Converting to unixTimeStamp
    const combinedDateTimeString = `${formInput.schedule_date}T${formInput.schedule_time}`;
    const combinedDateTime = new Date(combinedDateTimeString);
    const start_timestamp = combinedDateTime.getTime();
    // converting to 12-hrs
    const schedule_time = combinedDateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    if (formInput.duration > 120) {
      toast.error(lang?.clinical_session_duration_must_not_greater_min);
      return 0;
    }

    if (formInput.duration < 0) {
      toast.error(lang?.enter_correct_time_limit);
      return 0;
    }

    if (combinedDateTime < new Date()) {
      toast.error(lang?.start_time_less_current_time);
      return 0;
    }

    isValidFutureDate(formInput.schedule_date);

    const data = {
      start_timestamp,
      type: formInput.type,
      caption: formInput.caption,
      schedule_date: formInput.schedule_date,
      schedule_time,
      category_id: "3",
      subcategory_id: formInput.subcategory_id,
      title: formInput.title,
      visibility: formInput.visibility,
      duration: formInput.duration,
    };
    setIsLoading(true);
    axios
      .post(BaseURL + "api/post/create/" + user_id, data)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setInviteModal(true);
          setInviteSpin(true);
          setClinicalId(res.data.post.id);
          handleInvitationList(res.data.post.id);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSearch = (value) => {
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(BaseURL + "api/clinical-session/user/search", {
          keyword: value,
          user_id,
          post_id: clinicalId,
        })
        .then((res) => {
          if (res.data.status) {
            setSearchArr(res.data.data.data);
          } else {
            toast.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 2000);
  };

  return (
    <>
      <Col
        xl={8}
        className="bg-white p-3 rounded border border-1 border-inherit"
      >
        <Row>
          <Col className="text-center">
            <h5 className="mb-0">{lang?.create_clinical_session}</h5>
          </Col>
        </Row>

        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Row className="my-3">
            <Col>
              <Label>{lang?.title} *</Label>
              <Input
                required
                type="text"
                placeholder={lang?.clinical_session_title}
                value={formInput.title}
                onChange={(e) => {
                  setFormInput({ ...formInput, title: e.target.value });
                }}
              ></Input>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Label>{lang?.category} *</Label>
              <Input
                required
                type="select"
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    subcategory_id: e.target.value,
                  });
                }}
              >
                <option value="">{lang?.chose_clinical_cat}</option>
                {subcategories &&
                  subcategories.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Label>{lang?.start_date} *</Label>
              <Input
                required
                type="date"
                onChange={(e) => {
                  setFormInput({ ...formInput, schedule_date: e.target.value });
                }}
              ></Input>
            </Col>
            <Col>
              <Label>{lang?.start_time} *</Label>
              <input
                required
                type="time"
                onChange={(e) => {
                  setFormInput({ ...formInput, schedule_time: e.target.value });
                }}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={6}>
              <Label>{lang?.time_limit} *</Label>
              <InputGroup>
                <Input
                  required
                  value={formInput?.duration}
                  type="number"
                  placeholder={lang?.clinical_session_duration_limit}
                  onChange={(e) => {
                    setFormInput({
                      ...formInput,
                      duration: e.target.value,
                    });
                  }}
                />
                <InputGroupText>{lang?.mins}</InputGroupText>
              </InputGroup>
            </Col>
            <Col sm={6} className="mt-sm-0 mt-3">
              <Label>{lang?.clinical_session_commitment} *</Label>
              <Input
                required
                type="select"
                onChange={(e) => {
                  setFormInput({ ...formInput, visibility: e.target.value });
                }}
              >
                <option value="">{lang?.choose_session_commitment}</option>
                <option value="One Time">{lang?.one_time}</option>
                <option value="Daily">{lang?.daily}</option>
                <option value="Weekly">{lang?.weekly}</option>
              </Input>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Label>{lang?.description} *</Label>
              <Input
                required
                type="textarea"
                placeholder={lang?.description}
                onChange={(e) => {
                  setFormInput({ ...formInput, caption: e.target.value });
                }}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                disabled={
                  isLoading ||
                  !formInput.caption ||
                  !formInput.duration ||
                  !formInput.schedule_date ||
                  !formInput.schedule_time ||
                  !formInput.title ||
                  !formInput.visibility
                }
                type="submit"
                className="btn-blue border-0 rounded w-100 py-3"
              >
                {isLoading ? <Spinner size="sm" /> : lang?.submit}
              </button>
            </Col>
          </Row>
        </Form>
      </Col>

      {/* Invite Modal */}
      <Modal isOpen={inviteModal} centered zIndex={9} scrollable size="lg">
        <ModalHeader
          toggle={() => {
            setInviteModal(false);
            window.location.reload();
          }}
        >
          {lang?.invite}
        </ModalHeader>
        <ModalBody>
          {inviteSpin ? (
            <Row>
              <Col className="text-center">
                <Spinner />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setSearchArr([]);
                      } else {
                        handleSearch(e.target.value);
                      }
                    }}
                  />
                </Col>
              </Row>
              <InfiniteScroll
                pageStart={0}
                loadMore={() => handleInvitationList(clinicalId)}
                hasMore={
                  inviteData?.current_page !== inviteData?.last_page
                    ? true
                    : false
                }
                loader={
                  <Row>
                    <Col className="text-center my-3">
                      <Spinner />
                    </Col>
                  </Row>
                }
                useWindow={false}
              >
                {searchArr && searchArr.length > 0 ? (
                  <Row>
                    {isLoading ? (
                      <Row>
                        <Col className="text-center my-3">
                          <Spinner />
                        </Col>
                      </Row>
                    ) : (
                      searchArr.map((value) => (
                        <Col xs={12}>
                          <div className="border border-1 border-inherit rounded my-2">
                            <div className="d-flex m-3 justify-content-between">
                              <div className="d-flex gap-2">
                                <Link
                                  to={PROFILE + "/" + value.id}
                                  className="bg-white dropdown-avatar-shadow align-self-center"
                                  style={{
                                    borderRadius: "19px",
                                    maxWidth: "4rem",
                                    maxHeight: "4rem",
                                    padding: "1.8px",
                                  }}
                                >
                                  <img
                                    src={
                                      value?.image
                                        ? BaseURL + value.image
                                        : Avatar
                                    }
                                    alt="picture"
                                    className="img-fluid "
                                    style={{ borderRadius: "17px" }}
                                  />
                                </Link>
                                <div className="align-self-center">
                                  <Link
                                    to={PROFILE + "/" + value.id}
                                    className="fs-14 roboto-bold text-dark"
                                  >
                                    {value.name}
                                  </Link>
                                  <p className="mb-0 fs-12 roboto-regular">
                                    {value.user_name}
                                  </p>
                                </div>
                              </div>
                              <div className="align-self-center">
                                <button
                                  className="btn btn-white border border-1 px-3 py-1"
                                  disabled={isLoading}
                                  onClick={() => {
                                    handleSearchInvite(value.id);
                                  }}
                                >
                                  <span className="roboto-medium">
                                    {value.invite ? lang?.undo : lang?.invite}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))
                    )}
                  </Row>
                ) : (
                  <Row>
                    {isLoading ? (
                      <Row>
                        <Col className="text-center my-3">
                          <Spinner />
                        </Col>
                      </Row>
                    ) : (
                      inviteList.map((value) => (
                        <Col xs={12}>
                          <div className="border border-1 border-inherit rounded my-2">
                            <div className="d-flex m-3 justify-content-between">
                              <div className="d-flex gap-2">
                                <Link
                                  to={PROFILE + "/" + value.id}
                                  className="bg-white dropdown-avatar-shadow align-self-center"
                                  style={{
                                    borderRadius: "19px",
                                    maxWidth: "4rem",
                                    maxHeight: "4rem",
                                    padding: "1.8px",
                                  }}
                                >
                                  <img
                                    src={
                                      value?.image
                                        ? BaseURL + value.image
                                        : Avatar
                                    }
                                    alt="picture"
                                    className="img-fluid "
                                    style={{ borderRadius: "17px" }}
                                  />
                                </Link>
                                <div className="align-self-center">
                                  <Link
                                    to={PROFILE + "/" + value.id}
                                    className="fs-14 roboto-bold text-dark"
                                  >
                                    {value.name}
                                  </Link>
                                  <p className="mb-0 fs-12 roboto-regular">
                                    {value.user_name}
                                  </p>
                                </div>
                              </div>
                              <div className="align-self-center">
                                <button
                                  className="btn btn-white border border-1 px-3 py-1"
                                  disabled={isLoading}
                                  onClick={() => {
                                    handleInvite(value.id);
                                  }}
                                >
                                  <span className="roboto-medium">
                                    {value.invite ? lang?.undo : lang?.invite}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))
                    )}
                  </Row>
                )}
              </InfiniteScroll>
            </>
          )}
        </ModalBody>
      </Modal>
      {/* End invite modal */}
    </>
  );
};

export default AddClinicalSession;
