import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import {
  GetSpecializationApi,
  PostSpecializationApi,
} from "../../constants/API";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FEED } from "../../constants/RoutesUrl";
import { Language } from "../../App";

const SpecializationComponent = () => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [specializationsData, setSpecializationsData] = useState([]);

  const handleClick = (buttonId, buttonName) => {
    const isSelected = selectedSpecializations.some(
      (item) => item.id === buttonId
    );

    if (isSelected) {
      setSelectedSpecializations(
        selectedSpecializations.filter((item) => item.id !== buttonId)
      );
    } else {
      setSelectedSpecializations([
        ...selectedSpecializations,
        { id: buttonId, name: buttonName },
      ]);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const category = "Ophthalmology";
    const subcategory = selectedSpecializations
      .map((item) => item.name)
      .join(", ");

    try {
      const localData = JSON.parse(localStorage.getItem("SignupData"));
      localData.data.subcategory = subcategory;

      localStorage.setItem("SignupData", JSON.stringify(localData));

      await axios.post(PostSpecializationApi + localData.data.id, {
        category,
        subcategory,
      });

      localStorage.setItem("SignupData", JSON.stringify(localData));
      navigate(FEED);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GetSpecializationApi);
        setSpecializationsData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const isDisableBtn = selectedSpecializations.length === 0;

  return (
    <Col lg={6} className="overflow-hidden">
      <Row>
        <Col lg={9} className="auth-bg my-5 ms-5">
          <h1 className="text-blue fs-40 roboto-bold">
            {lang?.chose_your_specialization}
          </h1>
          <p className="fs-20 roboto-regular">
            {lang?.get_personalized_content_recomdation}
          </p>

          <div className="my-4">
            {specializationsData.map((value) => (
              <button
                key={value.id}
                className={`rounded-pill fs-16 roboto-bold bg-white specialization-btn-shadow py-2 border-0 px-3 mx-2 my-3 ${
                  selectedSpecializations.some(
                    (item) => item.id === value.id
                  ) && "clicked"
                }`}
                onClick={() => handleClick(value.id, value.name)}
              >
                {value.name}
              </button>
            ))}
          </div>

          <Row className="justify-content-center mb-4">
            <Col lg={8}>
              <button
                type="submit"
                className={`py-3 btn-blue w-100 border-0 `}
                onClick={handleSubmit}
                disabled={isDisableBtn}
              >
                {isLoading ? <div className="loader"></div> : lang?.update}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SpecializationComponent;
