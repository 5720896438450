import React, { useCallback, useContext, useState } from "react";
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Cropper from "react-easy-crop";
import axios from "axios";
import Spinner from "../Spinner";
import getCroppedImg from "../../hooks/useCropImage";
import { AddCourseApi, BaseURL } from "../../constants/API";
import UploadIcon from "../../assets/images/icon/upload.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { COURSES } from "../../constants/RoutesUrl";
import { Language } from "../../App";

const AddCourse = () => {
  const Navigate = useNavigate();
  // sessionStorages data
  const user = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = user?.user?.id;
  // States
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [data, setData] = useState({});
  const [courseImagePreview, setCourseImagePreview] = useState(null);
  const [courseInput, setCourseInput] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);

  const [certificateIndex, setCertificateIndex] = useState(null);
  const [certificateModal, setCertificateModal] = useState(false);
  const [certificateImage, setCertificateImage] = useState("");
  const { lang } = useContext(Language);
  const [certificateInputData, setCertificateInputData] = useState({
    title: "",
    image: "",
    api_image: "",
  });
  const [certificateData, setCertificateData] = useState([]);

  // Functions
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCertificateSubmit = () => {
    if (certificateIndex !== null) {
      // Update an existing certificate at the specified index
      const updatedCertificateData = [...certificateData];
      updatedCertificateData[certificateIndex] = certificateInputData;
      setCertificateData(updatedCertificateData);
    } else {
      // Add a new certificate to the array
      setCertificateData((prevCertificateData) => [
        ...prevCertificateData,
        certificateInputData,
      ]);
    }

    // Clear the certificate input data and index
    setCertificateInputData({
      title: "",
      image: "",
      api_image: "",
    });
    setCertificateIndex(null);
    setCertificateModal(false);
  };

  const removeCertificateAtIndex = (indexToRemove) => {
    // Create a copy of the current certificateData array
    const updatedCertificateData = [...certificateData];

    // Remove the certificate at the specified index
    updatedCertificateData.splice(indexToRemove, 1);

    // Update the state with the modified array
    setCertificateData(updatedCertificateData);
  };

  const handleCropper = async () => {
    try {
      const croppedImage = await getCroppedImg(
        courseImagePreview,
        croppedAreaPixels
      );
      // Replace the Blob URL with your actual Blob URL
      const blobUrl = croppedImage;
      // Fetch the Blob content
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a FileReader to read the Blob as base64
          const reader = new FileReader();
          reader.onload = () => {
            // The result will be the Blob content as base64
            const base64Data = reader.result;
            setCroppedImage(base64Data);
          };
          // Read the Blob as data URL
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error(error));
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.subcategory || data.subcategory === "") {
      toast.error(lang?.select_course_subcategory_please);
      return;
    }

    const certificateTitles = certificateData.map(
      (certificate) => certificate.title
    );
    const commaSeparatedTitles = certificateTitles.join(", ");

    const certificateImages = certificateData.map(
      (certificate) => certificate.api_image
    );

    setIsLoading(true);
    axios
      .post(
        AddCourseApi,
        {
          user_id,
          title: data.title,
          short_description: data.short_description,
          price: data.price,
          category: "3",
          subcategory: data.subcategory,
          images: certificateData.api_image,
          cover_base64: croppedImage,
          titles: commaSeparatedTitles,
          certifiedImages: certificateImages,
          images: certificateImages,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          Navigate(COURSES + "/2");
          window.location.reload();
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((error) => {
        toast.error(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Row>
        {spin ? (
          <div className="profilejobspinner align-items-start">
            <Spinner />
          </div>
        ) : (
          <Col
            lg={7}
            className="bg-white p-4 ms-lg-5 ms-md-0 ms-sm-0 ms-0 rounded"
          >
            <Row className="pb-3">
              <Col className="text-center">
                <h3 className="fs-20 fs-md-16 roboto-bold">
                  {lang?.add_course}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                {croppedImage ? (
                  <div
                    className="position-relative cursor"
                    style={{
                      minHeight: "15rem",
                      borderRadius: "6px",
                      backgroundColor: "rgba(0,0,0,0.70)",
                    }}
                  >
                    <button
                      className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                      style={{ zIndex: "1" }}
                      onClick={() => {
                        const update = { ...data, cover: "" };
                        setData(update);
                        setCroppedImage("");
                      }}
                    >
                      x
                    </button>
                    <img
                      src={croppedImage}
                      className="w-100 br-16"
                      style={{ objectFit: "cover", height: "15rem" }}
                      onClick={() => setImagePreviewModal(true)}
                      alt="picture"
                    />
                  </div>
                ) : (
                  <div
                    className="cursor"
                    style={{
                      border: "1px dashed #A2A2A2",
                      backgroundColor: "#F2FCFE",
                      minHeight: "12rem",
                      borderRadius: "6px",
                    }}
                    onClick={() => {
                      document.getElementById("fileInput").click();
                    }}
                  >
                    <div className="text-center mt-5">
                      <img
                        src={UploadIcon}
                        width={40}
                        height={40}
                        alt="picture"
                      />
                      <h4
                        className="fs-14 roboto-medium pt-3"
                        style={{ color: "#00c5de" }}
                      >
                        {lang?.add_course_img}
                      </h4>
                    </div>
                  </div>
                )}
                <input
                  type="file"
                  id="fileInput"
                  className="d-none"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setCourseInput(file);
                    // Preview the image
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const update = { ...data, cover: reader.result };
                      setData(update);
                      setCourseImagePreview(reader.result);
                      setImageModal(true);
                    };
                    reader.readAsDataURL(file);
                  }}
                />
              </Col>
            </Row>
            <Form className="mt-3">
              <hr />
              <Row className="mb-3 py-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-15 roboto-medium">
                    {lang?.certified_by}
                  </label>
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn bg-gray text-black roboto-bold py-2 px-4 border border-2 my-2"
                      onClick={() => setCertificateModal(true)}
                    >
                      <b>{lang?.add_certificate}</b>
                    </button>
                    <div className="d-flex gap-4">
                      {certificateData?.map((value, index) => (
                        <div className="position-relative">
                          <span
                            className="fw-bold text-black px-1 rounded position-absolute end-0 bg-white border cursor"
                            onClick={() => removeCertificateAtIndex(index)}
                            style={{ marginTop: "-2%", marginRight: "-5%" }}
                          >
                            x
                          </span>
                          <button
                            type="button"
                            key={index}
                            className="btn bg-gray text-black roboto-bold py-2 px-3 border border-2 my-2"
                            onClick={() => {
                              setCertificateInputData(certificateData[index]);
                              setCertificateIndex(index);
                              setCertificateModal(true);
                            }}
                          >
                            {lang?.certificate} {index + 1}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />

              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-15 roboto-medium">
                    {lang?.course_title}
                  </label>
                  <input
                    type="text"
                    className="py-2 form-control"
                    value={data?.title}
                    placeholder={lang?.course_title}
                    onChange={(e) => {
                      const update = { ...data, title: e.target.value };
                      setData(update);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-15 roboto-medium">
                    {lang?.course_category}
                  </label>
                  <select
                    className="form-select py-2"
                    value={data.subcategory}
                    onChange={(e) => {
                      const update = {
                        ...data,
                        subcategory: e.target.value,
                      };
                      setData(update);
                    }}
                  >
                    <option>{lang?.select_category}</option>
                    {user &&
                      user.home &&
                      user.home.subcategories &&
                      user.home.subcategories.map((value, index) => (
                        <option value={value.id} key={index}>
                          {value.name}
                        </option>
                      ))}
                  </select>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-15 roboto-medium">
                    {lang?.course_price}
                  </label>
                  <input
                    type="number"
                    placeholder={lang?.course_price}
                    className="py-2 form-control"
                    value={data.price}
                    onChange={(e) => {
                      const update = { ...data, price: e.target.value };
                      setData(update);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-15 roboto-medium">
                    {lang?.description}
                  </label>
                  <textarea
                    value={data.short_description}
                    onChange={(e) => {
                      const update = {
                        ...data,
                        short_description: e.target.value,
                      };
                      setData(update);
                    }}
                    className="form-control"
                    placeholder={lang?.description}
                    cols="30"
                    rows="8"
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    disabled={
                      isLoading ||
                      !data.title ||
                      data.title.length > 150 ||
                      !data.short_description ||
                      !data.cover ||
                      !data.price ||
                      data.price > 999.99
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    className="btn-blue border-0 w-100 my-4 py-3"
                  >
                    {isLoading ? (
                      <div className="loader"></div>
                    ) : (
                      lang?.add_course
                    )}
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
      </Row>

      {/* image cropping  */}
      <Modal
        isOpen={imageModal}
        toggle={() => setImageModal(!imageModal)}
        centered
        zIndex={9}
      >
        <div className="bg-white px-3 br-16">
          <ModalHeader toggle={() => setImageModal(!imageModal)}>
            {lang?.image_croper}
          </ModalHeader>
          <div className="position-relative" style={{ minHeight: "40rem" }}>
            <Cropper
              image={
                courseImagePreview ? courseImagePreview : BaseURL + data.cover
              }
              crop={crop}
              zoom={zoom}
              aspect={0.86}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="d-none">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
          <ModalFooter className="gap-3">
            <button
              className="btn btn-secondary border-0 px-4 py-2"
              onClick={() => {
                setImageModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-blue border-0 px-4 py-2"
              onClick={() => {
                handleCropper();
                setImageModal(false);
              }}
            >
              {lang?.save}
            </button>
          </ModalFooter>
        </div>
      </Modal>

      {/* cropped image preview */}
      <Modal
        isOpen={imagePreviewModal}
        centered
        zIndex={9}
        toggle={() => {
          setImagePreviewModal(!imagePreviewModal);
        }}
      >
        <img src={croppedImage} alt="picture" className="img-fluid" />
      </Modal>

      <Modal
        isOpen={certificateModal}
        toggle={() => setCertificateModal(!certificateModal)}
        centered
        style={{ borderRadius: "20px" }}
        zIndex={9999999999}
      >
        <ModalBody className="p-4">
          <Row className="mb-3">
            <Col className="text-center">
              <h4 className="fs-20 fs-md-16 roboto-bold">
                {lang?.certificate_by}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              {certificateInputData.image ? (
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    minHeight: "10rem",
                    borderRadius: "6px",
                  }}
                >
                  <button
                    className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                    onClick={() => {
                      const update = {
                        ...certificateInputData,
                        image: "",
                      };
                      setCertificateInputData(update);
                    }}
                  >
                    x
                  </button>
                  <img
                    src={certificateInputData.image}
                    alt="picture"
                    className=" br-6"
                    style={{
                      objectFit: "cover",
                      height: "10rem",
                      width: "100%",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "1px dashed #A2A2A2",
                    backgroundColor: "#F2FCFE",
                    minHeight: "10rem",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    document.getElementById("certificate").click();
                  }}
                >
                  <div className="text-center mt-5">
                    <img
                      src={UploadIcon}
                      width={40}
                      height={40}
                      alt="picture"
                    />
                    <h4
                      className="fs-14 roboto-medium pt-3"
                      style={{ color: "#00c5de" }}
                    >
                      {lang?.add_certificate_image}
                    </h4>
                  </div>
                </div>
              )}
              <input
                type="file"
                className="d-none"
                id="certificate"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setCertificateImage(file);
                  // Preview the image
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const update = {
                      ...certificateInputData,
                      image: reader.result,
                      api_image: file,
                    };
                    setCertificateInputData(update);
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.certificate_name}
              </label>
              <input
                type="text"
                className="w-100 br-8 px-3 mt-2"
                value={certificateInputData.title}
                onChange={(e) => {
                  const updatedInputData = {
                    ...certificateInputData,
                    title: e.target.value,
                  };
                  setCertificateInputData(updatedInputData);
                }}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                  padding: "13px 13px",
                }}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <button
                disabled={
                  isLoading ||
                  !certificateInputData.title ||
                  !certificateInputData.image
                    ? true
                    : false
                }
                className="border-0 btn-blue py-3 w-100"
                style={{ borderRadius: "6px" }}
                onClick={handleCertificateSubmit}
              >
                {isLoading ? (
                  <div className="loader"></div>
                ) : (
                  lang?.add_certificate
                )}
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="bg-white py-3 w-100 fs-16 roboto-bold"
                style={{
                  border: "1px solid #707070",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  setCertificateInputData({});
                  setCertificateIndex(null);
                  setCertificateModal(false);
                }}
              >
                {lang?.cancel}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddCourse;
