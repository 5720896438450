import React, { useContext, useEffect, useState } from "react";
import { Col, Collapse, Container, Row } from "reactstrap";
import NavBar from "../components/NavBar";
import EventCalendar from "../components/events/EventCalendar";
import SavedEvents from "../components/events/SavedEvents";
import MyEvents from "../components/events/MyEvents";
import AddEvent from "../components/events/AddEvent";
import { useParams } from "react-router-dom";
import JoinedEvents from "../components/events/JoinedEvents";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../App";

const Events = () => {
  const { lang } = useContext(Language);
  const param = useParams();
  const [tabs, setTabs] = useState(param.id);
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );

  return (
    <Container fluid className="vh-100 overflowY-hidden">
      <Row>
        <NavBar />
      </Row>
      <Row className="px-lg-5 px-md-4 px-sm-2 px-2">
        <Col xl={3} className="pt-3">
          <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
            <div
              onClick={() => setOpenSidebar(!openSidebar)}
              className="p-3 d-flex justify-content-between align-items-center cursor"
            >
              <h1 className="fs-20 fs-md-16 m-0 roboto-bold">
                {lang?.medical_event}
              </h1>{" "}
              <span className="d-xl-none">
                {openSidebar ? (
                  <CiCircleRemove size={22} />
                ) : (
                  <MdOutlineKeyboardArrowRight size={26} />
                )}
              </span>
            </div>
            <Collapse isOpen={openSidebar}>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "1" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("1");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("1");
                  }
                }}
              >
                <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                  {lang?.event_calender}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "2" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("2");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("2");
                  }
                }}
              >
                <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                  {lang?.my_events}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "5" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("5");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("5");
                  }
                }}
              >
                <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                  {lang?.joined_events}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "3" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("3");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("3");
                  }
                }}
              >
                <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                  {lang?.saved_events}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "4" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("4");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("4");
                  }
                }}
              >
                <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                  {lang?.create_new_event}
                </h1>
              </div>
            </Collapse>
          </div>
        </Col>
        <Col
          xl={9}
          className="overflowY-auto vh-100 pt-3 scroll-hide"
          style={{ paddingBottom: "8rem" }}
        >
          {tabs === "1" ? (
            <EventCalendar />
          ) : tabs === "2" ? (
            <MyEvents />
          ) : tabs === "3" ? (
            <SavedEvents />
          ) : tabs === "4" ? (
            <AddEvent />
          ) : (
            tabs === "5" && <JoinedEvents />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Events;
