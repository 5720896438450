import React, { useContext } from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import { Language } from "../../App";

const ResendCodeModal = ({ isOpen, onCancel, onResend, email }) => {
  const { lang } = useContext(Language);
  return (
    <Modal isOpen={isOpen} zIndex={9} centered className="bg-white rounded p-4">
      <div>
        <h2 className="text-blue fs-24 roboto-bold">{lang?.resend_code}</h2>
        <p className="fs-18 roboto-regular">
          {lang?.resend_code_para} {email}
        </p>
      </div>
      <hr />
      <div className="d-flex justify-content-end gap-3">
        <button
          className="bg-white text-dark border-1 rounded w-100 py-2"
          onClick={onCancel}
        >
          {lang?.cancel}
        </button>
        <button
          className="btn-blue border-0 rounded w-100 py-2"
          onClick={onResend}
        >
          {lang?.yes_resend}
        </button>
      </div>
    </Modal>
  );
};

ResendCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default ResendCodeModal;
