import React, { useCallback, useEffect, useContext, useState } from "react";
import { Col, Container, Collapse, Modal, ModalBody, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import EventDetail from "../../components/event-details/EventDetail";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  EventDetailApi,
  ReportCourseApi,
  deleteEventApi,
  saveEventApi,
} from "../../constants/API";
import { EVENTS } from "../../constants/RoutesUrl";
import JoinedMembers from "../../components/event-details/JoinedMembers";
import EditEvent from "../../components/events/EditEvent";
import EditQuestions from "../../components/event-details/EditQuestions";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../../App";
import { toast } from "react-toastify";

const EventsDetails = () => {
  const { lang } = useContext(Language);
  const event = useParams();
  const event_id = event.id;

  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = user.user.id;

  const [spin, setSpin] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [saveEvent, setSaveEvent] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportInput, setReportInput] = useState("");
  const [data, setData] = useState({});
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );

  const handleSave = () => {
    setIsLoading(true);
    axios
      .get(saveEventApi + event_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setSaveEvent(!saveEvent);
          setSaveModal(false);
        } else {
          toast.error(res.data.error);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    axios
      .get(deleteEventApi + event_id)
      .then((res) => {
        if (res.data.status) {
          setDeleteModal(false);
          setIsLoading(false);
          navigate(EVENTS);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleReport = () => {
    setIsLoading(true);
    axios
      .post(ReportCourseApi, {
        report: reportInput,
        user_id,
        report_id: event_id,
        type: "event",
      })
      .then((res) => {
        if (res.data.status) {
          setReportModal(false);
        } else {
          toast.error(res.data.error);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDetails = useCallback(() => {
    axios
      .get(EventDetailApi + event_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
          setSaveEvent(res.data.data.favorite);
          setUserId(res.data.data.user.id);
        } else {
          toast.error(res.data.error);
        }
        setSpin(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [saveEvent]);

  useEffect(() => {
    handleDetails();
  }, []);

  return (
    <>
      <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
        <Row>
          <NavBar />
        </Row>
        <Row className="px-lg-5 px-md-4 px-sm-2 px-2">
          <Col xl={3} className="pt-3">
            {spin && (
              <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
                <div
                  onClick={() => setOpenSidebar(!openSidebar)}
                  className="p-3 cursor d-flex justify-content-between align-items-center"
                >
                  <h1 className="fs-20 fs-md-16 m-0 roboto-bold">
                    {lang?.medical_event}
                  </h1>
                  <span className="d-xl-none">
                    {openSidebar ? (
                      <CiCircleRemove size={22} />
                    ) : (
                      <MdOutlineKeyboardArrowRight size={26} />
                    )}
                  </span>
                </div>
                <Collapse isOpen={openSidebar}>
                  <div
                    className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                      tabs === 1 && "active"
                    }`}
                    onClick={() => {
                      if (screenWidth < 1200) {
                        setTabs(1);
                        setOpenSidebar(!openSidebar);
                      } else {
                        setTabs(1);
                      }
                    }}
                  >
                    <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                      {lang?.event_details}
                    </h1>
                  </div>
                  {userId === user_id && (
                    <>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 2 && "active"
                        }`}
                        onClick={() => {
                          if (screenWidth < 1200) {
                            setTabs(2);
                            setOpenSidebar(!openSidebar);
                          } else {
                            setTabs(2);
                          }
                        }}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.joined_members}
                        </h1>
                      </div>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 3 || tabs === 6 ? "active" : ""
                        }`}
                        onClick={() => {
                          if (screenWidth < 1200) {
                            setTabs(3);
                            setOpenSidebar(!openSidebar);
                          } else {
                            setTabs(3);
                          }
                        }}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.edit_event}
                        </h1>
                      </div>

                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 4 && "active"
                        }`}
                        onClick={() => {
                          if (screenWidth < 1200) {
                            setTabs(4);
                            setOpenSidebar(!openSidebar);
                          } else {
                            setTabs(4);
                          }
                        }}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.edit_event_questions}
                        </h1>
                      </div>
                    </>
                  )}
                  {userId !== user_id && (
                    <div
                      className={`p-3 ps-4 border-top cursor manue-hover hover-blue`}
                      onClick={() => {
                        setSaveModal(true);
                      }}
                    >
                      <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                        {saveEvent ? lang?.unsave : lang?.save} {lang?.event}
                      </h1>
                    </div>
                  )}
                  {userId === user_id && (
                    <>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue`}
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                      >
                        <h1
                          className="fs-18 fs-md-16 m-0 roboto-medium"
                          style={{ color: "#FF1717" }}
                        >
                          {lang?.delete_event}
                        </h1>
                      </div>
                    </>
                  )}
                  {userId !== user_id && (
                    <div
                      className={`p-3 ps-4 border-top cursor manue-hover hover-blue`}
                      onClick={() => {
                        setReportModal(true);
                      }}
                    >
                      <h1
                        className="fs-18 fs-md-16 m-0 roboto-medium"
                        style={{ color: "#FF1717" }}
                      >
                        {lang?.report_event}
                      </h1>
                    </div>
                  )}
                </Collapse>
              </div>
            )}
          </Col>
          <Col
            xl={9}
            className="overflowY-auto vh-100 pt-3 scroll-hide"
            style={{ paddingBottom: "8rem" }}
          >
            {tabs === 1 ? (
              <EventDetail />
            ) : tabs === 2 ? (
              <JoinedMembers />
            ) : tabs === 3 ? (
              <EditEvent Eventdata={data} />
            ) : (
              tabs === 4 && <EditQuestions Eventdata={data} />
            )}
          </Col>
        </Row>
      </Container>

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_event}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.do_want_delete_event_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => handleDelete()}
            >
              {isLoading ? <div className="loader"></div> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Modal Ended */}
      {/* Save/unSave Modal */}
      <Modal
        centered
        zIndex={9}
        isOpen={saveModal}
        toggle={() => setSaveModal(!saveModal)}
      >
        <div className="bg-white rounded p-4">
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_you_want_to} {saveEvent ? lang?.unsave : lang?.save}{" "}
              {lang?.this_event}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after} {saveEvent ? lang?.unsaving : lang?.saving}{" "}
              {lang?.this_event_you} {saveEvent ? "cannot " : "can "}
              {lang?.view_course_save_event}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setSaveModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-blue border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => handleSave()}
            >
              {isLoading ? (
                <div className="loader"></div>
              ) : (
                `${lang?.yes}, ${saveEvent ? lang?.unsave : lang?.save}`
              )}
            </button>
          </div>
        </div>
      </Modal>
      {/* Modal Ended */}
      {/* Report Modal */}
      <Modal
        isOpen={reportModal}
        toggle={() => setReportModal(!reportModal)}
        centered
        style={{ borderRadius: "20px" }}
        zIndex={9}
      >
        <ModalBody className="p-4">
          <h4 className="fs-20 fs-md-16 roboto-bold">
            {lang?.report_this_event}
          </h4>
          <p className="fs-16 roboto-medium" style={{ opacity: "75%" }}>
            {lang?.report_this_event_para}
          </p>
          <textarea
            className="form-control my-3"
            cols="30"
            rows="7"
            placeholder={lang?.enter_your_report}
            onChange={(e) => {
              setReportInput(e.target.value);
            }}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14 roboto-bold"
              onClick={() => setReportModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={!reportInput || isLoading}
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              style={{ borderRadius: "6px" }}
              onClick={() => {
                handleReport();
              }}
            >
              {isLoading ? <div className="loader"></div> : lang?.submit_report}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Report Modal Ended */}
    </>
  );
};

export default EventsDetails;
