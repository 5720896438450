import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import NavBar from "../components/NavBar";
import ProfileInfoCard from "../components/feeds/ProfileInfoCard";
import SuggestedProfiles from "../components/feeds/SuggestedProfiles";
import PostForm from "../components/feeds/PostForm";
import SpinnerGif from "../assets/images/Spinner.gif";
import { UnixToRelative } from "../components/notification/Notification.jsx";
import {
  AddCommentApi,
  AddCommentRepliesApi,
  BaseURL,
  DeleteCommentApi,
  DeletePostApi,
  EditPostApi,
  FeedPostApi,
  GetCommentsApi,
  GetRepliesApi,
  LikeApi,
  ReportJobApi,
  RepostApi,
  ReserveApi,
  SaveApi,
} from "../constants/API";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
  IoIosArrowForward,
} from "react-icons/io";
import { BsSendFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import ImageWithAspectRatio from "../components/common/ImagewithAspectRatio.tsx";
import VideoWithAspectRatio from "../components/common/VideowithAspectRatio.tsx";
import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import Dots from "../assets/images/icon/post-dropdown-dots.png";
import InfiniteScroll from "react-infinite-scroller";
import {
  CONTESTS,
  CONTEST_DETAILS,
  NEW_CONTEST,
  PROFILE,
} from "../constants/RoutesUrl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageComponent from "../components/common/ImageComponent.jsx";
import { Language, Sponsorpost } from "../App.js";
import PostHeader from "../components/feeds/PostHeader.jsx";
import PostFooter from "../components/feeds/PostFooter.jsx";

const Feed = () => {
  sessionStorage.setItem("feed_url", window.location.href);
  const navigate = useNavigate();

  // sessionStorage
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const FeedType = sessionStorage.getItem("FeedType");

  // States
  const [page, setPage] = useState(1);
  const [selectedPoll, setSelectedPoll] = useState(0);
  const [postDeleteModal, setPostDeleteModal] = useState(false);
  const [data, setData] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [postComments, setPostComments] = useState({});
  const [comment, setComment] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [postId, setPostId] = useState("");
  const [commentReplies, setCommentReplies] = useState([]);
  const [replyDropdown, setReplyDropdown] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [replyUser, setReplyUser] = useState({});
  const [postDropDown, setPostDropDown] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);
  const [editPostModal, setEditPostModal] = useState(false);
  const [editPostInput, setEditPostInput] = useState("");
  const [reportInput, setReportInput] = useState("");
  const [likeUsersModal, setLikeUsersModal] = useState(false);
  const [likeUsers, setLikeUsers] = useState({});
  const [likeSpinner, setLikeSpinner] = useState(false);
  const [likePage, setLikePage] = useState(1);
  const [loadData, setLoadData] = useState(false);
  const [setHostModal, updateSetHostModal] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [inviteData, setInviteData] = useState({});
  const [invitePage, setInvitePage] = useState(1);
  const [inviteSpin, setInviteSpin] = useState(false);
  const { lang } = useContext(Language);

  // Functions
  const handleInvitationList = (webinar_id) => {
    axios
      .get(
        BaseURL +
          "api/webinar/reserved/users/" +
          webinar_id +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(invitePage)
      )
      .then((res) => {
        if (inviteList.length) {
          setInviteList(inviteList.concat(res.data.data.data));
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          return 0;
        }
        if (res.data.status) {
          setInviteList(res.data.data.data);
          setInviteData(res.data.data);
          setInvitePage(res.data.data.current_page + 1);
          setInviteSpin(false);
        } else {
          toast.error(res.data.error);
          setInviteSpin(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleNewPageData = () => {
    setIsLoading(true);
    axios
      .post(FeedPostApi + user_id + "?page=" + JSON.stringify(page + 1), {
        type: FeedType ? FeedType : "all",
      })
      .then((res) => {
        setData(res.data.data);
        const updatedValue = res.data.data.data;
        setPostData(postData.concat(updatedValue));
        const updated = postData.concat(updatedValue);
        sessionStorage.setItem("prevFeedData", JSON.stringify(updated));

        sessionStorage.setItem(
          "prevFeedDataDetails",
          JSON.stringify(res.data.data)
        );

        setPage(page + 1);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleLikeUsers = (otherId) => {
    setLikeSpinner(true);
    setLikeUsersModal(true);
    axios
      .get(BaseURL + "api/post/likes/" + otherId + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setLikeUsers(res.data.data);
        } else {
          toast.error(res.data.error);
          setLikeUsersModal(false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLikeSpinner(false);
      });
  };

  const handleNewLikeUsers = () => {
    axios
      .get(
        BaseURL +
          "api/post/likes/" +
          postId +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(likePage + 1)
      )
      .then((res) => {
        if (res.data.status) {
          const update = likeUsers.data.concat(res.data.data.data);
          setLikeUsers({ ...likeUsers, data: update });
          setLikePage(likePage + 1);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const indicatorStyles = {
    background: "#fff",
    width: 20,
    height: 4,
    display: "inline-block",
    margin: "0 4px",
    borderRadius: "1px",
  };

  const handleReserveWebinar = (post_id) => {
    const updatedData = postData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isReserve: !value.isReserve };
      }
      return value;
    });

    setPostData(updatedData);

    axios
      .get(ReserveApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePollAnswer = (post_id, answer) => {
    axios
      .post(BaseURL + "api/poll/answer/submit", {
        post_id,
        user_id,
        answer: answer + 1,
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleLike = (post_id) => {
    const updatedData = postData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isLike: !value.isLike };
      }
      return value;
    });

    setPostData(updatedData);

    axios
      .get(LikeApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };
  const handleSave = (post_id) => {
    const updatedData = postData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isSave: !value.isSave };
      }
      return value;
    });

    setPostData(updatedData);
    axios
      .get(SaveApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleRepost = (post_id) => {
    const updatedData = postData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isShare: !value.isShare };
      }
      return value;
    });

    setPostData(updatedData);
    axios
      .get(RepostApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
        } else {
          toast.error(res.data.actionI);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleGetComments = (post_id) => {
    setIsLoading(true);
    const id = post_id ? post_id : postId;
    axios
      .get(GetCommentsApi + id + "/" + user_id + "?page=1")
      .then((res) => {
        if (res.data.status) {
          setPostComments(res.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddComment = () => {
    const data = {
      comment,
    };
    axios
      .post(AddCommentApi + postId + "/" + user_id, data)
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteComment = () => {
    axios
      .get(DeleteCommentApi + commentId + "/delete")
      .then((res) => {
        if (res.data.status) {
          setCommentId("");
          setDeleteModal(false);
          handleGetComments();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddReplies = (comment_id) => {
    axios
      .post(AddCommentRepliesApi + postId + "/" + user_id, {
        parent: comment_id,
        comment,
      })
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments(postId);
          handleGetReplies(commentId);
          setIsReply(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSetHost = (webinarId, userId) => {
    setIsLoading(true);
    axios
      .get(BaseURL + "api/webinar/make/host/" + webinarId + "/" + userId)
      .then((res) => {
        if (res.data.status) {
          const update = inviteList.map((value) => {
            if (value.id === userId) {
              return { ...value, invite: !value.invite };
            }
            return value;
          });
          setInviteList(update);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleGetReplies = (comment_id) => {
    axios
      .get(GetRepliesApi + comment_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setCommentReplies(res.data.data);
          setReplyDropdown(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeletePost = () => {
    setIsLoading(true);
    axios
      .get(DeletePostApi + `${dropDownId}/delete`)
      .then((res) => {
        if (res.data.status) {
          toast.success("Post Deleted!");
          setIsLoading(false);
          setPostDeleteModal(false);
          window.location.reload();
        } else {
          toast.error(res.data.action);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handlePostReport = () => {
    setIsLoading(true);
    const reportData = {
      report_id: dropDownId,
      user_id,
      report: reportInput,
      type: "post",
    };
    axios
      .post(ReportJobApi, reportData)
      .then((res) => {
        if (res.data.status) {
          toast.success("Reported Successfully!");
          setIsLoading(false);
          setReportModal(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleEditPost = () => {
    setIsLoading(true);
    const editData = { caption: editPostInput, post_id: dropDownId };
    axios
      .post(EditPostApi, editData)
      .then((res) => {
        if (res.data.status) {
          toast.success("Edited Successfully!");
          setIsLoading(false);
          setEditPostModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    let jsonData = null;
    let dataDetails = null;

    try {
      const prevFeedData = sessionStorage.getItem("prevFeedData");
      const prevFeedDataDetails = sessionStorage.getItem("prevFeedDataDetails");
      jsonData = prevFeedData ? JSON.parse(prevFeedData) : null;
      dataDetails = prevFeedDataDetails
        ? JSON.parse(prevFeedDataDetails)
        : null;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    if (jsonData !== null && dataDetails !== null) {
      setLoadData(true);
      setData(dataDetails);
      setPostData(jsonData);
      setTimeout(() => {
        setLoadData(false);
      }, 1000);
    } else {
      setSpin(true);
      const requestData = {
        type: FeedType ? FeedType : "all",
      };

      axios
        .post(
          `${FeedPostApi}${user_id}?page=${JSON.stringify(page)}`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            sessionStorage.setItem(
              "prevFeedDataDetails",
              JSON.stringify(res.data.data)
            );
            sessionStorage.setItem(
              "prevFeedData",
              JSON.stringify(res.data.data.data)
            );
            setData(res.data.data);
            setPostData(res.data.data.data);
          } else {
            toast.error(res.data.error);
          }
        })
        .catch((error) => {
          console.error("Axios error:", error);
        })
        .finally(() => {
          setTimeout(() => {
            setSpin(false);
          }, 2000);
        });
    }
  }, []);

  useEffect(() => {
    const clearSessionStorageOnReload = (event) => {
      sessionStorage.removeItem("prevFeedData");
      sessionStorage.removeItem("prevFeedDataDetails");
      delete event["returnValue"];
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };
  }, []);

  // find scroling position
  window.onload = function () {
    sessionStorage.setItem("feed_scrolling", 0);
  };

  useEffect(() => {
    const scrollDiv = document.querySelector(".find_scroll");
    const handleScroll = () => {
      const currentPosition = scrollDiv.scrollTop;
      sessionStorage.setItem("feed_scrolling", currentPosition);
    };

    scrollDiv.addEventListener("scroll", handleScroll);

    const storedScrollPosition = sessionStorage.getItem("feed_scrolling");

    if (storedScrollPosition) {
      const position = parseInt(storedScrollPosition);
      setTimeout(() => {
        scrollDiv.scrollTo({
          top: position,
          behavior: "instant",
        });
      }, 400);
    } else {
      scrollDiv.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    return () => {
      scrollDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        {spin ? (
          <Row>
            <Col className="pt-5 text-center">
              <Spinner
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                }}
              />
            </Col>
          </Row>
        ) : (
          <Row className="d-flex justify-content-center bg-offwhite">
            <div className="d-flex justify-content-end col-md-3">
              <ProfileInfoCard />
            </div>
            <div
              className="find_scroll pt-3 vh-100 col-md-6 overflowX-none overflowY-auto scroll-hide"
              style={{
                maxWidth: "579px",
                paddingBottom: "5rem",
              }}
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={handleNewPageData}
                hasMore={
                  loadData
                    ? false
                    : data?.current_page !== data?.last_page
                    ? true
                    : false
                }
                loader={
                  <div className="w-100 text-center" key={0}>
                    <img src={SpinnerGif} alt="picture" width={30} />
                  </div>
                }
                useWindow={false}
              >
                <>
                  <PostForm />
                  <SponsorPost />
                  {postData &&
                    postData.map((value) =>
                      value?.type === "video" || value?.type === "image" ? (
                        <>
                          <div
                            key={value.id}
                            className="bg-white my-3 py-3 rounded"
                            style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                          >
                            {value.repost_user.image &&
                              value.repost_user.name && (
                                <>
                                  <Row>
                                    <Col className="d-flex">
                                      <div
                                        className="bg-white dropdown-avatar-shadow br-16 ms-3"
                                        style={{
                                          maxWidth: "2.5rem",
                                          maxHeight: "2.5rem",
                                          padding: "0.8px",
                                        }}
                                      >
                                        <ImageComponent
                                          src={
                                            BaseURL + value.repost_user.image
                                          }
                                          loader_height="2.5rem"
                                          loader_width="2.5rem"
                                          compClassName="br-16 img-fluid"
                                        />
                                      </div>
                                      <div className="align-self-center">
                                        <h6 className="mx-3 mb-0">
                                          {value.repost_user.name}{" "}
                                          {lang?.reposted_this}
                                        </h6>
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr style={{ opacity: "0.1px" }} />
                                </>
                              )}
                            <PostHeader
                              Id={value.user.id}
                              img={value.user.image}
                              name={value.user.name}
                              time={value?.time}
                              postDropDown={postDropDown}
                              setPostDropDown={setPostDropDown}
                              setReportModal={setReportModal}
                              setDropDownId={setDropDownId}
                              original_id={value.original_id}
                              dropDownId={dropDownId}
                              setEditPostInput={setEditPostInput}
                              caption={value.caption}
                              setEditPostModal={setEditPostModal}
                              setPostDeleteModal={setPostDeleteModal}
                            />
                            <Row className="pt-3">
                              <Col>
                                {value.type === "video" ? (
                                  <>
                                    {value.media &&
                                      value.media.map((value) => (
                                        <VideoWithAspectRatio
                                          src={BaseURL + value}
                                          aspectRatio={value.sizes}
                                        />
                                      ))}
                                  </>
                                ) : value.media && value.media.length === 1 ? (
                                  value.media.map((value) => (
                                    <ImageWithAspectRatio
                                      aspectRatio={value.sizes}
                                      src={BaseURL + value}
                                      alt="picture"
                                    />
                                  ))
                                ) : (
                                  <Carousel
                                    renderArrowNext={(
                                      onClickHandler,
                                      hasNext
                                    ) =>
                                      hasNext && (
                                        <IoIosArrowDroprightCircle
                                          className="top-0 bottom-0 position-absolute m-auto end-0 me-3"
                                          size={30}
                                          color="white"
                                          onClick={onClickHandler}
                                        />
                                      )
                                    }
                                    renderArrowPrev={(
                                      onClickHandler,
                                      hasPrev
                                    ) =>
                                      hasPrev && (
                                        <IoIosArrowDropleftCircle
                                          className="top-0 bottom-0 position-absolute m-auto ms-3 start-0"
                                          size={30}
                                          style={{ zIndex: "1" }}
                                          color="white"
                                          onClick={onClickHandler}
                                        />
                                      )
                                    }
                                    renderIndicator={(
                                      onClickHandler,
                                      isSelected,
                                      index,
                                      label
                                    ) => {
                                      return (
                                        <>
                                          {isSelected ? (
                                            <li
                                              style={{
                                                ...indicatorStyles,
                                                background: "#00c5de",
                                              }}
                                              aria-label={`Selected: ${label} ${
                                                index + 1
                                              }`}
                                              title={`Selected: ${label} ${
                                                index + 1
                                              }`}
                                            />
                                          ) : (
                                            <li
                                              style={indicatorStyles}
                                              onClick={onClickHandler}
                                              onKeyDown={onClickHandler}
                                              value={index}
                                              key={index}
                                              role="button"
                                              tabIndex={0}
                                              title={`${label} ${index + 1}`}
                                              aria-label={`${label} ${
                                                index + 1
                                              }`}
                                            />
                                          )}
                                        </>
                                      );
                                    }}
                                    statusFormatter={(current, total) => {
                                      return (
                                        <>
                                          <div
                                            className="mt-3 px-3 py-1 rounded-pill me-2"
                                            style={{
                                              backgroundColor:
                                                "rgba(0,0,0,0.2)",
                                            }}
                                          >
                                            <p className="roboto-bold mb-0 fs-12">{`${current}/${total}`}</p>
                                          </div>
                                        </>
                                      );
                                    }}
                                    autoPlay={false}
                                    infiniteLoop={false}
                                    showIndicators={true}
                                    showThumbs={false}
                                    showStatus={true}
                                  >
                                    {value.media &&
                                      value.media.map((data) => (
                                        <ImageWithAspectRatio
                                          aspectRatio={value.sizes}
                                          src={BaseURL + data}
                                          alt="picture"
                                        />
                                      ))}
                                  </Carousel>
                                )}
                              </Col>
                            </Row>
                            {value?.caption && (
                              <>
                                <Row className="p-2">
                                  <Col>
                                    <ReadMore text={value.caption} />
                                  </Col>
                                </Row>
                                <hr className="m-0" />
                              </>
                            )}
                            <PostFooter
                              likes_count={value.likes_count}
                              setPostId={setPostId}
                              original_id={value.original_id}
                              handleLikeUsers={handleLikeUsers}
                              like_users_images={value.like_users_images}
                              handleLike={handleLike}
                              isLike={value.isLike}
                              handleGetComments={handleGetComments}
                              setPostModal={setPostModal}
                              comments_count={value.comments_count}
                              handleRepost={handleRepost}
                              isShare={value.isShare}
                              isSave={value.isSave}
                              handleSave={handleSave}
                            />
                          </div>
                        </>
                      ) : value.type === "webinar" ? (
                        <>
                          <div
                            key={value.id}
                            className="bg-white my-3 py-3 br-6"
                            style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                          >
                            <div className="position-relative d-flex justify-content-between align-items-center px-3">
                              <div className="d-flex gap-3">
                                <div
                                  className="bg-white dropdown-avatar-shadow wh-lg-60px wh-md-50px align-self-center br-22 br-md-10px"
                                  style={{ padding: "2px" }}
                                >
                                  <Link to={PROFILE + "/" + value.user.id}>
                                    <ImageComponent
                                      src={
                                        value.user.image
                                          ? BaseURL + value.user.image
                                          : Avatar
                                      }
                                      img_height="100%"
                                      img_width="100%"
                                      loader_height="100%"
                                      loader_width="100%"
                                      compClassName="br-22 br-md-10px"
                                    />
                                  </Link>
                                </div>
                                <div className="align-self-center">
                                  <div>
                                    <Link to={PROFILE + "/" + value.user.id}>
                                      <h1 className="roboto-bold mb-0 text-dark text-ellips-162px fs-16">
                                        {value.user.name}
                                      </h1>
                                    </Link>
                                    <p
                                      className="roboto-regular mb-0 fs-12"
                                      style={{
                                        color: "#434343",
                                        opacity: "80%",
                                      }}
                                    >
                                      <UnixToRelative
                                        unixTimestamp={value?.time}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <img
                                  onClick={() => {
                                    setPostDropDown(!postDropDown);
                                    setDropDownId(value.original_id);
                                  }}
                                  width={20}
                                  className="cursor"
                                  src={Dots}
                                  alt="picture"
                                />
                              </div>
                              {/* Post DropDown */}
                              <div
                                className="position-absolute w-25 end-0"
                                style={{ marginTop: "8rem" }}
                              >
                                <Collapse
                                  isOpen={
                                    postDropDown &&
                                    value.original_id === dropDownId
                                      ? true
                                      : false
                                  }
                                  className="bg-white p-3 text-center br-16"
                                  style={{
                                    boxShadow:
                                      "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                                  }}
                                >
                                  {value.user.id !== user_id && (
                                    <h6
                                      className="mb-0 text-red cursor fs-md-12"
                                      onClick={() => {
                                        setReportModal(true);
                                        setPostDropDown(false);
                                      }}
                                    >
                                      {lang?.report}
                                    </h6>
                                  )}
                                  {value.user.id === user_id && (
                                    <>
                                      <h6
                                        onClick={() => {
                                          setPostDeleteModal(true);
                                          setPostDropDown(false);
                                        }}
                                        className="mb-0 text-red cursor fs-md-12"
                                      >
                                        {lang?.delete}
                                      </h6>
                                      <h6 className="mt-3 mb-0 cursor">
                                        {lang?.edit}
                                      </h6>
                                      <h6
                                        className="mt-3 mb-0 cursor"
                                        onClick={() => {
                                          updateSetHostModal(true);
                                          setInviteSpin(true);
                                          handleInvitationList(
                                            value.original_id
                                          );
                                          setPostDropDown(false);
                                        }}
                                      >
                                        Set Hosts
                                      </h6>
                                    </>
                                  )}
                                </Collapse>
                              </div>
                              {/* End Post DropDown */}
                            </div>
                            <Row className="pt-3 pb-2">
                              <Col>
                                {value.media.map((value) => (
                                  <ImageWithAspectRatio
                                    aspectRatio={value.sizes}
                                    src={BaseURL + value}
                                    alt="picture"
                                  />
                                ))}
                              </Col>
                            </Row>
                            {value?.caption || value?.title ? (
                              <>
                                <Row className="p-2">
                                  <Col>
                                    <h4>
                                      {value?.title && value.title.length > 26
                                        ? value.title.slice(0, 26) + "..."
                                        : value.title}
                                    </h4>
                                    <ReadMore text={value.caption} />
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <></>
                            )}
                            <Row className="justify-content-between px-3">
                              <Col className="align-self-center">
                                <p className="mb-0 text-warning">
                                  {lang?.scheduled_on}
                                </p>
                                <small className="mb-0">
                                  {value?.scheduled}
                                  {", "}
                                  <ConvertToTime
                                    tstamp={value.start_timestamp}
                                  />
                                </small>
                              </Col>
                              <Col className="text-end align-self-center">
                                {value.status !== 0 && (
                                  <button
                                    className="px-4 py-2 fw-bold text-white btn btn-warning"
                                    onClick={
                                      value.user.id === user_id
                                        ? value.status === 0
                                          ? null
                                          : value.status === 2
                                          ? "Webinar Ended"
                                          : () => {
                                              if (value.user.id === user_id) {
                                                window.open(
                                                  value.meeting_host_url,
                                                  "_blank"
                                                );
                                              } else {
                                                if (value.isHost) {
                                                  window.open(
                                                    value.meeting_host_url,
                                                    "_blank"
                                                  );
                                                } else {
                                                  window.open(
                                                    value.meeting_url,
                                                    "_blank"
                                                  );
                                                }
                                              }
                                            }
                                        : value.status === 0
                                        ? value.isReserve
                                          ? () => handleReserveWebinar(value.id)
                                          : () => handleReserveWebinar(value.id)
                                        : value.status === 1
                                        ? value.isReserve
                                          ? () => {
                                              if (value.user.id === user_id) {
                                                window.open(
                                                  value.meeting_host_url,
                                                  "_blank"
                                                );
                                              } else {
                                                if (value.meeting_url !== "") {
                                                  window.open(
                                                    value.meeting_url,
                                                    "_blank"
                                                  );
                                                } else {
                                                  toast.info(
                                                    "Not started yet."
                                                  );
                                                }
                                              }
                                            }
                                          : () => handleReserveWebinar(value.id)
                                        : () => toast.info("Webinar is Ended.")
                                    }
                                  >
                                    {value.user.id === user_id
                                      ? value.status === 0
                                        ? "Not Started Yet"
                                        : value.status === 2
                                        ? "Webinar Ended"
                                        : "Start Webinar"
                                      : value.status === 0
                                      ? value.isReserve
                                        ? "Unreserve"
                                        : "Reserve Seat"
                                      : value.status === 1
                                      ? value.isReserve
                                        ? "Join Webinar"
                                        : "Reserve Seat"
                                      : "Webinar Ended"}
                                  </button>
                                )}
                              </Col>
                            </Row>
                          </div>
                          {/* Set Host Modal */}
                          <Modal
                            isOpen={setHostModal}
                            centered
                            zIndex={9}
                            scrollable
                            size="lg"
                          >
                            <ModalHeader
                              toggle={() => {
                                updateSetHostModal(false);
                              }}
                            >
                              {lang?.invite}
                            </ModalHeader>
                            <ModalBody>
                              {inviteSpin ? (
                                <Row>
                                  <Col className="text-center">
                                    <Spinner />
                                  </Col>
                                </Row>
                              ) : (
                                <InfiniteScroll
                                  pageStart={0}
                                  loadMore={() =>
                                    handleInvitationList(value.original_id)
                                  }
                                  hasMore={
                                    inviteData?.current_page !==
                                    inviteData?.last_page
                                      ? true
                                      : false
                                  }
                                  loader={
                                    <Row>
                                      <Col className="text-center">
                                        <Spinner size="sm" />
                                      </Col>
                                    </Row>
                                  }
                                  useWindow={false}
                                >
                                  <Row>
                                    {inviteList.map((val) => (
                                      <Col xs={12}>
                                        <div className="border border-1 border-inherit rounded my-2">
                                          <div className="d-flex m-3 justify-content-between">
                                            <div className="d-flex gap-2">
                                              <Link
                                                to={PROFILE + "/" + val.id}
                                                className="bg-white dropdown-avatar-shadow align-self-center"
                                                style={{
                                                  borderRadius: "19px",
                                                  maxWidth: "4rem",
                                                  maxHeight: "4rem",
                                                  padding: "1.8px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    val?.image
                                                      ? BaseURL + val.image
                                                      : Avatar
                                                  }
                                                  alt="picture"
                                                  className="img-fluid "
                                                  style={{
                                                    borderRadius: "17px",
                                                  }}
                                                />
                                              </Link>
                                              <div className="align-self-center">
                                                <Link
                                                  to={PROFILE + "/" + val.id}
                                                  className="fs-14 roboto-bold text-dark"
                                                >
                                                  {val.name}
                                                </Link>
                                                <p className="mb-0 fs-12 roboto-regular">
                                                  {val.user_name}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="align-self-center">
                                              <button
                                                className="btn btn-white border border-1 px-3 py-1"
                                                disabled={isLoading}
                                                onClick={() => {
                                                  handleSetHost(
                                                    value.original_id,
                                                    val.id
                                                  );
                                                }}
                                              >
                                                <span className="roboto-medium">
                                                  {val.invite
                                                    ? lang?.undo
                                                    : "Set Host"}
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    ))}
                                  </Row>
                                </InfiniteScroll>
                              )}
                            </ModalBody>
                          </Modal>
                          {/* End set host modal */}
                        </>
                      ) : value.type === "clinical-image" ? (
                        <>
                          <div
                            key={value.id}
                            className="bg-white my-3 py-3 br-6"
                            style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                          >
                            {value.repost_user.image &&
                              value.repost_user.name && (
                                <>
                                  <Row>
                                    <Col className="d-flex">
                                      <div
                                        className="bg-white dropdown-avatar-shadow br-14 ms-3"
                                        style={{
                                          maxWidth: "2.5rem",
                                          maxHeight: "2.5rem",
                                          padding: "0.8px",
                                        }}
                                      >
                                        <ImageComponent
                                          src={
                                            BaseURL + value.repost_user.image
                                          }
                                          loader_height="2.5rem"
                                          loader_width="2.5rem"
                                          compClassName="br-12 img-fluid"
                                        />
                                      </div>
                                      <div className="align-self-center">
                                        <h6 className="mx-3 mb-0">
                                          {value.repost_user.name}{" "}
                                          {lang?.reposted_this}
                                        </h6>
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr style={{ opacity: "0.1px" }} />
                                </>
                              )}
                            <PostHeader
                              Id={value.user.id}
                              img={value.user.image}
                              name={value.user.name}
                              time={value?.time}
                              setPostDropDown={setPostDropDown}
                              postDropDown={postDropDown}
                              setDropDownId={setDropDownId}
                              original_id={value.original_id}
                              dropDownId={dropDownId}
                              setReportModal={setReportModal}
                              setPostDeleteModal={setPostDeleteModal}
                            />
                            <Row className="pt-3 pb-2">
                              <Col>
                                {value?.media && value?.media.length === 1 ? (
                                  value?.media.map((data) => (
                                    <ImageWithAspectRatio
                                      aspectRatio={value?.sizes}
                                      src={BaseURL + data}
                                      alt="picture"
                                    />
                                  ))
                                ) : (
                                  <Carousel
                                    statusFormatter={(current, total) => {
                                      return (
                                        <>
                                          <div
                                            className="mt-3 px-3 py-1 rounded-pill me-2"
                                            style={{
                                              backgroundColor:
                                                "rgba(0,0,0,0.2)",
                                            }}
                                          >
                                            <p className="roboto-bold mb-0 fs-12">{`${current}/${total}`}</p>
                                          </div>
                                        </>
                                      );
                                    }}
                                    renderArrowNext={(
                                      onClickHandler,
                                      hasNext
                                    ) =>
                                      hasNext && (
                                        <IoIosArrowDroprightCircle
                                          className="top-0 bottom-0 position-absolute m-auto end-0 me-3"
                                          size={30}
                                          color="white"
                                          onClick={onClickHandler}
                                        />
                                      )
                                    }
                                    renderArrowPrev={(
                                      onClickHandler,
                                      hasPrev
                                    ) =>
                                      hasPrev && (
                                        <IoIosArrowDropleftCircle
                                          className="top-0 bottom-0 position-absolute m-auto ms-3 start-0"
                                          size={30}
                                          style={{ zIndex: "1" }}
                                          color="white"
                                          onClick={onClickHandler}
                                        />
                                      )
                                    }
                                    renderIndicator={(
                                      onClickHandler,
                                      isSelected,
                                      index,
                                      label
                                    ) => {
                                      if (isSelected) {
                                        return (
                                          <li
                                            style={{
                                              ...indicatorStyles,
                                              background: "#00c5de",
                                            }}
                                            aria-label={`Selected: ${label} ${
                                              index + 1
                                            }`}
                                            title={`Selected: ${label} ${
                                              index + 1
                                            }`}
                                          />
                                        );
                                      }
                                      return (
                                        <li
                                          style={indicatorStyles}
                                          onClick={onClickHandler}
                                          onKeyDown={onClickHandler}
                                          value={index}
                                          key={index}
                                          role="button"
                                          tabIndex={0}
                                          title={`${label} ${index + 1}`}
                                          aria-label={`${label} ${index + 1}`}
                                        />
                                      );
                                    }}
                                    autoPlay={false}
                                    infiniteLoop={false}
                                    showIndicators={true}
                                    showThumbs={false}
                                    showStatus={true}
                                  >
                                    {value?.media &&
                                      value?.media.map((data) => (
                                        <ImageWithAspectRatio
                                          aspectRatio={value?.sizes}
                                          src={BaseURL + data}
                                          alt="picture"
                                        />
                                      ))}
                                  </Carousel>
                                )}
                              </Col>
                            </Row>
                            {value?.caption || value?.title ? (
                              <>
                                <Row className="justify-content-between p-2">
                                  <Col className="align-self-center">
                                    <h4 className="mb-0">
                                      {value?.title && value.title.length > 26
                                        ? value.title.slice(0, 26) + "..."
                                        : value.title}
                                    </h4>
                                  </Col>
                                  {value?.pdf && (
                                    <Col className="text-end">
                                      <button
                                        className="border-0 px-3 py-1 text-white btn-blue"
                                        onClick={() => {
                                          window.open(
                                            BaseURL + value.pdf,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        {lang?.view_Case_Study}
                                      </button>
                                    </Col>
                                  )}
                                </Row>
                                <Row className="px-2">
                                  <Col>
                                    <ReadMore text={value.caption} />
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <></>
                            )}
                            <PostFooter
                              likes_count={value.likes_count}
                              setPostId={setPostId}
                              original_id={value.original_id}
                              handleLikeUsers={handleLikeUsers}
                              like_users_images={value.like_users_images}
                              handleLike={handleLike}
                              isLike={value.isLike}
                              handleGetComments={handleGetComments}
                              setPostModal={setPostModal}
                              comments_count={value.comments_count}
                              handleRepost={handleRepost}
                              isShare={value.isShare}
                              isSave={value.isSave}
                              handleSave={handleSave}
                            />
                          </div>
                        </>
                      ) : value?.type === "poll" ? (
                        <>
                          <div
                            className="bg-white my-3 py-3 br-6"
                            style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                          >
                            {value.repost_user.image &&
                              value.repost_user.name && (
                                <>
                                  <Row>
                                    <Col className="d-flex">
                                      <div
                                        className="bg-white dropdown-avatar-shadow br-14 ms-3"
                                        style={{
                                          maxWidth: "2.5rem",
                                          maxHeight: "2.5rem",
                                          padding: "0.8px",
                                        }}
                                      >
                                        <ImageComponent
                                          loader_height="2.5rem"
                                          loader_width="2.5rem"
                                          src={
                                            BaseURL + value.repost_user.image
                                          }
                                          compClassName="br-12 img-fluid"
                                        />
                                      </div>
                                      <div className="align-self-center">
                                        <h6 className="mx-3 mb-0">
                                          {value.repost_user.name}{" "}
                                          {lang?.reposted_this}
                                        </h6>
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr style={{ opacity: "0.1px" }} />
                                </>
                              )}
                            <PostHeader
                              Id={value.user.id}
                              img={value.user.image}
                              name={value.user.name}
                              time={value?.time}
                              postDropDown={postDropDown}
                              setPostDropDown={setPostDropDown}
                              setReportModal={setReportModal}
                              setDropDownId={setDropDownId}
                              original_id={value.original_id}
                              dropDownId={dropDownId}
                              setEditPostInput={setEditPostInput}
                              caption={value.caption}
                              setEditPostModal={setEditPostModal}
                              setPostDeleteModal={setPostDeleteModal}
                            />
                            <Row className="pt-3 pb-2">
                              <Col>
                                {value?.media &&
                                  value?.media.map((data) => (
                                    <ImageWithAspectRatio
                                      aspectRatio={data?.sizes}
                                      src={BaseURL + data}
                                      alt="picture"
                                    />
                                  ))}
                              </Col>
                            </Row>
                            {value?.title && (
                              <>
                                <Row className="px-3 py-2">
                                  <Col>
                                    <p className="roboto-medium m-0 fs-20 fs-md-15">
                                      {value?.title}
                                    </p>
                                  </Col>
                                </Row>
                                <hr className="m-0" />
                              </>
                            )}

                            {[
                              value?.option_1,
                              value?.option_2,
                              value?.option_3,
                              value?.option_4,
                            ].map((data, index) => (
                              <div className="mx-3 my-3" key={index}>
                                {data !== "" && (
                                  <div
                                    className="p-3 cursor"
                                    onClick={() => {
                                      setSelectedPoll(index);
                                      handlePollAnswer(
                                        value.original_id,
                                        index
                                      );
                                    }}
                                    style={{
                                      border: "1px solid #00c5de",
                                      borderRadius: "10px",
                                      background: "rgba(0, 197, 222,0.1)",
                                    }}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <h5 className="m-0 fs-md-15">{data}</h5>
                                      </div>
                                      <div className="d-flex justify-content-end gap-2">
                                        <label
                                          className="form-check-label"
                                          style={{ width: "35px" }}
                                        >
                                          {value.answer_percentage[index]} %
                                        </label>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            checked={selectedPoll === index}
                                            value={index}
                                            name="poll"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                            <hr className="mb-0" />

                            <PostFooter
                              likes_count={value.likes_count}
                              setPostId={setPostId}
                              original_id={value.original_id}
                              handleLikeUsers={handleLikeUsers}
                              like_users_images={value.like_users_images}
                              handleLike={handleLike}
                              isLike={value.isLike}
                              handleGetComments={handleGetComments}
                              setPostModal={setPostModal}
                              comments_count={value.comments_count}
                              handleRepost={handleRepost}
                              isShare={value.isShare}
                              isSave={value.isSave}
                              handleSave={handleSave}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                </>
              </InfiniteScroll>
            </div>
            <div className="col-md-3">
              <SuggestedProfiles />
            </div>
          </Row>
        )}
      </Container>

      {/* Post Modal */}
      <Offcanvas
        direction="end"
        scrollable
        isOpen={postModal}
        zIndex={9}
        style={{ width: "480px" }}
        toggle={() => {
          setPostModal(false);
        }}
      >
        <OffcanvasHeader
          className="bg-grey"
          toggle={() => {
            setPostModal(false);
          }}
        >
          {lang?.comments}
        </OffcanvasHeader>
        <hr className="m-0" style={{ opacity: "0.1" }} />
        <OffcanvasBody
          className="bg-grey scroll-hide"
          style={{ paddingBottom: "5rem" }}
        >
          {isLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                {postComments?.data &&
                  postComments.data.map((content, index) => (
                    <div className="bg-white my-3 p-2 br-12" key={index}>
                      <div className="d-flex gap-3">
                        {/* Profile Image */}
                        <div>
                          <div
                            className="bg-white dropdown-avatar-shadow br-16 cursor"
                            style={{
                              maxWidth: "3rem",
                              maxHeight: "3rem",
                              padding: "0.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                content.user.image
                                  ? BaseURL + content.user.image
                                  : Avatar
                              }
                              loader_height="3rem"
                              loader_width="3rem"
                              compClassName="br-14 img-fluid"
                            />
                          </div>
                        </div>
                        {/* Div End Profile Image */}
                        {/* Profile Detials */}
                        <div>
                          <p className="roboto-bold mb-0 cursor fs-16">
                            {content.user.name}
                          </p>
                          <p className="roboto-regular text-gray fs-14">
                            {content.comment}
                          </p>
                          <div className="d-flex gap-4">
                            <p className="roboto-medium fs-12">
                              {<TimestampConverter timestamp={content.time} />}
                            </p>
                            <p
                              className="roboto-medium text-blue cursor fs-12"
                              onClick={() => {
                                setIsReply(true);
                                setReplyUser(content.user);
                                setCommentId(JSON.stringify(content.id));
                              }}
                            >
                              {lang?.reply}
                            </p>
                            <p
                              className="roboto-medium text-red cursor fs-12"
                              onClick={() => {
                                setCommentId(JSON.stringify(content.id));
                                setDeleteModal(true);
                              }}
                            >
                              {lang?.delete}
                            </p>
                          </div>
                        </div>
                        {/* Div ended Profile Detials */}
                      </div>

                      {content.replies > 0 && (
                        <div>
                          <p
                            className="mb-0 fw-bold text-end text-gray cursor fs-12 me-3"
                            onClick={() => {
                              if (content.id === commentId) {
                                setCommentId("");
                                setCommentReplies([]);
                              } else {
                                setCommentId(content.id);
                                handleGetReplies(content.id);
                              }
                            }}
                          >
                            {content.id === commentId ? "Hide" : "View"}{" "}
                            {content.replies}{" "}
                            {content.replies > 1 ? "replies" : "reply"}
                          </p>

                          {content.id === commentId &&
                            commentReplies.map((val) => {
                              return (
                                <>
                                  <div>
                                    <Collapse horizontal isOpen={true}>
                                      <div
                                        className="mt-4 pt-3 ms-5"
                                        key={val.id}
                                      >
                                        <div className="d-flex gap-3">
                                          {/* Profile Image */}
                                          <div>
                                            <div
                                              className="bg-white dropdown-avatar-shadow br-16 cursor"
                                              style={{
                                                maxWidth: "3rem",
                                                maxHeight: "3rem",
                                                padding: "0.8px",
                                              }}
                                            >
                                              <ImageComponent
                                                src={
                                                  val.user.image
                                                    ? BaseURL + val.user.image
                                                    : Avatar
                                                }
                                                loader_height="3rem"
                                                loader_width="3rem"
                                                compClassName="br-14 img-fluid"
                                              />
                                            </div>
                                          </div>
                                          {/* Div End Profile Image */}
                                          {/* Profile Detials */}
                                          <div>
                                            <p className="roboto-bold mb-0 cursor fs-16">
                                              {val.user.name}
                                            </p>
                                            <p className="roboto-regular text-gray fs-14">
                                              {val.comment}
                                            </p>
                                            <div className="d-flex gap-4">
                                              <p className="roboto-medium fs-12">
                                                {
                                                  <TimestampConverter
                                                    timestamp={val.time}
                                                  />
                                                }
                                              </p>
                                              <p
                                                className="roboto-medium text-blue cursor fs-12"
                                                onClick={() => {
                                                  setIsReply(true);
                                                  setReplyUser(val.user);
                                                  setCommentId(
                                                    JSON.stringify(content.id)
                                                  );
                                                }}
                                              >
                                                {lang?.reply}
                                              </p>
                                              <p
                                                className="roboto-medium text-red cursor fs-12"
                                                onClick={() => {
                                                  setCommentId(
                                                    JSON.stringify(val.id)
                                                  );
                                                  setDeleteModal(true);
                                                }}
                                              >
                                                {lang?.delete}
                                              </p>
                                            </div>
                                          </div>
                                          {/* Div ended Profile Detials */}
                                        </div>
                                      </div>
                                    </Collapse>
                                    <hr
                                      className="ms-5"
                                      style={{ opacity: "0.1" }}
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              {/* if NO comments available */}
              {postComments.data && postComments.data.length === 0 && (
                <div className="text-center">
                  <h3 className="text-gray">{lang?.there_no_comment}</h3>
                  <p className="text-gray">{lang?.no_one_commented_post}</p>
                </div>
              )}
            </>
          )}

          {/* Comment input section */}
          <div className="bottom-0 position-absolute m-auto end-0 start-0">
            {isReply && (
              <Row>
                <Col
                  className="d-flex justify-content-between bg-blue br-12"
                  style={{ marginLeft: "1.3rem", marginRight: "6.3rem" }}
                >
                  <h6 className="mb-0 py-2 text-white">
                    {lang?.replying_to} {replyUser.name}
                  </h6>
                  <div
                    className="align-self-center cursor"
                    onClick={() => setIsReply(false)}
                  >
                    <MdOutlineCancel color="white" size={20} />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex">
                <input
                  type="text"
                  placeholder={lang?.write_your_coment}
                  className="form-control m-2 py-3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div className="align-self-center">
                  <button
                    type="button"
                    disabled={comment === "" ? true : false}
                    className="border-0 px-4 py-3 btn-blue me-2 ms-1"
                    onClick={() => {
                      isReply
                        ? handleAddReplies(commentId)
                        : handleAddComment();
                    }}
                  >
                    <BsSendFill size={20} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          {/* Comment input section */}
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={deleteModal} centered zIndex={9}>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_comment}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_comment_able_comment}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeleteComment}
            >
              {lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={postDeleteModal}
        toggle={() => setPostDeleteModal(!postDeleteModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setPostDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={reportModal}
        centered
        zIndex={9}
        toggle={() => setReportModal(!reportModal)}
      >
        <ModalHeader>{lang?.report_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.modal_enter_report_para}</h6>

          <textarea
            rows="7"
            className="form-control"
            placeholder={lang?.enter_your_report}
            onChange={(e) => setReportInput(e.target.value)}
          ></textarea>

          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setReportModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              onClick={() => {
                handlePostReport();
              }}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.submit_report}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={editPostModal}
        toggle={() => setEditPostModal(!editPostModal)}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.edit_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setEditPostModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              disabled={!editPostInput}
              onClick={handleEditPost}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.update}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={likeUsersModal}
        toggle={() => setLikeUsersModal(!likeUsersModal)}
        centered
        zIndex={9}
      >
        <ModalHeader toggle={() => setLikeUsersModal(false)}>
          {lang?.liked_users}
        </ModalHeader>
        <ModalBody>
          {likeSpinner ? (
            <Col className="my-4 text-center">
              <Spinner />
            </Col>
          ) : (
            <div>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleNewLikeUsers}
                hasMore={likeUsers.current_page !== likeUsers.last_page}
                loader={
                  <Col className="text-center">
                    <Spinner />
                  </Col>
                }
                useWindow={false}
              >
                {likeUsers.data &&
                  likeUsers.data.map((value, index) => (
                    <>
                      <Link
                        onClick={() => {
                          navigate(PROFILE + "/" + value.id);
                          window.location.reload();
                        }}
                        className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
                        key={index}
                      >
                        <div className="d-flex gap-2">
                          <div
                            className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
                            style={{
                              padding: "1.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? BaseURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded"
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>
                          <div className="align-self-center">
                            <div className="roboto-bold text-dark fs-17 search-post-name">
                              {value.name}
                            </div>
                            <p className="roboto-regular mb-0 fs-12 search-post-name">
                              {value.user_name}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {index !== 3 && (
                        <hr className="m-0" style={{ opacity: "6%" }} />
                      )}
                    </>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Feed;

function TimestampConverter({ timestamp }) {
  const dt = new Date(timestamp * 1000); // Convert to milliseconds

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = dt.toLocaleString("en-US", options);

  return <div>{formattedDate}</div>;
}
export { TimestampConverter };

function SponsorPost() {
  const SplashData = JSON.parse(sessionStorage.getItem("SplashData"));
  const value = SplashData?.home?.contest_obj;
  const value2 =
    SplashData?.home?.new_contest_obj?.show === "1"
      ? SplashData?.home?.new_contest_obj
      : null;
  const Redirect = useNavigate();
  const { contextValue, updateContext } = useContext(Sponsorpost);
  const { lang } = useContext(Language);

  const oldContest = { ...value, color_code: "#1DC1DD", type: "3" };
  const newContest = { ...value2, color_code: "#2D3155", type: "2" };

  const contestArr = [oldContest, newContest];

  const indicatorStyles = {
    background: "#fff",
    width: 20,
    height: 4,
    display: "inline-block",
    margin: "0 4px",
    borderRadius: "1px",
  };

  return (
    <>
      {value?.show === "1" && (
        <div
          className="bg-white my-3 pb-3 overflow-hidden br-6"
          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
        >
          <Row>
            {/* new code start */}
            <Carousel
              statusFormatter={(current, total) => {
                return (
                  <>
                    <div
                      className="mt-3 px-3 py-1 rounded-pill me-2"
                      style={{
                        backgroundColor: "rgba(0,0,0,0.2)",
                      }}
                    >
                      <p className="roboto-bold mb-0 fs-12">{`${current}/${total}`}</p>
                    </div>
                  </>
                );
              }}
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <IoIosArrowDroprightCircle
                    className="top-0 bottom-0 position-absolute m-auto end-0 me-3 cursor"
                    size={30}
                    color="white"
                    onClick={onClickHandler}
                  />
                )
              }
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <IoIosArrowDropleftCircle
                    className="top-0 bottom-0 position-absolute m-auto ms-3 start-0 cursor"
                    size={30}
                    style={{ zIndex: "1" }}
                    color="white"
                    onClick={onClickHandler}
                  />
                )
              }
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                if (isSelected) {
                  return (
                    <li
                      style={{
                        ...indicatorStyles,
                        background: "#00c5de",
                      }}
                      aria-label={`Selected: ${label} ${index + 1}`}
                      title={`Selected: ${label} ${index + 1}`}
                    />
                  );
                }
                return (
                  <li
                    style={indicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                  />
                );
              }}
              autoPlay={false}
              infiniteLoop={false}
              showIndicators={true}
              showThumbs={false}
              showStatus={true}
            >
              {contestArr?.map((data) => (
                <>
                  <ImageComponent
                    src={data.image}
                    img_height={"auto"}
                    img_width="100%"
                    loader_height={"265px"}
                    loader_width=""
                    compClassName="object-cover"
                  />
                  <div
                    className="d-flex justify-content-between mx-2 pt-1 cursor"
                    onClick={() => {
                      if (data.type === "3") {
                        updateContext(data?.image);
                        Redirect(CONTEST_DETAILS + "COMPARTE TU CASO");
                      } else {
                        updateContext(data);
                        Redirect(NEW_CONTEST);
                      }
                    }}
                  >
                    <div className="align-self-end">
                      <h6
                        className="mb-0 pb-0"
                        style={{ color: data.color_code }}
                      >
                        {lang?.haz_click_para_participar}
                      </h6>
                    </div>
                    <div className="align-self-end">
                      <IoIosArrowForward color={data.color_code} size={25} />
                    </div>
                  </div>
                </>
              ))}
            </Carousel>
            {/* new code end */}
          </Row>
        </div>
      )}
    </>
  );
}

export const ReadMore = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const { lang } = useContext(Language);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      {expanded ? (
        <div>
          <p
            className="roboto-regular mb-0 fs-14"
            style={{
              wordWrap: "break-word",
              color: "#434343",
            }}
          >
            {text}
          </p>
        </div>
      ) : (
        <div>
          <p
            className="roboto-regular mb-0 fs-14"
            style={{ wordWrap: "break-word", color: "#434343" }}
          >
            {text.slice(0, 240)}
            {text.length > 240 && (
              <span
                onClick={toggleReadMore}
                className="roboto-bold text-blue cursor fs-14"
              >
                {" "}
                ...{lang?.see_more}
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export const ConvertToTime = ({ tstamp }) => {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    convertTimestampToTime();
  }, []);

  const convertTimestampToTime = () => {
    const date = new Date(Number(tstamp));
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const seconds = "0" + date.getSeconds();
    const formattedTime =
      hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
    setFormattedTime(formattedTime);
  };

  return formattedTime;
};
