import React, { createContext, useContext, useEffect, useState } from "react";
import { Col, Collapse, Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import AllCourses from "../../components/courses/AllCourses";
import MyCourses from "../../components/courses/MyCourses";
import SavedCourses from "../../components/courses/SavedCourses";
import PurchasedCourses from "../../components/courses/PurchasedCourses";
import AddCourse from "../../components/courses/AddCourse";
import { useParams } from "react-router-dom";
import BannerCarousel from "../../components/courses/BannerCarousel";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../../App";

export const BannerContext = createContext();

const Courses = () => {
  const { id } = useParams();
  const defaultTab = id ? parseInt(id, 10) : 1;
  const [tabs, setTabs] = useState(defaultTab);
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );
  const { lang } = useContext(Language);

  const [banner, setBanner] = useState([]);

  const updateContext = (newValue) => {
    setBanner(newValue);
  };

  const tabsInfo = [
    { id: 1, label: lang?.all_courses, component: <AllCourses /> },
    { id: 2, label: lang?.my_courses, component: <MyCourses /> },
    { id: 3, label: lang?.saved_courses, component: <SavedCourses /> },
    { id: 4, label: lang?.purchased_courses, component: <PurchasedCourses /> },
    { id: 5, label: lang?.create_new_course, component: <AddCourse /> },
  ];

  const selectedTabComponent =
    tabsInfo.find((tab) => tab.id === tabs)?.component || null;

  return (
    <BannerContext.Provider value={{ banner, updateContext }}>
      <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
        <Row>
          <NavBar />
        </Row>
        <Row className="px-lg-5 px-md-4 px-sm-2 px-2">
          <Col
            xl={3}
            className="pt-3 overflow-scroll pb-xl-5 pb-lg-1 pb-md-1 pb-sm-1 pb-0 scroll-hide"
            style={{ maxHeight: "100vh" }}
          >
            <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
              <div
                onClick={() => setOpenSidebar(!openSidebar)}
                className="p-3 d-flex justify-content-between align-items-center cursor"
              >
                <h1 className="fs-20 fs-sm-16 m-0 roboto-bold">
                  {lang?.medical_learnings}
                </h1>
                <span className="d-xl-none">
                  {openSidebar ? (
                    <CiCircleRemove size={22} />
                  ) : (
                    <MdOutlineKeyboardArrowRight size={26} />
                  )}
                </span>
              </div>
              <Collapse isOpen={openSidebar}>
                {tabsInfo.map(({ id, label }) => (
                  <div
                    key={id}
                    className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                      tabs === id && "active"
                    }`}
                    onClick={() => {
                      if (screenWidth < 1200) {
                        setTabs(id);
                        setOpenSidebar(!openSidebar);
                      } else {
                        setTabs(id);
                      }
                    }}
                  >
                    <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                      {label}
                    </h1>
                  </div>
                ))}
              </Collapse>
            </div>

            <div className="mt-3 pb-5 d-xl-block d-lg-none d-md-none d-sm-none d-none">
              <BannerCarousel banner={banner} />
            </div>
          </Col>
          <Col
            xl={9}
            lg={12}
            md={12}
            sm={12}
            className="overflowY-auto scroll-hide vh-100 pt-3"
            style={{ paddingBottom: "9rem" }}
          >
            {selectedTabComponent}
          </Col>
        </Row>
      </Container>
    </BannerContext.Provider>
  );
};

export default Courses;
