import React from "react";
import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import Notification from "../../components/notification/Notification";

const Index = () => {
  return (
    <>
      <Container fluid className="bg-grey vh-100">
        <Row>
          <NavBar />
        </Row>
        <Notification />
      </Container>
    </>
  );
};

export default Index;
