import React from "react";
import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import SearchComponent from "../../components/search/SearchComponent";

const Search = () => {
  return (
    <>
      <Container fluid className="bg-grey vh-100">
        <Row>
          <NavBar />
        </Row>
        <SearchComponent />
      </Container>
    </>
  );
};

export default Search;
