import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ImageRestriction } from 'react-advanced-cropper';
import cn from 'classnames';
import { CloseIcon } from './icons/CloseIcon.tsx';
import { SettingsCheckbox } from './SettingsCheckbox.tsx';
import { CropperSettings } from './CroppersWizard.tsx';
import { FreeAspectRatioIcon } from './icons/settings/FreeAspectRatioIcon.tsx';
import { SquareAspectRatioIcon } from './icons/settings/SquareAspectRatioIcon.tsx';
import { VerticalAspectRatioIcon } from './icons/settings/VerticalAspectRatioIcon.tsx';
import { PortraitAspectRatioIcon } from './icons/settings/PortraitAspectRatioIcon.tsx';
import { HorizontalAspectRatioIcon } from './icons/settings/HorizontalAspectRatioIcon.tsx';
import { RangeAspectRatioIcon } from './icons/settings/RangeAspectRatioIcon.tsx';
import { FitAreaIcon } from './icons/settings/FitAreaIcon.tsx';
import { FillAreaIcon } from './icons/settings/FillAreaIcon.tsx';
import { StencilIcon } from './icons/settings/StencilIcon.tsx';
import { NoneIcon } from './icons/settings/NoneIcon.tsx';
import { RectangleIcon } from './icons/settings/RectangleIcon.tsx';
import { CircleIcon } from './icons/settings/CircleIcon.tsx';
import { SettingsInput } from './SettingsInput.tsx';
import './CroppersWizardSettings.scss';

interface Props {
	settings: CropperSettings;
	open?: boolean;
	onClose: (value: CropperSettings) => void;
	properties: string[];
	className?: string;
	visibleClassName?: string;
}

export const CroppersWizardSettings: FC<Props> = (props) => {
	const location = useLocation();
	const { open, onClose, properties, visibleClassName, className } = props;

	const [settings, setSettings] = useState(props.settings);

	useEffect(() => {
		setSettings(props.settings);
	}, [open]);

	const aspectRatios = [
		{
			icon: <FreeAspectRatioIcon />,
		},
		{
			icon: <SquareAspectRatioIcon />,
			aspectRatio: 1,
		},
		{
			icon: <VerticalAspectRatioIcon />,
			aspectRatio: 1 / 2,
		},
		{
			icon: <PortraitAspectRatioIcon />,
			aspectRatio: 3 / 4,
		},
		{
			icon: <HorizontalAspectRatioIcon />,
			aspectRatio: 2 / 1,
		},
	];

	const onCloseInternal = () => {
		onClose(settings);
	};

	const updateSettings = (values: Partial<CropperSettings>) => {
		setSettings({
			...settings,
			...values,
		});
		onClose(values);
	};

	return (
		<div className={cn('croppers-wizard-settings', open && visibleClassName, className)}>
			<button className="croppers-wizard-settings__close-button" onClick={onCloseInternal}>
				<CloseIcon />
			</button>
			{properties.indexOf('aspectRatio') !== -1 && (
				<div className="croppers-wizard-settings__property">
					<div className="croppers-wizard-settings__property-title">Aspect Ratio</div>
					<ScrollContainer className="croppers-wizard-settings__property-values">
						{aspectRatios.map((ratio, index) => {
							const { maxAspectRatio, minAspectRatio, aspectRatio } = ratio;
							const active =
								settings.maxAspectRatio === maxAspectRatio &&
								settings.minAspectRatio === minAspectRatio &&
								settings.aspectRatio === aspectRatio;
							return (
								<div
									key={index}
									className={cn(
										'croppers-wizard-settings__icon',
										active && 'croppers-wizard-settings__icon--active',
									)}
									onClick={() => updateSettings({ maxAspectRatio, minAspectRatio, aspectRatio })}
								>
									{ratio.icon}
								</div>
							);
						})}
					</ScrollContainer>
				</div>
			)}
		</div>
	);
};
