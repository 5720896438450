import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { AllCoursesApi, BaseURL } from "../../constants/API";
import CoursePreview from "./CoursePreview";
import SubcategoryCards from "./SubcategoryCards";
import { toast } from "react-toastify";
import { BannerContext } from "../../pages/course/Courses";
import { Carousel } from "react-responsive-carousel";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
  IoIosArrowForward,
  IoMdArrowRoundDown,
} from "react-icons/io";
import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { GoDotFill } from "react-icons/go";
import { COURSE_DETAILS, SEE_ALL_COURSES } from "../../constants/RoutesUrl";
import { useNavigate } from "react-router-dom";
import { Language } from "../../App";

const fetchData = async (user_id) => {
  try {
    const response = await fetch(`${AllCoursesApi}${user_id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (!data.status) {
      throw new Error(data.action);
    }
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    toast.error(error.message || "An error occurred while fetching data.");
    throw error;
  }
};

const AllCourses = () => {
  const Navigate = useNavigate();

  const { contextValue, updateContext } = useContext(BannerContext);

  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [recommendedData, setRecommendedData] = useState({});
  const [recommendedDataArray, setRecommendedDataArray] = useState([]);
  const { lang } = useContext(Language);

  const fetchDataAndSetState = async () => {
    setLoading(true);
    try {
      const fetchedData = await fetchData(user_id);

      sessionStorage.setItem("prevCourseData", JSON.stringify(fetchedData));

      setData(fetchedData);
      setRecommendedData(fetchedData.data);
      setRecommendedDataArray(fetchedData.data.data);

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      setLoading(false);
    }
  };

  const HandleLoadMore = async (page_num) => {
    setLoad(true);
    let page = page_num + 1;
    try {
      const response = await fetch(
        `${AllCoursesApi}${user_id}?page=${JSON.stringify(page)}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const fetchdata = await response.json();
      if (!data.status) {
        throw new Error(data.action);
      }
      setRecommendedData(fetchdata.data);
      const recommended = recommendedDataArray.concat(fetchdata.data.data);
      setRecommendedDataArray(recommended);
      setLoad(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error(error.message || lang?.an_error_occured_fetching_date);
      throw error;
    }
  };

  useEffect(() => {
    const clearSessionStorageOnReload = (event) => {
      sessionStorage.removeItem("prevCourseData");
      delete event["returnValue"];
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };
  }, []);

  useEffect(() => {
    let jsonData = null;

    try {
      const prevCourseData = sessionStorage.getItem("prevCourseData");
      jsonData = prevCourseData ? JSON.parse(prevCourseData) : null;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    if (jsonData !== null) {
      setData(jsonData);
      setRecommendedData(jsonData.data);
      setRecommendedDataArray(jsonData.data.data);
      updateContext(jsonData.images);
    } else {
      fetchDataAndSetState();
    }
  }, [user_id]);

  return (
    <>
      {loading ? (
        <div className="profilejobspinner align-items-start">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="d-flex my-2 justify-content-between">
            <div>
              <h3 className="fs-20 fs-sm-16 m-0 roboto-medium">
                {lang?.trending_new_courses}
              </h3>
            </div>
            <div className="text-end align-self-center">
              <h3
                className="roboto-bold see-all-course transition cursor m-0 d-flex justify-content-end"
                style={{ fontSize: "13px" }}
                onClick={() => {
                  Navigate(SEE_ALL_COURSES);
                }}
              >
                <span className="align-self-center">{lang?.see_all}</span>{" "}
                <IoIosArrowForward size={15} />
              </h3>
            </div>
          </div>

          <CoursesCarousel courses={data?.courses_list} />

          <div
            className="px-3 py-2 my-3 d-flex br-8"
            style={{ backgroundColor: "rgba(221, 146, 90,0.08)" }}
          >
            <Col className="b-b-blue align-self-center"></Col>
            <Col className="text-center" lg={3}>
              <span className="fs-16 roboto-bold" style={{ color: "#DD925A" }}>
                {lang?.ophthalmology_specialties}
              </span>
            </Col>
            <Col className="b-b-blue align-self-center"></Col>
          </div>

          <SubcategoryCards />

          {Array.isArray(data.following_user) &&
            data.following_user.length > 0 && (
              <>
                <div className="d-flex my-3 justify-content-between">
                  <div>
                    <h3 className="fs-20 fs-sm-16 m-0 roboto-medium">
                      {lang?.following_doctor_courses}
                    </h3>
                  </div>
                  <div className="text-end align-self-center">
                    <h3
                      className="roboto-bold see-all-course transition cursor m-0 d-flex justify-content-end"
                      style={{ fontSize: "13px" }}
                      onClick={() => {
                        Navigate(SEE_ALL_COURSES);
                      }}
                    >
                      <span className="align-self-center">{lang?.see_all}</span>{" "}
                      <IoIosArrowForward size={15} />
                    </h3>
                  </div>
                </div>

                <CoursesCarousel courses={data?.following_user} />
              </>
            )}

          <Row className="justify-content-between my-3">
            <Col>
              <h3 className="fs-20 fs-sm-16 roboto-medium mb-0">
                {lang?.recomended_for_you}
              </h3>
            </Col>
          </Row>
          <div className="d-flex flex-wrap gap-3">
            {recommendedDataArray &&
              recommendedDataArray.map((value, index) => (
                <RecommendedCard
                  key={index}
                  image={value.cover}
                  price={value.price}
                  profile={value.owner_image}
                  username={value.owner_username}
                  published_courses={value.owner_published}
                  title={value.title}
                  course_id={value.id}
                />
              ))}
          </div>

          {recommendedData?.current_page !== recommendedData?.last_page ? (
            <Col className="text-center my-5">
              <button
                className="btn-blue border-0 px-5 py-2"
                disabled={load}
                onClick={() => {
                  HandleLoadMore(recommendedData.current_page);
                }}
              >
                {load ? (
                  <Col className="text-center">
                    <Spinner size="sm" color="black" />
                  </Col>
                ) : (
                  lang?.load_more
                )}
                <IoMdArrowRoundDown
                  size={20}
                  className={load ? "d-none" : "d-inline"}
                />
              </button>
            </Col>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

const CoursesCarousel = React.memo((props) => {
  const courses = props.courses || [];

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedCourses = chunkArray(courses, 5);

  return (
    <>
      <Carousel
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <div className="position-absolute top-0 bottom-0 end-0 m-0 d-flex h-100 align-items-center">
              <button
                onClick={onClickHandler}
                className="btn-blue border-0 br-10 p-2"
                style={{ backgroundColor: "rgba(0, 197, 222,0.8)" }}
              >
                <IoIosArrowDroprightCircle size={30} color="white" />
              </button>
            </div>
          )
        }
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <div
              className="position-absolute start-0 d-flex align-items-center h-100"
              style={{ zIndex: "1" }}
            >
              <button
                onClick={onClickHandler}
                className="btn-blue border-0 br-10 p-2"
                style={{ backgroundColor: "rgba(0, 197, 222,0.8)" }}
              >
                <IoIosArrowDropleftCircle size={30} color="white" />
              </button>
            </div>
          )
        }
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={false}
      >
        {chunkedCourses.map((chunk, index) => (
          <div key={index} className="w-100 overflowX-auto scroll-hide">
            <div className="d-flex gap-2">
              {chunk.map((course, courseIndex) => (
                <div key={courseIndex}>
                  <CoursePreview
                    certificates={course.certificates}
                    owner_username={course.owner_username}
                    owner_image={course.owner_image}
                    thumbnail={course.thumbnail}
                    image={course.cover}
                    price={course.price}
                    name={course.title}
                    course_id={course.id}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
});

const RecommendedCard = ({
  image,
  price,
  profile,
  username,
  published_courses,
  title,
  course_id,
}) => {
  const navigate = useNavigate();

  const strNumber = price;
  const doubleNumber = parseFloat(strNumber);
  const hasDecimal = strNumber.includes(".");
  const formattedNumber = hasDecimal
    ? doubleNumber.toString()
    : doubleNumber.toFixed(2);
  const { lang } = useContext(Language);

  return (
    <div
      className="bg-white br-12 cursor border border-1 border-inherit p-0 w-320px"
      onClick={() => {
        sessionStorage.setItem("CourseId", course_id);
        navigate(COURSE_DETAILS);
      }}
    >
      <div className="position-relative">
        <div className="bg-recommended-loader br-tl-12 br-tr-12">
          <ImageComponent
            src={BaseURL + image}
            compClassName="br-tl-12 br-tr-12 img-fluid"
            loader_height="370px"
            loader_width="100%"
          />
        </div>
        <div className="position-absolute bottom-0 start-0 mb-4 ms-4">
          {profile ? (
            <div
              className="bg-white dropdown-avatar-shadow align-self-center rounded-3"
              style={{
                maxWidth: "4rem",
                maxHeight: "4rem",
                padding: "1.8px",
              }}
            >
              <ImageComponent
                src={profile !== "..." ? BaseURL + profile : Avatar}
                compClassName="img-fluid rounded-3"
                loader_height="3.8rem"
                loader_width="3.8rem"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="text-end mx-3">
          <button
            style={{
              backgroundColor: "#3081f9",
              borderRadius: "5px",
              opacity: "70%",
            }}
            className="border-0 text-white px-3 py-1 my-3 roboto-regular fs-14"
          >
            {lang?.enrol_now} -{" "}
            {price === "0" ? lang?.free : "$" + formattedNumber}
          </button>
        </div>
      </div>
      <div className="mx-3 mb-2">
        <div className="d-flex flex-wrap align-items-center">
          <h6 className="roboto-bold fs-15 mb-0">{username}</h6>{" "}
          <GoDotFill size={10} className="mx-1" />
          <p className="mb-0 roboto-regular fs-12" style={{ color: "#434343" }}>
            {published_courses} {lang?.course_published}
          </p>
        </div>
      </div>
      <div className="mx-3 mb-3">
        <p
          className="mb-0 roboto-medium fs-14 w-100"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#434343",
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default AllCourses;
