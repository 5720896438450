import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { LOGIN } from "../../constants/RoutesUrl";

const LoginAlertModal = ({ open }) => {
  const redirect = useNavigate();
  return (
    <Modal isOpen={open} centered>
      <ModalBody>
        <h5 className="text-center">Login your account to view the content.</h5>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <button
          className="btn-blue border-0 px-5 py-2"
          onClick={() => {
            redirect(LOGIN);
          }}
        >
          Login
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default LoginAlertModal;
