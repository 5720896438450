import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "./RoutesUrl";

export const CountContext = createContext();

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [count, setCount] = useState(null);

  const updateCount = (val) => {
    setCount(val);
  };

  const checkUser = () => {
    const userToken = JSON.parse(localStorage.getItem("SignupData"));
    if (!userToken?.data?.id) {
      setIsLoggedIn(false);
      return navigate(LOGIN);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUser();
  }, [isLoggedIn]);
  return (
    <>
      {isLoggedIn ? (
        <CountContext.Provider value={{ count, updateCount }}>
          {props.children}
        </CountContext.Provider>
      ) : null}
    </>
  );
};
export default ProtectedRoute;
