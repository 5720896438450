import React from "react";
import SpinnerGif from "../../../assets/images/Spinner.gif";

const SpecSpinner = () => {
  return (
    <div>
      <img src={SpinnerGif} alt="picture" width={100} height={100} />
    </div>
  );
};

export default SpecSpinner;
