import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { BaseURL } from "../../constants/API";
import Like from "../../assets/images/icon/like.png";
import Liked from "../../assets/images/icon/liked.png";
import Reshare from "../../assets/images/icon/reshare.png";
import ReshareActive from "../../assets/images/icon/repost-active.png";
import Save from "../../assets/images/icon/save.png";
import Saved from "../../assets/images/icon/saved.png";
import Comment from "../../assets/images/icon/comment.png";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Language } from "../../App";

const PostFooter = ({
  likes_count,
  setPostId,
  original_id,
  handleLikeUsers,
  like_users_images,
  handleLike,
  isLike,
  handleGetComments,
  setPostModal,
  comments_count,
  handleRepost,
  isShare,
  isSave,
  handleSave,
  setCommentsModal, //for profile.jsx
  profile_current_url,
}) => {
  const profile_save_url = sessionStorage.getItem("profile_url");
  const { lang } = useContext(Language);
  return (
    <div>
      {likes_count > 0 && (
        <>
          <Row className="ps-4 pe-3 py-2">
            <Col
              className="d-flex cursor"
              onClick={() => {
                if (profile_current_url !== profile_save_url) {
                  setPostId(original_id);
                  handleLikeUsers(original_id);
                }
              }}
            >
              {like_users_images &&
                like_users_images.map((data) => (
                  <div
                    className="bg-white rounded-circle dropdown-avatar-shadow"
                    style={{
                      marginLeft: "-10px",
                      padding: "1px",
                    }}
                  >
                    <ImageComponent
                      src={data && data !== ".." ? BaseURL + data : Avatar}
                      img_width={25}
                      img_height={25}
                      compClassName="rounded-circle"
                    />
                  </div>
                ))}

              <p className="ps-2 fs-14 fs-md-12 roboto-medium mb-0 align-self-center text-ellips-162px hover-blue">
                {likes_count > 3
                  ? `& ${likes_count - 3} ${lang?.other_have_liked}`
                  : `${lang?.have_liked}`}
              </p>
            </Col>
          </Row>
          <hr className="m-0" />
        </>
      )}
      <div className="d-flex px-3 py-2 mt-2 justify-content-between align-items-center">
        <div className="d-flex gap-lg-5 gap-md-4 gap-sm-3 gap-1">
          <div
            className="d-flex gap-1 cursor"
            onClick={() => handleLike(original_id)}
          >
            <div>
              <img
                className="w-lg-23px"
                src={isLike ? Liked : Like}
                alt="..."
              />
            </div>
            <div className="align-self-end hover-blue">
              <p className="mb-0 fw-bold fs-14 fs-md-12">{lang?.like}</p>
            </div>
          </div>
          <div
            className="d-flex gap-1 cursor justify-content-center"
            onClick={() => {
              setPostId(original_id);
              handleGetComments(original_id);
              if (profile_current_url === profile_save_url) {
                setCommentsModal(true); //for profile.jsx
              } else {
                setPostModal(true);
              }
            }}
          >
            <div>
              <img className="w-lg-23px" src={Comment} alt="picture" />
            </div>
            <div className="align-self-end hover-blue">
              <p className="mb-0 fw-bold fs-14 fs-md-12">
                {comments_count > 0 ? comments_count : ""} {lang?.comments}
              </p>
            </div>
          </div>
          <div
            className="d-flex gap-1 cursor"
            onClick={() => {
              handleRepost(original_id);
            }}
          >
            <div>
              <img
                className="w-lg-23px"
                src={isShare ? ReshareActive : Reshare}
                alt="picture"
              />
            </div>
            <div className="align-self-end hover-blue">
              <p
                className={`mb-0 fw-bold fs-14 fs-md-12 ${
                  isShare && "text-blue"
                }`}
              >
                {isShare ? lang?.reposted : lang?.repost}
              </p>
            </div>
          </div>
        </div>
        <div>
          <img
            src={isSave ? Saved : Save}
            alt="picture"
            className="cursor w-lg-23px"
            onClick={() => handleSave(original_id)}
          />
        </div>
      </div>
    </div>
  );
};

export default PostFooter;
