const LOGIN = "/";
const SPECIALIZATION = "/specialization";
const SIGNUP = "/signup";
const FORGOT_PASSWORD = "/forgot-password";
const OTP_VERIFICATION = "/otp-verification";
const FEED = "/feed";
const EVENTS = "/events";
const JOBS = "/jobs";
const COURSES = "/courses";
const CHANGE_PASSWORD = "/change-password";
const PROFILE = "/profile";
const COURSE_DETAILS = "/course-datails";
const CHAT = "/chat";
const EDIT_PROFILE = "/edit_profile";
const ADDPOST = "/upload-content";
const SETTINGMODULE = "/setting";
const COURSE_CONTENT = "/course-content";
const NOTIFICATIONS = "/notifications";
const WEBINAR = "/webinar";
const SEARCH = "/search";
const EVENT_DETAILS = "/event-details/"; // event_id
const JOBDETAIL = "/job-detail";
const MyReports = "/my-reports";
const LIVE_WEBINARS = "/live-webinars";
const ADD_CLINICAL = "/add-clinical";
const PROFILEJOB = "/profile-job";
const PROFILEEVENTS = "/profile-events";
const PROFILECOURSES = "/profile-courses";
const PROFILEPOSTS = "/profile-posts";
const GROUPSETTING = "/group-setting";

const CONTESTS = "/contests";
const CONTEST_LIST = "/contest-list";
const CONTEST_DETAILS = "/contest-detail/";
const NEW_CONTEST = "/new-contest";

const CLINICAL_SESSIONS = "/clinical-sessions";
const CLINICAL_SESSIONS_DETAILS = "/clinical-details/";

const POST_DETAILS = "/post/"; // post_id
const POLL_DETAILS = "/poll/"; // post_id
const WEBINAR_DETAILS = "/webinar/"; // post_id
const CLINICAL_DETAILS = "/clinical/"; // post_id
const CLINICAL_SESSION_DETAILS = "/clinical-session/"; // post_id

const COURSE_SHARED_POST = "/course/"; // course_id

const EVENT_SHARED_POST = "/event/"; // event_id

const SEE_ALL_COURSES = "/courses/all";
export {
  EVENT_SHARED_POST,
  COURSE_SHARED_POST,
  NEW_CONTEST,
  CONTEST_LIST,
  SEE_ALL_COURSES,
  POST_DETAILS,
  POLL_DETAILS,
  WEBINAR_DETAILS,
  CLINICAL_DETAILS,
  CLINICAL_SESSION_DETAILS,
  CLINICAL_SESSIONS_DETAILS,
  CLINICAL_SESSIONS,
  CONTEST_DETAILS,
  CONTESTS,
  LIVE_WEBINARS,
  GROUPSETTING,
  ADD_CLINICAL,
  PROFILEPOSTS,
  PROFILEEVENTS,
  PROFILECOURSES,
  PROFILEJOB,
  SEARCH,
  JOBDETAIL,
  MyReports,
  EVENT_DETAILS,
  NOTIFICATIONS,
  PROFILE,
  EVENTS,
  EDIT_PROFILE,
  JOBS,
  COURSES,
  CHANGE_PASSWORD,
  LOGIN,
  SPECIALIZATION,
  SIGNUP,
  FEED,
  FORGOT_PASSWORD,
  OTP_VERIFICATION,
  COURSE_DETAILS,
  CHAT,
  ADDPOST,
  SETTINGMODULE,
  COURSE_CONTENT,
  WEBINAR,
};
