import React, { useEffect, useState } from "react";
import LocationIcon from "../../assets/images/icon/location.png";
import ClockIcon from "../../assets/images/icon/clock.png";
import GreenDot from "../../assets/images/icon/green-dot.png";
import OrangeDot from "../../assets/images/icon/orange-dot.png";
import BlackDot from "../../assets/images/icon/black-dot.png";
import { Col } from "reactstrap";
import { BaseURL } from "../../constants/API";
import { useNavigate } from "react-router-dom";
import { EVENT_DETAILS } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";

const EventPreview = (props) => {
  const Navigate = useNavigate();
  const [formattedDate, setFormattedDate] = useState("");
  const { image, available, title, location, time } = props;

  useEffect(() => {
    const timestamp = time;
    const date = new Date(timestamp * 1000);

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12}:${minutes} ${ampm}`;

    const formattedDateString = `${dayOfWeek}, ${year} ${month} ${day} at ${formattedTime}`;

    setFormattedDate(formattedDateString);
  }, []);

  return (
    <>
      <Col
        md={12}
        className="my-3 rounded cursor border border-1 border-inherit box"
        key={props.id}
        style={{ maxWidth: "579px" }}
        onClick={() => {
          Navigate(EVENT_DETAILS + props.id);
        }}
      >
        <div>
          <ImageComponent
            compClassName="rounded-top"
            src={BaseURL + image}
            img_width={"100%"}
            img_height={"100%"}
            loader_width="100%"
            loader_height="301px"
          />
        </div>
        <div className="p-3 bg-white br-4">
          <div>
            <div className="d-flex gap-2">
              <div>
                {available === "Online" ? (
                  <img src={GreenDot} alt="picture" width={10} height={10} />
                ) : available === "Offline" ? (
                  <img src={OrangeDot} alt="picture" width={10} height={10} />
                ) : (
                  available === "Online & Offline" && (
                    <img src={BlackDot} alt="picture" width={10} height={10} />
                  )
                )}
              </div>
              <div>
                <span
                  className={`fs-12 roboto-regular ${
                    available === "Online"
                      ? "text-green"
                      : available === "Offline"
                      ? "text-orange"
                      : available === "Online & Offline" && "text-dark"
                  }`}
                >
                  {available}
                </span>
              </div>
            </div>

            <div className="my-3">
              <h2 className="fs-16 roboto-medium">{title}</h2>
            </div>
          </div>

          <div className="mt-4">
            {location && (
              <div className="d-flex my-3 gap-2">
                <div>
                  <img src={LocationIcon} alt="picture" width={25} height={25} />
                </div>
                <div>
                  <span className="fs-14 roboto-regular">{location}</span>
                </div>
              </div>
            )}

            {formattedDate && (
              <div className="d-flex gap-2">
                <div>
                  <img src={ClockIcon} alt="picture" width={25} height={25} />
                </div>
                <div>
                  <span className="fs-14 roboto-regular text-orange">
                    {formattedDate}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default EventPreview;
