import React, { Fragment, useContext, useEffect, useState } from "react";
import EventPreview from "./EventPreview";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CalendarEventsApi } from "../../constants/API";
import {
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import CustomTooltip from "../common/CustomTooltip";
import { TiCancel } from "react-icons/ti";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_KEY } from "../../constants/Keys";
import { Language } from "../../App";

const EventCalendar = () => {
  // Google Map
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
  });

  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;
  const [eventsData, setEventsData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [eventCounts, setEventsCounts] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState({
    location: "",
    distance: 25.0,
  });
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [latLng, setLatLng] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [locationModal, setLocationModal] = useState(false);
  const { lang } = useContext(Language);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleEvents = (date) => {
    let radius = JSON.stringify(filterData.distance);
    let lat_lng = latLng?.lat + "," + latLng?.lng;

    setSpin(true);
    axios
      .post(CalendarEventsApi, {
        user_id,
        date,
        radius,
        lat_lng,
      })
      .then((res) => {
        if (res.data.status) {
          sessionStorage.setItem(
            "prevEventsCounts",
            JSON.stringify(res.data.dates)
          );
          sessionStorage.setItem(
            "prevEventData",
            JSON.stringify(res.data.data)
          );
          setEventsCounts(res.data.dates);
          setEventsData(res.data.data);
          setSpin(false);
        } else {
          toast.error(res.data.action);
          setSpin(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const isDateWithEvents = (date) => {
    const formattedDateString = formatDate(date);
    return eventCounts.some(
      (event) => event.date === formattedDateString && event.count > 0
    );
  };
  const handleLocation = () => {
    if (latLng || markerPosition) {
      setLocationModal(true);
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log({ latitude, longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        () => {}
      );
      setTimeout(() => {
        setLocationModal(true);
      }, 500);
    } else {
      toast.error(lang?.geolocation_not_suported);
    }
  };
  // Marker drag
  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };
  // Map click
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };
  // To get city and country using lat lng
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        let cityName = "";
        let countryName = "";

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          } else if (component.types.includes("country")) {
            countryName = component.long_name;
          }
        }

        setCity(cityName);
        setCountry(countryName);
      } else {
        setCity("");
        setCountry("");
      }
    });
  };

  useEffect(() => {
    const clearSessionStorageOnReload = (event) => {
      sessionStorage.removeItem("prevEventData");
      sessionStorage.removeItem("prevEventsCounts");
      delete event["returnValue"];
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    let prevEvents = null;
    let prevCounts = null;

    try {
      const prevEventData = sessionStorage.getItem("prevEventData");
      const prevEventsCounts = sessionStorage.getItem("prevEventsCounts");
      prevEvents = prevEventData ? JSON.parse(prevEventData) : null;
      prevCounts = prevEventsCounts ? JSON.parse(prevEventsCounts) : null;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    if (prevEvents !== null && prevCounts !== null) {
      setEventsCounts(prevCounts);
      setEventsData(prevEvents);
    } else {
      handleEvents(formattedDate);
    }
  }, []);
  return (
    <>
      <Row className="justify-content-center">
        <Col
          md={6}
          sm={6}
          className="rounded border border-inherit calendar-bg calender-w py-4 px-3 text-center"
        >
          <Calendar
            className="border-0 calendar-bg w-100"
            returnValue="start"
            prevLabel={<MdKeyboardArrowLeft />}
            nextLabel={<MdKeyboardArrowRight />}
            tileContent={({ date, view }) =>
              view === "month" && isDateWithEvents(date) ? (
                <div className="event-marker"></div>
              ) : null
            }
            onClickDay={(value) => {
              const dateString = value;
              const date = new Date(dateString);

              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");

              const formattedDateString = `${year}-${month}-${day}`;
              handleEvents(formattedDateString);
            }}
          />
        </Col>
      </Row>
      {spin ? (
        <div className="profilejobspinner align-items-start">
          <Spinner />
        </div>
      ) : (
        <>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="d-flex justify-content-between">
                <div className="my-3">
                  <h1 className="fs-20 fs-md-16 roboto-medium">
                    {lang?.upcoming_events}
                  </h1>
                </div>
                <div className="my-3">
                  <CustomTooltip text={lang?.filter}>
                    <button
                      className="border border-1 border-inherit rounded manue-hover"
                      onClick={() => setFilterModal(true)}
                    >
                      <FaFilter size="15" />
                    </button>
                  </CustomTooltip>{" "}
                </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ textAlign: "-webkit-center" }}>
            {eventsData?.map((value, index) => {
              return (
                <Fragment key={index}>
                  <EventPreview
                    id={value.id}
                    image={value.cover}
                    title={value.title}
                    location={value.short_location}
                    time={value.start_timestamp}
                    available={value.availability}
                  />
                </Fragment>
              );
            })}
          </div>
        </>
      )}

      <Modal isOpen={filterModal} centered zIndex={9}>
        <ModalHeader>{lang?.filters}</ModalHeader>
        <ModalBody>
          <div>
            <Row className="mb-3">
              <Col>
                <div className="d-flex justify-content-between">
                  <label className="pb-1 fs-15 roboto-medium">
                    {lang?.location}
                  </label>
                  <small
                    className="fw-bold text-blue cursor"
                    onClick={() => {
                      setFilterData({ location: "", distance: 25.0 });
                      setLatLng("");
                      setCity("");
                      setCountry("");
                    }}
                  >
                    {lang?.reset}
                  </small>
                </div>
                <div className="position-relative">
                  <input
                    onClick={handleLocation}
                    type="text"
                    className="py-2 form-control"
                    placeholder="City, Country"
                    value={
                      latLng && city && country ? `${city}, ${country}` : ""
                    }
                  />
                  {city !== "" && country !== "" && (
                    <div
                      className="position-absolute end-0 top-0 me-2"
                      style={{ marginTop: "13px" }}
                    >
                      <h6
                        className="text-primary cursor"
                        onClick={() => {
                          setLatLng("");
                          setCity("");
                          setCountry("");
                        }}
                      >
                        <TiCancel size={18} />
                        {lang?.remove}
                      </h6>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="d-flex justify-content-between">
                  <label className="pb-1 fs-15 roboto-medium">
                    {lang?.distance}
                  </label>
                  <label className="pb-1 fs-15 roboto-medium">
                    {filterData.distance} {lang?.km}
                  </label>
                </div>
                <Input
                  type="range"
                  value={filterData.distance}
                  onChange={(e) => {
                    setFilterData({ ...filterData, distance: e.target.value });
                  }}
                />
                <div className="d-flex justify-content-between">
                  <label className="pb-1 fs-15 roboto-medium">
                    0 {lang?.km}
                  </label>
                  <label className="pb-1 fs-15 roboto-medium">
                    100 {lang?.km}
                  </label>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="justify-content-end gap-3 d-flex mt-3 w-100">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setFilterModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              onClick={() => {
                setFilterModal(false);
                handleEvents();
              }}
            >
              {lang?.apply_filter}
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Google map modal */}
      <Modal isOpen={locationModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setLocationModal(false)}></ModalHeader>
        <div style={{ height: "50vh" }}>
          {loadError && lang?.error_loading_maps}
          {!isLoaded && lang?.loading_maps}
          <GoogleMap
            mapContainerClassName="w-100 h-100"
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                title={JSON.stringify(markerPosition)}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
        <ModalFooter className="gap-4">
          <button
            className="btn btn-secondary py-2 px-4"
            onClick={() => {
              setLocationModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2"
            onClick={() => {
              setLatLng(markerPosition);
              setLocationModal(false);
              const lat = markerPosition.lat;
              const lng = markerPosition.lng;
              reverseGeocode(lat, lng);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EventCalendar;
