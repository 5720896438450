import React, { useState, useContext } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import Create_Complaint from "../Create_Complaint";
import arrow from "../../../assets/images/icon/arrow-right.png";
import axios from "axios";
import { GenerateHelpTicketApi } from "../../../constants/API";
import { useNavigate } from "react-router-dom";
import { SETTINGMODULE } from "../../../constants/RoutesUrl";
import { Language } from "../../../App";

const HelpSupport = () => {
  const style = {
    width: "14px",
    height: "18px",
    opacity: "50%",
  };
  const [createCompModal, setCreateCompModal] = useState(false);
  const [complaint, setComplaint] = useState(true);
  const [helpDescription, setHelpDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { lang } = useContext(Language);

  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;

  function complaintModalShow(id) {
    if (id === 1) {
      setCreateCompModal(true);
    } else {
      setComplaint(false);
    }
  }

  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    setHelpDescription(description);
    setIsSaveButtonDisabled(!description); // Disable the button if description is empty
  };

  const hanldeTicket = () => {
    setIsLoading(true);
    if (selectedCategory && helpDescription) {
      axios
        .post(GenerateHelpTicketApi, {
          user_id: login_id,
          description: helpDescription,
          type: selectedCategory,
        })
        .then((response) => {
          console.log("API response:", response.data);
          setIsLoading(false);
          setCreateCompModal(false);
          navigate(SETTINGMODULE + "/5");

          // Handle success, e.g., show a success message
        })
        .catch((error) => {
          console.error("API error:", error);
          setIsLoading(false);
          // Handle API error, e.g., show an error message
        });
    } else {
      console.error(lang?.please_select_category_enter_desc);
    }
  };
  const HelpSupportData = [
    {
      id: 1,
      title: lang?.report_problem,
    },
    {
      id: 2,
      title: lang?.my_report,
    },
  ];
  return (
    <>
      {complaint === true && (
        <div className="rounded pxy-20 bg-white w-xl-75 w-lg-100 box-shadow1 m-xl-0 m-lg-auto m-md-auto">
          <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
            {lang?.help_support}
          </h3>
          <div className="d-flex flex-column gap-4 mt-4">
            {HelpSupportData.map((props) => (
              <div
                className="box-shadow1 d-flex justify-content-between align-items-center p-16 rounded ps-4 pe-4 cursor manue-hover"
                onClick={() => complaintModalShow(props.id)}
              >
                <h4 className="fs-18 fs-md-16 fw-bold m-0">{props.title}</h4>
                <span>
                  <img src={arrow} alt="picture" style={style} />
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal
        isOpen={createCompModal}
        toggle={() => setCreateCompModal(!createCompModal)}
        centered
        zIndex={9}
        style={{ padding: "0" }}
      >
        <ModalBody style={{ padding: "0" }}>
          <div className="create-complaint br-8 pxy-20 bg-white w-100 m-xl-0 m-lg-auto m-md-auto m-sm-auto m-auto position-relative">
            <h3 className="d-flex justify-content-center mt-12 fs-20 fs-md-16 fw-bold">
              {lang?.create_complaint}
            </h3>

            <form className="w-100">
              <div>
                <h6 className="fs-16">{lang?.category}</h6>
                <select
                  className="text-gray form-select w-100 p-3 br-12"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option disabled selected>
                    {lang?.comp_cate}
                  </option>
                  <option>{lang?.cancel_subscription}</option>
                  <option>{lang?.refund_payment}</option>
                  <option>{lang?.compl_coach}</option>
                  <option>{lang?.videos_not_loading}</option>
                  <option>{lang?.app_crashes}</option>
                  <option>{lang?.help_support}</option>
                </select>
              </div>

              <div className="mt-3">
                <h6 className="fs-16">{lang?.description}</h6>
                <textarea
                  cols="30"
                  rows="7"
                  className="form-control fs-16 w-100"
                  placeholder={lang?.enter_compl_her}
                  onChange={handleDescriptionChange}
                ></textarea>
              </div>
              <div className="d-flex gap-3 mt-3">
                <button
                  className="w-100 manue-hover btn box-shadow1 rounded py-3 fs-16 fw-bold text-black"
                  onClick={() => {
                    setCreateCompModal(false);
                  }}
                >
                  {lang?.cancel}
                </button>
                <button
                  disabled={isLoading || isSaveButtonDisabled}
                  type="button"
                  className="w-100 bg-blue btn rounded py-3 fs-16 fw-bold text-white"
                  onClick={hanldeTicket}
                >
                  {isLoading ? <Spinner /> : lang?.create_complaint}
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      {complaint === false && <Create_Complaint />}
    </>
  );
};

export default HelpSupport;
