import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import { GoDotFill } from "react-icons/go";
import {
  BaseURL,
  ReadNotificationsApi,
  getNotificationsApi,
} from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { toast } from "react-toastify";
import {
  COURSE_DETAILS,
  EVENT_DETAILS,
  POST_DETAILS,
  PROFILE,
} from "../../constants/RoutesUrl";
import { Language } from "../../App";
import ImageComponent from "../common/ImageComponent";
import { CountContext } from "../../constants/ProtectedRoute";

const Notification = () => {
  const { count } = useContext(CountContext);
  // sessionStorage
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const { lang } = useContext(Language);

  const btns = [
    { id: 1, type: "social", name: lang?.posts, count: count?.social },
    { id: 2, type: "webinar", name: lang?.webinars, count: count?.webinar },
    {
      id: 3,
      type: "clinical",
      name: lang?.clinical_studies,
      count: count?.clinical,
    },
    { id: 4, type: "course", name: lang?.courses, count: count?.course },
    { id: 5, type: "event", name: lang?.events, count: count?.event },
    {
      id: 6,
      type: "clinical-session",
      name: lang?.clinical_sessions,
      count: count?.clinical_session,
    },
  ];

  // States
  const [dataLoading, setDataLoading] = useState(false);
  const [activeBtn, setActiveBtn] = useState(1);
  const [notificationsType, setNotificationsType] = useState("social");
  const [notificationData, setNotificationData] = useState({});

  // Functions
  const handleReadNotification = () => {
    axios
      .post(ReadNotificationsApi + user_id, { type: notificationsType })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleGetNotifications = () => {
    setDataLoading(true);
    axios
      .post(getNotificationsApi + user_id, { type: notificationsType })
      .then((res) => {
        if (res.data.status) {
          setNotificationData(res.data.data);
          handleReadNotification();
        } else {
          toast.error(res.data.action);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    handleGetNotifications();
  }, [notificationsType]);
  return (
    <Row className="justify-content-center">
      <Col lg={6} className="pt-3 vh-100 overflowY-auto scroll-hide pb-4">
        <div className="bg-white rounded mb-5 p-3 border border-1 border-inherit">
          {/* Tabs menu */}
          <div className="scroll-hide d-flex gap-2 mb-3 overflowX-auto">
            {btns &&
              btns.map((value) => (
                <div key={value.id}>
                  <button
                    type="button"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => {
                      setActiveBtn(value.id);
                      setNotificationsType(value.type);
                    }}
                    className={`rounded px-5 py-2 fw-bold position-relative manue-hover hover-blue ${
                      activeBtn === value.id
                        ? "bg-dark text-white border-0"
                        : "border border-1 border-inherit"
                    }`}
                  >
                    {value.name}{" "}
                    {value.count !== 0 && value.count ? (
                      <span>
                        <div
                          className="bg-danger rounded-circle position-absolute top-0 end-0 me-4 mt-1"
                          style={{
                            zIndex: "2",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          <p
                            className="fw-bold text-white mb-0"
                            style={{ fontSize: "10px" }}
                          >
                            {value.count !== 0 && value.count}
                          </p>
                        </div>
                      </span>
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              ))}
          </div>
          {/* Tabs menu ended */}

          {dataLoading ? (
            <div className="text-center pt-3">
              <Spinner />
            </div>
          ) : (
            <>
              {notificationData.data &&
                notificationData.data.map((value) => (
                  <>
                    {value.data.notification_type !== "follow" ? (
                      <NotificationCardLike value={value} />
                    ) : value.data.notification_type === "follow" ? (
                      <NotificationCardFollow value={value} />
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Notification;

export function UnixToRelative({ unixTimestamp }) {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const timestampDifference = currentTimestamp - unixTimestamp;
  const { lang } = useContext(Language);
  const intervals = [
    { label: lang?.year, seconds: 31536000 },
    { label: lang?.month, seconds: 2592000 },
    { label: lang?.day, seconds: 86400 },
    { label: lang?.hour, seconds: 3600 },
    { label: lang?.minute, seconds: 60 },
  ];

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const intervalCount = Math.floor(timestampDifference / interval.seconds);
    if (intervalCount >= 1) {
      if (intervalCount === 1) {
        return `${intervalCount} ${interval.label} ${lang?.ago}`;
      } else {
        return `${intervalCount} ${interval.label}s ${lang?.ago}`;
      }
    }
  }

  return `${lang?.just_now}`;
}

const NotificationCardFollow = ({ value }) => {
  const Navigate = useNavigate();
  return (
    <div
      className="border border-1 border-inherit mb-3 rounded overflow-hidden cursor"
      onClick={() => {
        Navigate(PROFILE + "/" + value.users[0].id);
      }}
      key={value.id}
    >
      <div className="border-bottom border-inherit border-1">
        <h6 className="mx-3 my-2 pb-0">
          <GoDotFill color="#00c5de" /> {value.date}
        </h6>
      </div>
      <div className="px-3 py-3 manue-hover">
        <div className="d-flex gap-3">
          {value.users_count > 1 ? (
            <div className="d-flex">
              <div>
                <div
                  className="bg-white rounded-1 dropdown-avatar-shadow"
                  style={{
                    maxWidth: "3rem",
                    maxHeight: "3rem",
                    padding: "0.8px",
                  }}
                >
                  <ImageComponent
                    src={
                      value?.users[0]?.thumbnail
                        ? BaseURL + value.users[0].thumbnail
                        : Avatar
                    }
                    compClassName="rounded-1 img-fluid"
                    loader_height="100%"
                    loader_width="100%"
                  />
                </div>
              </div>
              <div
                style={{
                  marginLeft: "-1.7rem",
                  marginTop: "1rem",
                }}
              >
                <div
                  className="bg-white rounded-1 dropdown-avatar-shadow"
                  style={{
                    maxWidth: "3rem",
                    maxHeight: "3rem",
                    padding: "0.8px",
                  }}
                >
                  <ImageComponent
                    src={
                      value?.users[1]?.thumbnail
                        ? BaseURL + value.users[1].thumbnail
                        : Avatar
                    }
                    compClassName="rounded-1 img-fluid"
                    loader_height="100%"
                    loader_width="100%"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div
                  className="bg-white rounded-1 dropdown-avatar-shadow"
                  style={{
                    maxWidth: "3rem",
                    maxHeight: "3rem",
                    padding: "0.8px",
                  }}
                >
                  <ImageComponent
                    src={
                      value?.users[0]?.thumbnail
                        ? BaseURL + value.users[0].thumbnail
                        : Avatar
                    }
                    compClassName="rounded-1 img-fluid"
                    loader_height="100%"
                    loader_width="100%"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="align-self-center">
            <div className="d-flex">
              {value.users_count > 1 ? (
                <p className="mb-0 fs-md-15">
                  <span className="fw-bold">{value?.users[0].name}</span> and{" "}
                  <span className="fw-bold">
                    {value.users_count > 2 ? "others" : value?.users[1].name}
                  </span>
                  {value.data.body}
                </p>
              ) : (
                <p className="mb-0 fs-md-15">
                  <span className="fw-bold">{value?.users[0].name}</span>{" "}
                  {value.data.body}
                </p>
              )}
            </div>
            <div>
              <small className="text-secondary">
                <UnixToRelative unixTimestamp={value.data.time} />
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotificationCardLike = ({ value }) => {
  const Navigate = useNavigate();
  return (
    <div
      className="border border-1 border-inherit mb-3 rounded overflow-hidden"
      key={value.id}
    >
      <div className="border-bottom border-inherit border-1">
        <h6 className="mx-3 my-2 pb-0">
          <GoDotFill color="#00c5de" /> {value.date}
        </h6>
      </div>
      <div className="px-3 py-3 manue-hover">
        <div className="d-flex justify-content-between">
          <div>
            <div className="d-flex gap-3">
              {value.users_count > 1 ? (
                <div className="d-flex">
                  <div>
                    <div
                      className="bg-white rounded-1 dropdown-avatar-shadow"
                      style={{
                        maxWidth: "3rem",
                        maxHeight: "3rem",
                        padding: "0.8px",
                      }}
                    >
                      <ImageComponent
                        src={
                          value?.users[0]?.thumbnail
                            ? BaseURL + value.users[0].thumbnail
                            : Avatar
                        }
                        compClassName="rounded-1 img-fluid"
                        loader_height="100%"
                        loader_width="100%"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: "-1.7rem",
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      className="bg-white rounded-1 dropdown-avatar-shadow"
                      style={{
                        maxWidth: "3rem",
                        maxHeight: "3rem",
                        padding: "0.8px",
                      }}
                    >
                      <ImageComponent
                        src={
                          value?.users[1]?.thumbnail
                            ? BaseURL + value.users[1].thumbnail
                            : Avatar
                        }
                        compClassName="rounded-1 img-fluid"
                        loader_height="100%"
                        loader_width="100%"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <div
                      className="bg-white rounded-1 dropdown-avatar-shadow"
                      style={{
                        maxWidth: "3rem",
                        maxHeight: "3rem",
                        padding: "0.8px",
                      }}
                    >
                      <ImageComponent
                        src={
                          value?.users[0]?.thumbnail
                            ? BaseURL + value.users[0].thumbnail
                            : Avatar
                        }
                        compClassName="rounded-1 img-fluid"
                        loader_height="100%"
                        loader_width="100%"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="align-self-center">
                <div className="d-flex">
                  {value.users_count > 1 ? (
                    <p className="mb-0 fs-md-15">
                      <span className="fw-bold">{value?.users[0].name}</span>{" "}
                      and{" "}
                      <span className="fw-bold">
                        {value.users_count > 2
                          ? "others"
                          : value?.users[1].name}
                      </span>
                      {value.data.body}
                    </p>
                  ) : (
                    <p className="mb-0 fs-md-15">
                      <span className="fw-bold">{value?.users[0].name}</span>{" "}
                      {value.data.body}
                    </p>
                  )}
                </div>
                <div>
                  <small className="text-secondary">
                    <UnixToRelative unixTimestamp={value.data.time} />
                  </small>
                </div>
              </div>
            </div>
          </div>
          {value.data.media && (
            <div>
              <div>
                <div
                  className="bg-white rounded-1 dropdown-avatar-shadow cursor"
                  onClick={() => {
                    if (value.data.type === "event") {
                      Navigate(EVENT_DETAILS + value.data.post_id);
                    } else if (value.data.type === "course") {
                      sessionStorage.setItem("CourseId", value.data.post_id);
                      Navigate(COURSE_DETAILS);
                    } else {
                      Navigate(POST_DETAILS + value.data.post_id);
                    }
                  }}
                  style={{
                    maxWidth: "2.5rem",
                    maxHeight: "2.5rem",
                    padding: "0.8px",
                  }}
                >
                  <img
                    src={BaseURL + value.data.media}
                    className="rounded-1"
                    style={{
                      width: "2.5rem",
                      height: "2.5rem",
                      objectFit: "cover",
                    }}
                    alt="picture"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
