import React, { useEffect, useContext, useState } from "react";
import Spinner from "../Spinner";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import PlaceholderImg from "../../assets/images/icon/placeholder_Awatar.png";
import axios from "axios";
import {
  AddCertificateApi,
  BaseURL,
  DeteleCourseCertificateApi,
  EditCertificateApi,
  GetCourseCertificatesListApi,
} from "../../constants/API";
import PlusIcon from "../../assets/images/icon/plus.png";
import UploadIcon from "../../assets/images/icon/upload.png";
import { Language } from "../../App";
import { toast } from "react-toastify";

const CourseCertificate = () => {
  const course_id = sessionStorage.getItem("CourseId");
  const [spin, setSpin] = useState(false);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(false);
  const [certificateInput, setCertificateInput] = useState(null);
  const [certificateImagePreview, setCertificateImagePreview] = useState(null);
  const [certificateName, setCertificateName] = useState("");
  const [addCertificateModal, setAddCertificateModal] = useState(false);

  const [editCertificateInput, setEditCertificateInput] = useState(null);
  const [
    editCertificateImagePreview,
    setEditCertificateImagePreview,
  ] = useState(null);
  const [editCertificateName, setEditCertificateName] = useState("");
  const [editCertificateModal, setEditCertificateModal] = useState(false);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const [editData, setEditData] = useState({});
  const { lang } = useContext(Language);

  const handleDelete = (id) => {
    setIsLoading(true);
    axios
      .get(DeteleCourseCertificateApi + id)
      .then((res) => {
        setIsLoading(false);
        setDeleteModal(false);
        if (res.data.status) {
          setDataRefetch(!dataRefetch);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleData = () => {
    setSpin(true);
    axios
      .get(GetCourseCertificatesListApi + course_id, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((res) => {
        setData(res.data.data);
        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
  };

  const handleEditCertificate = () => {
    setIsLoading(true);

    if (editData.title.length > 150) {
      setIsLoading(false);
      toast.error(lang?.title_is_long);
      return;
    }

    axios
      .post(
        EditCertificateApi,
        {
          certificate_id: editData.id,
          title: editData.title,
          media: editCertificateInput,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          setEditCertificateModal(false);
          setEditCertificateImagePreview(null);
          setEditCertificateName("");
          setData([]);
          setDataRefetch(!dataRefetch);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleAddCertificate = () => {
    setIsLoading(true);

    if (certificateName.length > 150) {
      setIsLoading(false);
      toast.error(lang?.title_is_long);
      return;
    }

    axios
      .post(
        AddCertificateApi,
        {
          course_id,
          title: certificateName,
          media: certificateInput,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          setAddCertificateModal(false);
          setCertificateImagePreview(null);
          setCertificateInput("");
          setCertificateName("");
          setDataRefetch(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleData();
  }, [dataRefetch]);

  return (
    <>
      <Row>
        {spin ? (
          <div className="ticketchatspinner">
            <Spinner />
          </div>
        ) : (
          <Col
            lg={7}
            className="bg-white rounded p-4 ms-lg-0 ms-md-0 ms-sm-0 ms-0"
          >
            <Row className="justify-content-end">
              <Col lg={8} className="d-flex justify-content-between">
                <h3 className="fs-20 fs-md-16 roboto-bold">
                  {lang?.course_certificates}
                </h3>
                <img
                  src={PlusIcon}
                  width={25}
                  height={25}
                  alt="picture"
                  className="cursor"
                  onClick={() => setAddCertificateModal(true)}
                />
              </Col>
            </Row>
            {data.map((value, index) => (
              <>
                <Row
                  key={index}
                  className="py-2 my-3 rounded"
                  style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <Col lg={8} className="d-flex gap-3">
                    <div className="align-self-center">
                      <div
                        className="bg-white p-1 rounded dropdown-avatar-shadow"
                        style={{ minHeight: "5rem", minWidth: "5rem" }}
                      >
                        <img
                          src={
                            value?.media === ""
                              ? PlaceholderImg
                              : BaseURL +
                                value?.media +
                                `?timestamp=${Date.now()}`
                          }
                          onClick={() => {
                            setEditData(value);
                            setImagePreviewModal(true);
                          }}
                          style={{
                            height: "5rem",
                            width: "5rem",
                            objectFit: "cover",
                          }}
                          className="cursor rounded"
                          alt="picture"
                        />
                      </div>
                    </div>
                    <div className="align-self-center">
                      <h4 className="fs-18 roboto-bold mb-0">{value.title}</h4>
                    </div>
                  </Col>
                  <Col
                    lg={4}
                    className="d-flex justify-content-around align-self-center"
                  >
                    <div className="justify-content-end gap-3 d-flex">
                      <button
                        className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                        style={{ background: "#69C34D" }}
                        onClick={() => {
                          setEditData(value);
                          setEditCertificateModal(true);
                        }}
                      >
                        {lang?.edit}
                      </button>

                      <button
                        className="btn-red border-0 rounded w-100 py-2 fs-md-14"
                        onClick={() => {
                          setDeleteId(value.id);
                          setDeleteModal(true);
                        }}
                      >
                        {lang?.delete}
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            ))}
          </Col>
        )}
      </Row>
      {/* Add Modal */}
      <Modal
        isOpen={addCertificateModal}
        toggle={() => setAddCertificateModal(!addCertificateModal)}
        centered
        zIndex={9}
        className="rounded"
      >
        <ModalBody className="p-4">
          <Row className="mb-3">
            <Col className="text-center">
              <h4 className="fs-20 roboto-bold">{lang?.certificate_by}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              {certificateImagePreview ? (
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    minHeight: "10rem",
                    borderRadius: "6px",
                  }}
                >
                  <button
                    className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                    onClick={() => {
                      setCertificateImagePreview(null);
                    }}
                    disabled={isLoading}
                  >
                    x
                  </button>
                  <img
                    src={certificateImagePreview}
                    alt="picture"
                    className=" br-6"
                    style={{
                      objectFit: "cover",
                      height: "10rem",
                      width: "100%",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "1px dashed #A2A2A2",
                    backgroundColor: "#F2FCFE",
                    minHeight: "10rem",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    !isLoading && document.getElementById("fileInput").click();
                  }}
                >
                  <div className="text-center mt-5">
                    <img
                      src={UploadIcon}
                      width={40}
                      height={40}
                      alt="picture"
                    />
                    <h4
                      className="fs-14 roboto-medium pt-3"
                      style={{ color: "#4470FF" }}
                    >
                      {lang?.add_certificate_image}
                    </h4>
                  </div>
                </div>
              )}
              <input
                type="file"
                id="fileInput"
                className="d-none"
                accept="image/*"
                disabled={isLoading}
                required
                onChange={(e) => {
                  const file = e.target.files[0];
                  setCertificateInput(file);
                  // Preview the image
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    setCertificateImagePreview(reader.result);
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <label className="fs-20 fs-md-16 roboto-medium">
                {lang?.certificate_name}
              </label>
              <input
                type="text"
                className="w-100 br-8 px-3 mt-2 form-control"
                disabled={isLoading}
                required
                onChange={(e) => setCertificateName(e.target.value)}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                  padding: "13px 13px",
                }}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <div className="justify-content-end gap-3 d-flex">
              <button
                disabled={isLoading || !certificateInput || !certificateName}
                className="btn-blue text-dark border-1 border rounded w-100 py-2 fs-md-14"
                style={{ borderRadius: "6px" }}
                onClick={() => {
                  handleAddCertificate();
                }}
              >
                {isLoading ? (
                  <div className="loader"></div>
                ) : (
                  lang?.add_certificate
                )}
              </button>

              <button
                className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14 rounded"
                onClick={() => {
                  setCertificateImagePreview(null);
                  setCertificateInput("");
                  setAddCertificateModal(false);
                }}
              >
                {lang?.cancel}
              </button>
            </div>
          </Row>
        </ModalBody>
      </Modal>

      {/* image preview modal */}
      <Modal
        isOpen={imagePreviewModal}
        centered
        zIndex={9}
        toggle={() => {
          setImagePreviewModal(!imagePreviewModal);
          setEditData({});
        }}
      >
        <img
          src={BaseURL + editData.media}
          alt="picture"
          className="img-fluid"
        />
      </Modal>
      {/* image preview modal ended */}

      {/* Edit Modal */}
      <Modal
        isOpen={editCertificateModal}
        centered
        className="rounded"
        zIndex={9}
      >
        <ModalBody className="p-4">
          <Row className="mb-3">
            <Col className="text-center">
              <h4 className="fs-20 roboto-bold">{lang?.certificate_by}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              {editData.media || editCertificateImagePreview ? (
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    minHeight: "10rem",
                    borderRadius: "6px",
                  }}
                >
                  <button
                    className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                    disabled={isLoading}
                    onClick={() => {
                      const updatedMedia = { ...editData, media: null };
                      setEditData(updatedMedia);
                      setEditCertificateImagePreview(null);
                    }}
                  >
                    x
                  </button>
                  <img
                    src={
                      editCertificateImagePreview
                        ? editCertificateImagePreview
                        : BaseURL + editData?.media
                    }
                    alt="picture"
                    className=" br-6"
                    style={{
                      objectFit: "cover",
                      height: "10rem",
                      width: "100%",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "1px dashed #A2A2A2",
                    backgroundColor: "#F2FCFE",
                    minHeight: "10rem",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    !isLoading && document.getElementById("editinput").click();
                  }}
                >
                  <div className="text-center mt-5">
                    <img
                      src={UploadIcon}
                      width={40}
                      height={40}
                      alt="picture"
                    />
                    <h4
                      className="fs-14 roboto-medium pt-3"
                      style={{ color: "#4470FF" }}
                    >
                      {lang?.add_certificate_image}
                    </h4>
                  </div>
                </div>
              )}
              <input
                type="file"
                id="editinput"
                disabled={isLoading}
                accept="image/*"
                className="d-none"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setEditCertificateInput(file);
                  // Preview the image
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const updated = reader.result.replace(BaseURL, "");
                    console.log(updated);
                    const update = { ...editData, media: updated };
                    setEditData(update);
                    setEditCertificateImagePreview(reader.result);
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <label className="fs-20 roboto-medium">
                {lang?.certificate_name}
              </label>
              <input
                type="text"
                className="w-100 br-8 px-3 mt-2"
                value={editData.title}
                disabled={isLoading}
                onChange={(e) => {
                  const updatedName = { ...editData, title: e.target.value };
                  setEditData(updatedName);
                }}
                style={{
                  border: "1px solid rgba(0,0,0,0.53)",
                  padding: "13px 13px",
                }}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <div className="justify-content-end gap-3 d-flex">
              <button
                disabled={isLoading || !editData.title || !editData.media}
                className="btn-blue text-dark border-1 border rounded w-100 py-2 fs-md-14"
                onClick={() => {
                  handleEditCertificate(editData.id);
                }}
              >
                {isLoading ? (
                  <div className="loader"></div>
                ) : (
                  lang?.edit_certificate
                )}
              </button>

              <button
                className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                onClick={() => {
                  setEditCertificateImagePreview(null);
                  setEditCertificateInput("");
                  setEditCertificateModal(false);
                }}
              >
                {lang?.cancel}
              </button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      {/* Delete Modal */}
      <Modal isOpen={deleteModal} centered zIndex={9999999}>
        <ModalBody>
          <div>
            <h2 className="text-blue fs-24 roboto-bold">
              {lang?.do_you_delete_certi}
            </h2>
            <p className="fs-18 roboto-regular">
              {lang?.do_you_delete_certi_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-red border-0 rounded w-100 py-2"
              onClick={() => {
                handleDelete(deleteId);
              }}
            >
              {isLoading ? <div className="loader"></div> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CourseCertificate;
