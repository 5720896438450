import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import Lottie from "lottie-react";
import Logo from "../assets/images/icon/mr-logo.webp";
import { IoSearch } from "react-icons/io5";
import { IoReorderThree } from "react-icons/io5";
import { CiCircleRemove } from "react-icons/ci";

import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import NotificationIcon from "../assets/images/icon/notification.webp";
import MessageIcon from "../assets/images/icon/message.webp";
import FilterIcon from "../assets/images/icon/filter.webp";

import HomeIcon from "../assets/images/icon/home.png";
import HomeActive from "../assets/images/icon/home-active.webp";
import JobIcon from "../assets/images/icon/job.png";
import JobActive from "../assets/images/icon/job-active.png";
import ClinicalActive from "../assets/images/icon/clinical-active.png";
import ClinicalIcon from "../assets/images/icon/clinical.png";
import EventsIcon from "../assets/images/icon/calendar.png";
import EventActive from "../assets/images/icon/calendar-active.png";
import CourseIcon from "../assets/images/icon/course.png";
import CourseActive from "../assets/images/icon/course-active.png";
import {
  CHAT,
  CLINICAL_SESSIONS,
  COURSES,
  EVENTS,
  FEED,
  JOBS,
  LIVE_WEBINARS,
  LOGIN,
  NOTIFICATIONS,
  PROFILE,
  SEARCH,
  SETTINGMODULE,
} from "../constants/RoutesUrl";
import { Link, useNavigate } from "react-router-dom";

import AnimatedImage from "../assets/images/live_webinar_lottie_file.json";
import WalletIcon from "../assets/images/icon/wallet.png";
import SettingsIcon from "../assets/images/icon/settings.png";
import FilesIcon from "../assets/images/icon/files.png";
import HelpIcon from "../assets/images/icon/headphones.png";
import LogoutIcon from "../assets/images/icon/logout.png";
import { BaseURL, CountApi, LogoutApi } from "../constants/API";
import axios from "axios";
import { toast } from "react-toastify";
import CustomTooltip from "./common/CustomTooltip";
import ImageComponent from "./common/ImageComponent";
import { Language } from "../App";
import { IoIosArrowForward } from "react-icons/io";
import { CountContext } from "../constants/ProtectedRoute";

const NavBar = () => {
  const { count, updateCount } = useContext(CountContext);
  const feed_url = sessionStorage.getItem("feed_url");
  const Navigate = useNavigate();

  const data = JSON.parse(localStorage.getItem("SignupData"));

  const [isLoading, setIsLoading] = useState(false);
  const [ModalStatus, setModalStatus] = useState(false);
  const [counts, setCounts] = useState({});
  const [naveOpen, setNaveOpen] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const { lang } = useContext(Language);

  const handleSubmit = () => {
    setIsLoading(true);
    const userId = data.data.id;
    axios
      .post(LogoutApi, {
        userId,
      })
      .then((response) => {
        if (response.data.status) {
          sessionStorage.clear();
          localStorage.clear();
          setModalStatus(false);
          setIsLoading(false);
          Navigate(LOGIN);
        } else {
          setIsLoading(false);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch(`${CountApi}${data.data.id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          updateCount(data);
          setCounts(data);
        } else {
          toast.error(data.error);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, []);

  const constantPagesData = [
    {
      id: "hometab",
      image: HomeIcon,
      image_active: HomeActive,
      active: true,
      tooltip: lang?.home,
      link: FEED,
      width: 23,
      height: 26,
      find: "",
    },
    {
      id: "eventtab",
      image: EventsIcon,
      image_active: EventActive,
      active: false,
      tooltip: lang?.events,
      link: EVENTS + "/1",
      width: 23,
      height: 26,
      find: "event",
    },
    {
      id: "coursetab",
      image: CourseIcon,
      image_active: CourseActive,
      active: false,
      tooltip: lang?.courses,
      link: COURSES + "/1",
      width: 21,
      height: 24,
      find: "course",
    },
    {
      id: "clinicaltab",
      image: ClinicalIcon,
      image_active: ClinicalActive,
      active: false,
      tooltip: lang?.clinical_sessions,
      link: CLINICAL_SESSIONS,
      width: 24,
      height: 24,
      find: "clinical",
    },
    {
      id: "jobtab",
      image: JobIcon,
      image_active: JobActive,
      active: false,
      tooltip: lang?.jobs,
      link: JOBS + "/1",
      width: 26,
      height: 24,
      find: "job",
    },
  ];

  const constantData = [
    { image: FilterIcon, toolTip: lang?.filters, link: null },
    { image: MessageIcon, toolTip: lang?.messages, link: CHAT },
    {
      image: NotificationIcon,
      toolTip: lang?.notifications,
      link: NOTIFICATIONS,
    },
  ];
  // profile collapse auto close
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [closingProfile, setClosingProfile] = useState(false);

  const collapseProfileDropdown = useRef(null);

  const handelProfileDropdown = (e) => {
    if (
      collapseProfileDropdown.current &&
      !collapseProfileDropdown.current.contains(e.target)
    ) {
      setClosingProfile(true);
      setTimeout(() => {
        setProfileDropdown(false);
        setClosingProfile(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (profileDropdown)
      document.addEventListener("mousedown", handelProfileDropdown);
    return () =>
      document.removeEventListener("mousedown", handelProfileDropdown);
  }, [profileDropdown]);

  // filter collapse auto
  const [typeDropdown, setTypeDropdown] = useState(false);
  const [closingFilter, setClosingFilter] = useState(false);
  const collapseFilter = useRef(null);

  const handelFilterDropdown = (e) => {
    if (collapseFilter.current && !collapseFilter.current.contains(e.target)) {
      setClosingFilter(true);
      setTimeout(() => {
        setTypeDropdown(false);
        setClosingFilter(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (typeDropdown)
      document.addEventListener("mousedown", handelFilterDropdown);
    return () =>
      document.removeEventListener("mousedown", handelFilterDropdown);
  }, [typeDropdown]);

  return (
    <>
      <Container
        fluid
        className="bg-white border-bottom border-1 border-inherit"
        style={{
          boxShadow: "rgba(0,0,0,0.3 ) 0px 0px 2px 0px",
          height: "56px",
        }}
      >
        <Row className="justify-content-between position-relative h-100 align-items-center">
          <Col
            xl={2}
            lg={3}
            md={12}
            sm={12}
            className="d-flex gap-3 justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center"
          >
            <div className="logo-991">
              <img
                src={Logo}
                alt="Medical Radar"
                style={{ width: "40px", height: "40px" }}
                className="cursor"
                onClick={() => {
                  Navigate(FEED);
                }}
              />
            </div>
            <div className="position-relative d-none-991">
              <IoSearch
                className="position-absolute m-auto top-0 bottom-0 ms-lg-3 ms-md-2 ms-sm-2 ms-1 cursor"
                size={20}
                color="grey"
                onClick={() => {
                  Navigate(SEARCH);
                }}
              />
              <button
                className="rounded-pill w-100 border border-1 border-inherit cursor pe-xl-4 pe-lg-2 text-gray"
                style={{
                  whiteSpace: "nowrap",
                  backgroundColor: "rgba(0, 197, 222,0.1)",
                  height: "40px",
                  paddingLeft: "2.6rem",
                  fontSize: "15px",
                }}
                onClick={() => {
                  Navigate(SEARCH);
                }}
              >
                {lang?.search_medical_radar}
              </button>
            </div>

            {/* show again third column in tab and mobile screen */}
            <Row className="justify-content-end d-lg-none filter-991">
              {counts?.active_webinar ? (
                <CustomTooltip
                  text="Live Webinars"
                  compClassName={`w_fit_content mt-1  ${
                    feed_url === window.location.href ? "right_15px" : ""
                  }`}
                  account_class="mt-2"
                  style={{ right: "-15px", top: "-2px" }}
                >
                  <Col
                    md={2}
                    sm={12}
                    className="cursor"
                    onClick={() => {
                      Navigate(LIVE_WEBINARS);
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Lottie
                        animationData={AnimatedImage}
                        loop
                        autoplay
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </Col>
                </CustomTooltip>
              ) : (
                <></>
              )}
              {constantData.map((value, index) => (
                <IconsComponent
                  image={value.image}
                  toolTip={value.toolTip}
                  key={index}
                  link={value?.link}
                  updateState={(newValue) => {
                    setTypeDropdown(newValue);
                  }}
                  counts={counts}
                />
              ))}
              <CustomTooltip
                text={lang?.account}
                compClassName="w_fit_content"
                topMargin="0.8rem"
                account_class="account-tooltip-768"
              >
                <Col className="profile-991">
                  <div
                    className="rounded-circle cursor"
                    onClick={() => setProfileModal(true)}
                    style={{
                      border: "2px solid #00c5de",
                      width: "40px",
                      padding: "1px",
                      height: "40px",
                      filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.16 ))",
                    }}
                  >
                    <ImageComponent
                      src={
                        counts?.user?.image
                          ? BaseURL + counts?.user?.image
                          : Avatar
                      }
                      compClassName="rounded-circle h-100 w-100"
                    />
                  </div>
                </Col>
              </CustomTooltip>
            </Row>
            <div
              className="position-absolute cursor d-lg-none"
              style={{ top: "15px", right: "7px" }}
            >
              <span
                onClick={() => {
                  Navigate(SEARCH);
                }}
                className="me-1"
              >
                <IoSearch size={24} />
              </span>
              <span onClick={() => setNaveOpen(!naveOpen)}>
                {naveOpen ? (
                  <CiCircleRemove size={24} />
                ) : (
                  <IoReorderThree size={25} />
                )}
              </span>
            </div>
          </Col>

          <Col
            xl={4}
            lg={6}
            md={12}
            sm={12}
            className={`naveShow text-center align-self-center ${
              naveOpen ? "d-block" : ""
            }`}
          >
            <Row className="d-flex justify-content-center my-0 my-lg-0 flex-nowrap">
              {constantPagesData.map((data) => (
                <PagesTabsComponent
                  key={data.id}
                  counts={counts}
                  image={
                    data.link === window.location.pathname ||
                    window.location.pathname.search(data.find) > 0
                      ? data.image_active
                      : data.image
                  }
                  status={
                    data.link === window.location.pathname ||
                    window.location.pathname.search(data.find) > 0
                  }
                  toolTip={data.tooltip}
                  link={data.link}
                  height={data.height}
                  width={data.width}
                />
              ))}
            </Row>
          </Col>

          <Col xl={3} lg={3} md={12} sm={12} className={`d-none-991`}>
            <Row className="justify-content-end me-1" style={{ gap: "0px" }}>
              {counts?.active_webinar ? (
                <CustomTooltip
                  text="Live Webinars"
                  compClassName="w_fit_content"
                  account_class="mt-3"
                >
                  <Col
                    md={2}
                    sm={12}
                    className="cursor col-12"
                    onClick={() => {
                      Navigate(LIVE_WEBINARS);
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Lottie
                        animationData={AnimatedImage}
                        loop
                        autoplay
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </Col>
                </CustomTooltip>
              ) : (
                <></>
              )}

              {constantData.map((value, index) => (
                <IconsComponent
                  image={value.image}
                  toolTip={value.toolTip}
                  key={index}
                  link={value?.link}
                  updateState={(newValue) => {
                    setTypeDropdown(newValue);
                  }}
                  counts={counts}
                />
              ))}

              <CustomTooltip
                text={lang?.account}
                compClassName="w_fit_content"
                topMargin="0.8rem"
                account_class="m--7px"
              >
                <Col>
                  <div
                    className="rounded-circle cursor"
                    onClick={() => setProfileDropdown(!profileDropdown)}
                    style={{
                      border: "2px solid #00c5de",
                      width: "40px",
                      padding: "1px",
                      height: "40px",
                      filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.16 ))",
                    }}
                  >
                    <ImageComponent
                      src={
                        counts?.user?.image
                          ? BaseURL + counts?.user?.image
                          : Avatar
                      }
                      compClassName="rounded-circle h-100 w-100"
                    />
                  </div>
                </Col>
              </CustomTooltip>
              {/* profile modal */}
              <Modal
                centered
                isOpen={profileModal}
                toggle={() => setProfileModal(!profileModal)}
                data-bs-keyboard="false"
                backdrop={true}
              >
                <ModalBody className="p-2">
                  <div className="bg-white rounded p-2 d-flex flex-column gap-2">
                    <Link
                      to={PROFILE + "/" + counts?.user?.id}
                      style={{
                        zIndex: "9",
                      }}
                      className="px-2 py-2 mt-1 manue-hover hover-blue rounded d-flex justify-content-between align-items-center shadow-sid"
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          className="rounded-circle"
                          src={
                            counts?.user?.image
                              ? BaseURL + counts?.user?.image
                              : Avatar
                          }
                          alt="picture"
                          width={45}
                          height={45}
                        />
                        <div>
                          <div className="fs-14 roboto-bold">
                            {counts?.user?.name}
                          </div>
                          <p className="fs-12 roboto-medium m-0">
                            @{counts?.user?.user_name}
                          </p>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>
                    {/* <hr className="my-1" /> */}
                    <div className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue cursor">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img
                            src={WalletIcon}
                            alt="picture"
                            className="w-100"
                          />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.wallet}: 0.0$
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </div>
                    {/* <hr className="my-1" /> */}
                    <Link
                      to={SETTINGMODULE + "/1"}
                      className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img
                            src={SettingsIcon}
                            alt="picture"
                            className="w-100"
                          />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.manage_account}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>
                    {/* <hr className="my-1" /> */}
                    <Link
                      to={SETTINGMODULE + "/6"}
                      className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img
                            src={FilesIcon}
                            alt="picture"
                            className="w-100"
                          />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.terms_privacy}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>
                    {/* <hr className="my-1" /> */}
                    <Link
                      to={SETTINGMODULE + "/5"}
                      className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img src={HelpIcon} alt="picture" className="w-100" />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.help}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>
                    {/* <hr className="my-1" /> */}
                    <div
                      className="px-2 rounded py-2 d-flex gap-3 align-items-center manue-hover hover-blue cursor"
                      onClick={() => {
                        setProfileDropdown(false);
                        setModalStatus(true);
                      }}
                    >
                      <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                        <img src={LogoutIcon} alt="picture" className="w-100" />
                      </div>
                      <div>
                        <h5 className="fs-14 roboto-bold m-0">
                          {lang?.sign_out}
                        </h5>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </Row>
          </Col>

          <Collapse
            isOpen={typeDropdown && !closingFilter}
            innerRef={collapseFilter}
            className="position-absolute end-0 top-0"
            style={{
              zIndex: "99",
              marginTop: "56px",
              maxWidth: "18rem",
            }}
          >
            <div
              className="bg-white rounded overflow-hidden"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 3px 15px" }}
            >
              <CheckBoxComponent />
            </div>
          </Collapse>

          <Collapse
            isOpen={profileDropdown && !closingProfile}
            innerRef={collapseProfileDropdown}
            className="position-absolute end-0 top-0"
            style={{ zIndex: "99", marginTop: "56px", maxWidth: "360px" }}
          >
            <div
              className="bg-white rounded p-2 d-flex flex-column gap-2"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 3px 15px",
              }}
            >
              <Link
                to={PROFILE + "/" + counts?.user?.id}
                style={{
                  zIndex: "9",
                }}
                className="px-2 py-2 mt-1 hover-blue manue-hover rounded d-flex justify-content-between align-items-center shadow-sid"
              >
                <div className="d-flex gap-2 align-items-center">
                  <img
                    className="rounded-circle"
                    src={
                      counts?.user?.image
                        ? BaseURL + counts?.user?.image
                        : Avatar
                    }
                    alt="picture"
                    width={45}
                    height={45}
                  />
                  <div>
                    <div className="fs-14 roboto-bold">
                      {counts?.user?.name}
                    </div>
                    <p className="fs-12 roboto-medium m-0">
                      @{counts?.user?.user_name}
                    </p>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <div className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue cursor">
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={WalletIcon} alt="picture" className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">
                      {lang?.wallet}: 0.0$
                    </h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </div>
              {/* <hr className="my-1" /> */}
              <Link
                to={SETTINGMODULE + "/1"}
                className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={SettingsIcon} alt="picture" className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">
                      {lang?.manage_account}
                    </h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <Link
                to={SETTINGMODULE + "/6"}
                className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={FilesIcon} alt="picture" className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">
                      {lang?.terms_privacy}
                    </h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <Link
                to={SETTINGMODULE + "/5"}
                className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={HelpIcon} alt="picture" className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">{lang?.help}</h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <div
                className="px-2 rounded py-2 d-flex gap-3 align-items-center manue-hover hover-blue cursor"
                onClick={() => {
                  setProfileDropdown(false);
                  setModalStatus(true);
                }}
              >
                <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                  <img src={LogoutIcon} alt="picture" className="w-100" />
                </div>
                <div>
                  <h5 className="fs-14 roboto-bold m-0">{lang?.sign_out}</h5>
                </div>
              </div>
            </div>
          </Collapse>
        </Row>
      </Container>

      <Modal
        isOpen={ModalStatus}
        toggle={() => setModalStatus(!ModalStatus)}
        centered
        zIndex={9999}
      >
        <ModalBody className="bg-white rounded p-4">
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_sign_out_account}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.signout_modal_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setModalStatus(false);
              }}
            >
              {lang?.cancel}
            </button>

            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => {
                handleSubmit();
              }}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.yes_signout}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default NavBar;

const PagesTabsComponent = ({
  image,
  status,
  toolTip,
  link,
  height,
  width,
  counts,
}) => {
  const Navigate = useNavigate();
  const { lang } = useContext(Language);
  return (
    <CustomTooltip text={toolTip} compClassName="col" topMargin="0.7rem">
      <Col
        className={`position-relative cursor p-2 rounded ${
          status ? "bg-white" : "tab-bg"
        }`}
        onClick={() => {
          if (link === window.location.pathname) {
            if (toolTip === lang?.home) {
              sessionStorage.removeItem("prevFeedData");
            } else if (toolTip === lang?.events) {
              sessionStorage.removeItem("prevEventData");
              sessionStorage.removeItem("prevEventsCounts");
            } else if (toolTip === lang?.courses) {
              sessionStorage.removeItem("prevCourseData");
            } else if (toolTip === lang?.jobs) {
              sessionStorage.removeItem("prevJobsData");
            } else if (toolTip === lang?.clinical_sessions) {
              sessionStorage.removeItem("prevClinicalSessionsData");
              sessionStorage.removeItem("prevStartedClinicalSessionsData");
            } else {
              return;
            }
            window.location.reload();
          } else {
            Navigate(link);
          }
        }}
      >
        {toolTip === lang?.clinical_sessions ? (
          <div
            className="position-relative d-flex justify-content-center"
            style={{ marginTop: "2.5px" }}
          >
            <img src={image} alt="picture" height={height} width={width} />
            {counts && counts.active_clinical_session > 0 ? (
              <div
                className="bg-danger rounded-circle position-absolute top-0"
                style={{
                  zIndex: "2",
                  width: "15px",
                  height: "15px",
                  margin: "-3px -7px 0px 10px",
                }}
              >
                <p
                  className="fw-bold text-white m-0"
                  style={{ fontSize: "10px" }}
                >
                  {counts.active_clinical_session}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <img src={image} alt="picture" height={height} width={width} />
        )}
        {status && (
          <div
            className="rounded-pill bg-blue position-absolute w-100 end-0 mx-0"
            style={{
              height: "6px",
              marginTop: "0.6rem",
            }}
          ></div>
        )}
      </Col>
    </CustomTooltip>
  );
};

const IconsComponent = ({ image, toolTip, link, updateState, counts }) => {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { lang } = useContext(Language);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {toolTip === lang?.filters && window.location.pathname !== "/feed" ? (
        <></>
      ) : (
        <>
          <div className="w_fit_content">
            <div>
              <div
                className="rounded-circle border border-2 border-inherit text-center bg-blue-opacity1 cursor position-relative d-flex justify-content-center align-items-center"
                onClick={() => {
                  if (link === null) {
                    if (screenWidth < 992) {
                      setFilterModal(true);
                    } else {
                      updateState(!open);
                    }
                  } else {
                    Navigate(link);
                  }
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              >
                {toolTip === lang?.filters &&
                sessionStorage.getItem("FeedType") &&
                sessionStorage.getItem("FeedType") !== "all" ? (
                  <div className="bg-danger rounded-circle position-absolute top-0 end-0">
                    <p
                      className="fw-bold text-white mb-0 p-1"
                      style={{ fontSize: "10px", paddingTop: "2px" }}
                    ></p>
                  </div>
                ) : toolTip === lang?.messages && counts.unread > 0 ? (
                  <div
                    className="bg-danger rounded-circle position-absolute top-0 end-0 mt-1"
                    style={{
                      marginRight: "3px",
                      zIndex: "2",
                      width: "15px",
                      height: "15px",
                    }}
                  >
                    <p
                      className="fw-bold text-white mb-0"
                      style={{ fontSize: "10px" }}
                    >
                      {counts.unread}
                    </p>
                  </div>
                ) : toolTip === lang?.notifications &&
                  counts?.clinical +
                    counts?.clinical_session +
                    counts?.event +
                    counts?.course +
                    counts?.social +
                    counts?.webinar >
                    0 ? (
                  <div
                    className="bg-danger rounded-circle position-absolute top-0 end-0 mt-1"
                    style={{
                      marginRight: "3px",
                      zIndex: "2",
                      width: "15px",
                      height: "15px",
                    }}
                  >
                    <p
                      className="fw-bold text-white mb-0"
                      style={{ fontSize: "10px" }}
                    >
                      {counts &&
                        counts?.clinical +
                          counts?.clinical_session +
                          counts?.event +
                          counts?.course +
                          counts?.social +
                          counts?.webinar}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <CustomTooltip text={toolTip}>
                  {toolTip === lang?.filters ? (
                    <img
                      src={image}
                      alt="Medical Radar"
                      height={18}
                      width={18}
                    />
                  ) : toolTip === lang?.notifications ? (
                    <img
                      src={image}
                      alt="Medical Radar"
                      height={20}
                      style={{ marginBottom: "2px" }}
                      width={16}
                    />
                  ) : toolTip === lang?.messages ? (
                    <img
                      src={image}
                      alt="Medical Radar"
                      style={{ marginBottom: "3px" }}
                      height={18}
                      width={21}
                    />
                  ) : (
                    ""
                  )}
                </CustomTooltip>
              </div>
            </div>
          </div>
          <Modal
            centered
            isOpen={filterModal}
            toggle={() => setFilterModal(!filterModal)}
          >
            <ModalBody className="p-0">
              <CheckBoxComponent />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

IconsComponent.args = {
  autohide: true,
  flip: true,
};

IconsComponent.argTypes = {
  placement: {
    control: { type: "select" },
    options: ["bottom"],
  },
};
PagesTabsComponent.args = {
  autohide: true,
  flip: true,
};

PagesTabsComponent.argTypes = {
  placement: {
    control: { type: "select" },
    options: ["bottom"],
  },
};

const CheckBoxComponent = () => {
  const TypeData = sessionStorage.getItem("FeedType");
  const [type, setType] = useState(TypeData ? TypeData : "all");

  const handleType = (type) => {
    sessionStorage.removeItem("prevFeedData");
    sessionStorage.setItem("FeedType", type);
    window.location.reload();
  };
  const { lang } = useContext(Language);
  const TypesData = [
    { text: lang?.see_all, type: "all" },
    { text: lang?.posts, type: "posts" },
    { text: lang?.poll, type: "poll" },
    { text: lang?.webinars, type: "webinar" },
    { text: lang?.clinical_studies, type: "clinical-image" },
  ];

  return (
    <div className="d-flex flex-column rounded overflow-hidden">
      {TypesData.map((data, index) => (
        <>
          <div
            className="d-flex justify-content-between manue-hover hover-blue py-2 px-3 cursor"
            onClick={() => {
              setType(data.type);
              handleType(data.type);
            }}
            key={index}
          >
            <div className="align-self-center">
              <h3 className="fs-16 roboto-bold mb-0">{data.text}</h3>
            </div>
            <div className="form-check align-self-center">
              <input
                className="form-check-input"
                value={data.type}
                checked={type === data.type}
                type="radio"
                name="poll"
                id={"poll"}
              />
            </div>
          </div>
          <hr
            className={`my-0 ${data.type === "clinical-image" ? "d-none" : ""}`}
          />
        </>
      ))}
    </div>
  );
};
