import React, { FC } from 'react';

interface Props {
	className?: string;
}

export const ResetIcon: FC<Props> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="17"
			viewBox="-0.5 -0.5 17 17"
			xmlSpace="preserve"
		>
			<path
				fill="#FFF"
				d="M10.4 12.5H5.6c-1.2 0-2.1-.9-2.1-2.1V5.6c0-1.2.9-2.1 2.1-2.1h4.9c1.2 0 2.1.9 2.1 2.1v4.9c-.1 1.1-1 2-2.2 2zM5.6 5c-.4 0-.6.2-.6.6v4.9c0 .3.2.5.6.5h4.9c.3 0 .6-.3.6-.6V5.6c-.1-.4-.3-.6-.7-.6H5.6zM14 16.5h-2.5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H14c.4 0 .8.3.8.8s-.4.8-.8.8zm-4.9 0H6.6c-.4 0-.8-.3-.8-.8s.4-.7.8-.7h2.5c.4 0 .8.3.8.8s-.4.7-.8.7zm-5 0H1.6c-.4 0-.8-.3-.8-.8s.4-.7.8-.7h2.5c.4 0 .8.3.8.8s-.4.7-.8.7zm11.7-1.3c-.4 0-.8-.3-.8-.7V12c0-.4.3-.8.8-.8s.8.3.8.8v2.5c-.1.3-.4.7-.8.7zM.2 14.8c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.4.8.8V14c0 .4-.3.8-.8.8zm15.6-4.6c-.4 0-.8-.3-.8-.8V6.9c0-.4.3-.8.8-.8s.8.3.8.8v2.5c-.1.4-.4.8-.8.8zM.2 9.8c-.4 0-.8-.3-.8-.8V6.6c0-.4.3-.8.8-.8s.8.3.8.8V9c0 .5-.3.8-.8.8zm15.6-4.6c-.4 0-.8-.3-.8-.8V2c0-.4.3-.8.8-.8s.8.3.8.8v2.5c-.1.4-.4.7-.8.7zM.2 4.8c-.4 0-.8-.3-.8-.8V1.6c0-.4.3-.8.8-.8s.8.4.8.8v2.5c0 .4-.3.7-.8.7zM14.4 1h-2.5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h2.5c.4 0 .8.3.8.8s-.4.8-.8.8zm-5 0H7c-.5 0-.8-.3-.8-.8s.3-.7.8-.7h2.5c.4 0 .8.3.8.8s-.4.7-.9.7zM4.5 1H2c-.4 0-.8-.3-.8-.8s.4-.7.8-.7h2.5c.4 0 .8.3.8.8s-.4.7-.8.7z"
			/>
		</svg>
	);
};
