import React, { useCallback, useContext, useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import Cropper from "react-easy-crop";
import axios from "axios";
import getCroppedImg from "../../../hooks/useCropImage";
import {
  BaseURL,
  EditProfileInfoApi,
  RemoveProfilePicApi,
} from "../../../constants/API";
import Avatar from "./../../../assets/images/icon/placeholder_Awatar.png";
import { FaArrowLeft } from "react-icons/fa";
import { BiSolidUser } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { TfiWorld } from "react-icons/tfi";
import { MdLocationOn } from "react-icons/md";
import { TbWorldPlus } from "react-icons/tb";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_KEY } from "../../../constants/Keys";
import { TiCancel } from "react-icons/ti";
import { Language } from "../../../App";
import { toast } from "react-toastify";

const EditProfile = () => {
  const navigate = useNavigate(""); // Used for navigation

  // sessionStorage
  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;
  const userlocation = localData?.user?.location;

  const location = useLocation(); // Used for params
  const searchParams = new URLSearchParams(location.search);

  // Params
  const user_name = searchParams.get("user_name");
  const name = searchParams.get("name");
  const bio = searchParams.get("bio");
  const link = searchParams.get("link");
  const locations = searchParams.get("location");
  const profileImage = searchParams.get("image");
  const email = searchParams.get("email");
  const { lang } = useContext(Language);

  // States
  const [editData, setEditData] = useState({
    name,
    user_name,
    bio,
    link,
    locations,
    profileImage,
    email,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [cropperModal, setCropperModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [typeDropdown, setTypeDropdown] = useState(false);
  const [isLoadingremovepic, setIsLoadingRemovePic] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [formData, setFormData] = useState({});
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  // Map click
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Update the state
    setCity(""); // Reset city and country when the map is clicked
    setCountry("");
    setEditData({
      ...editData,
      latitude: lat,
      longitude: lng,
    });

    reverseGeocode(lat, lng);
  };

  // Google Map
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
  });

  // New state for controlling the Remove modal
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  // Function to open the Remove modal
  const openRemoveModal = () => {
    setRemoveModalOpen(true);
  };

  // Function to close the Remove modal
  const closeRemoveModal = () => {
    setRemoveModalOpen(false);
  };

  //history
  const goBack = () => {
    window.history.back();
  };

  // Function to open the image modal
  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  // Function to close the image modal
  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Functions
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropper = async () => {
    try {
      const croppedImage = await getCroppedImg(
        editData.profileImage,
        croppedAreaPixels
      );
      // Replace the Blob URL with your actual Blob URL
      const blobUrl = croppedImage;
      // Fetch the Blob content
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a FileReader to read the Blob as base64
          const reader = new FileReader();
          reader.onload = () => {
            // The result will be the Blob content as base64
            const base64Data = reader.result;
            setEditData({ ...editData, profileImage: base64Data });
          };
          // Read the Blob as data URL
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error(error));
    } catch (e) {
      console.error(e);
    }
  };

  // Initialize editData using localData
  useEffect(() => {
    setEditData({
      bio: bio || localData.user.bio || "", // Use bio if provided, then localData's bio, otherwise empty string
      link: link || localData.user.link || "", // Use link if provided, then localData's link, otherwise empty string
      locations: locations || localData.user.location || "", // Use locations if provided, then localData's location, otherwise empty string
    });
  }, [bio, link, locations]);

  const handleSave = () => {
    setIsLoading(true);

    // Replace empty fields with "@@@drudotstech@@@"
    const {
      name,
      user_name,
      bio,
      link,
      locations,
      latitude,
      longitude,
      profileImage,
    } = editData;

    console.log(locations);

    const replaceEmptyWithDefault = (value, defaultValue) => {
      if (!value || value.trim() === "") {
        return defaultValue;
      }
      return value;
    };

    const data = {
      name,
      user_name,
      location: replaceEmptyWithDefault(locations, "@@@drudotstech@@@"),

      // email,
      link: replaceEmptyWithDefault(link, "@@@drudotstech@@@"),
      bio: replaceEmptyWithDefault(bio, "@@@drudotstech@@@"),

      latitude,
      longitude,
      image_base64: profileImage,
    };

    if (markerPosition) {
      // Include location data if markerPosition is available
      data.lat = markerPosition.lat;
      data.lng = markerPosition.lng;
    }

    axios
      .post(EditProfileInfoApi + login_id, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status) {
          navigate("/profile/" + login_id);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  // Location Lat_Lng
  const handleLocation = () => {
    if (formData?.lat_lng || markerPosition) {
      setLocationModal(true);
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log({ latitude, longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        () => {}
      );
      setTimeout(() => {
        setLocationModal(true);
      }, 500);
    } else {
      toast.error(lang?.geolocation_not_suported);
    }
  };

  // Marker drag
  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Update the state
    setEditData({
      ...editData,
      locations: `${lat}, ${lng}`, // Set the locations field with lat and lng
      latitude: lat, // Set the latitude field
      longitude: lng, // Set the longitude field
    });

    reverseGeocode(lat, lng);
  };

  // To get city and country using lat lng
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        let cityName = "";
        let countryName = "";

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          } else if (component.types.includes("country")) {
            countryName = component.long_name;
          }
        }

        setCity(cityName);
        setCountry(countryName);

        // Update the locations field with the name of the location
        setEditData({
          ...editData,
          locations: `${cityName}, ${countryName}`,
        });
      } else {
        setCity("");
        setCountry("");
      }
    });
  };

  //remove pic api
  const handleRemovePicture = () => {
    setIsLoadingRemovePic(true);
    axios
      .get(RemoveProfilePicApi + login_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
          setIsLoadingRemovePic(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(lang?.err_remove_img, err);
      });
  };

  return (
    <div className="edit-profile rounded pxy-20 px-0 bg-white box-shadow1 m-xl-0 m-lg-auto m-md-auto position-relative">
      <div
        className="arrow cursor manue-hover py-2 px-3 d-flex justify-content-center align-items-center float-start position-absolute"
        style={{ left: "1px", top: "10px", borderRadius: "4px 0" }}
        onClick={goBack}
      >
        <FaArrowLeft />
      </div>
      <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
        {lang?.edit_profile}
      </h3>
      <form className="w-75 m-auto">
        <div className="d-flex justify-content-center mt-4">
          <div className="profile_img position-relative">
            <img
              src={
                editData?.profileImage
                  ? editData?.profileImage
                  : localData?.user.image
                  ? BaseURL + localData?.user?.image
                  : Avatar
              }
              alt="picture"
              id="profileimage"
              onClick={openImageModal}
            />
            <Modal
              isOpen={isImageModalOpen}
              centered
              zIndex={9}
              toggle={closeImageModal}
            >
              <img
                src={
                  localData?.user?.image
                    ? BaseURL + localData?.user?.image
                    : Avatar
                }
                alt="Profile Image"
                className="img-fluid rounded"
              />
            </Modal>
            <input
              type="file"
              id="media"
              accept="image/*"
              className="d-none"
              onChange={(e) => {
                const file = e.target.files[0];
                // Preview the image
                const reader = new FileReader();
                reader.onloadend = () => {
                  setEditData({
                    ...editData,
                    profileImage: reader.result,
                  });
                  setCropperModal(true);
                };
                reader.readAsDataURL(file);
              }}
            />
            <label
              className="plus manue-hover rounded"
              onClick={() => setTypeDropdown(!typeDropdown)}
            >
              <HiPlus color="black" size={25} />
            </label>
            <Collapse
              isOpen={typeDropdown}
              className="position-absolute"
              style={{ zIndex: "99999" }}
            >
              <div className="bg-white p-1 rounded dropdown-avatar-shadow">
                {localData?.user?.image && (
                  <div
                    className="align-self-center manue-hover p-2 px-3 rounded"
                    onClick={() => {
                      openRemoveModal();
                      setTypeDropdown(false); // Close the dropdown
                    }}
                  >
                    <label className=" roboto-bold mb-0 remove-btn cursor">
                      {lang?.remove}
                    </label>
                  </div>
                )}
                <div className="align-self-center manue-hover p-2 px-3 rounded">
                  <label
                    className="change-btn roboto-bold mb-0 cursor"
                    htmlFor="media"
                    onClick={() => setTypeDropdown(false)}
                  >
                    {lang?.change}
                  </label>
                </div>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-3">
          <div>
            <h6 className="fs-16 fw-bold opacity-75">
              <BiSolidUser color="#00C5DE" size={22} /> {lang?.full_name}
            </h6>
            <input
              type="text"
              defaultValue={
                editData?.name ? editData?.name : localData?.user?.name
              }
              onChange={(e) =>
                setEditData({ ...editData, name: e.target.value })
              }
              className="rounded"
            />
          </div>

          <div>
            <h6 className="fs-16 fw-bold opacity-75">
              <BiSolidUser color="#00C5DE" size={22} /> {lang?.user_name}
            </h6>
            <input
              type="text"
              defaultValue={
                editData?.user_name
                  ? editData?.user_name
                  : localData?.user?.user_name
              }
              onChange={(e) =>
                setEditData({ ...editData, user_name: e.target.value })
              }
              className="rounded"
            />
          </div>

          <div>
            <h6 className="fs-16 fw-bold opacity-75">
              {" "}
              <MdLocationOn color="#00C5DE" size={22} /> {lang?.location}
            </h6>

            <div className="position-relative">
              <input
                onClick={handleLocation}
                type="text"
                className="py-2 rounded"
                placeholder={lang?.city_country}
                value={
                  formData?.lat_lng && city && country
                    ? `${city}, ${country}`
                    : userlocation
                }
                onChange={(e) =>
                  setEditData({ ...editData, locations: e.target.value })
                }
              />
              {city !== "" && country !== "" && (
                <div
                  className="position-absolute end-0 top-0 me-2"
                  style={{ marginTop: "13px" }}
                >
                  <h6
                    className="text-primary cursor"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        lat_lng: "",
                      });
                      setCity("");
                      setCountry("");
                    }}
                  >
                    <TiCancel size={18} />
                    {lang?.remove}
                  </h6>
                </div>
              )}
            </div>
          </div>

          <div>
            <h6 className="fs-16 fw-bold opacity-75">
              <MdEmail color="#00C5DE" size={22} /> {lang?.email_address}
            </h6>
            <input
              type="text"
              // defaultValue={
              //   editData.email ? editData.email : localData.user.email
              // }
              value={localData?.user?.email}
              // onChange={(e) =>
              //   setEditData({ ...editData, email: e.target.value })
              // }
              className="rounded"
            />
          </div>

          <div>
            <h6 className="fs-16 fw-bold opacity-75">
              {" "}
              <TfiWorld color="#00C5DE" size={22} /> {lang?.website}
            </h6>
            <input
              type="text"
              placeholder={lang?.website_link}
              defaultValue={
                editData?.link ? editData?.link : localData?.user?.link
              }
              onChange={(e) =>
                setEditData({ ...editData, link: e.target.value })
              }
              className="rounded"
            />
          </div>

          <div>
            <h6 className="fs-16 fw-bold opacity-75">
              <TbWorldPlus color="#00C5DE" size={25} /> {lang?.bio}
            </h6>
            <textarea
              cols="30"
              rows="10"
              placeholder={lang?.enter_your_bio}
              defaultValue={
                editData?.bio ? editData?.bio : localData?.user?.bio
              }
              onChange={(e) =>
                setEditData({ ...editData, bio: e.target.value })
              }
              className="rounded"
            ></textarea>
          </div>

          <button
            type="button"
            disabled={isLoading}
            className="bg-blue border-0 rounded fs-16 fw-bold text-white p-lg-3 p-md-2 ps-md-2 p-2"
            onClick={handleSave}
          >
            {isLoading ? <Spinner /> : lang?.update}
          </button>
        </div>
        {/* image cropping  */}
        <Modal isOpen={cropperModal} centered zIndex={9}>
          <div className="bg-white px-3 rounded">
            <ModalHeader toggle={() => setCropperModal(false)}>
              {lang?.image_croper}
            </ModalHeader>
            <div className="position-relative" style={{ minHeight: "40rem" }}>
              <Cropper
                image={editData?.profileImage}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className="d-none">
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
            </div>
            <ModalFooter className="gap-3">
              <button
                className="btn btn-secondary border-0 px-4 py-2"
                onClick={() => {
                  setCropperModal(false);
                }}
              >
                {lang?.cancel}
              </button>
              <button
                className="btn-blue border-0 px-4 py-2"
                onClick={() => {
                  handleCropper();
                  setCropperModal(false);
                }}
              >
                {lang?.save}
              </button>
            </ModalFooter>
          </div>
        </Modal>
      </form>

      {/* Modal for Remove */}
      <Modal
        isOpen={removeModalOpen}
        centered
        zIndex={9}
        toggle={closeRemoveModal}
      >
        <div className="bg-white px-3 rounded">
          {/* Add the content for your Remove modal here */}
          <ModalHeader toggle={closeRemoveModal}>
            {lang?.confirm_removal}
          </ModalHeader>
          <ModalBody>{lang?.are_sure_remove_picture}</ModalBody>
          <ModalFooter>
            <div className="d-flex gap-3 w-100">
              <button
                className="btn btn-secondary border-0 w-100 py-2 px-4"
                onClick={closeRemoveModal}
              >
                {lang?.cancel}
              </button>

              <button
                type="button"
                className="btn btn-danger border-0 w-100 py-2 px-4"
                onClick={handleRemovePicture}
              >
                {isLoadingremovepic ? <Spinner /> : lang?.remove}
              </button>
            </div>
          </ModalFooter>
        </div>
      </Modal>

      {/* Google map modal */}
      <Modal isOpen={locationModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setLocationModal(false)}></ModalHeader>
        {loadError && lang?.error_loading_maps}
        {!isLoaded && lang?.loading_maps}
        <div style={{ height: "50vh" }}>
          <GoogleMap
            mapContainerClassName="w-100 h-100"
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                title={JSON.stringify(markerPosition)}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
        <ModalFooter className="gap-4">
          <button
            className="btn btn-secondary py-2 px-4"
            onClick={() => {
              setLocationModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2"
            onClick={() => {
              setFormData({ ...formData, lat_lng: markerPosition });
              setLocationModal(false);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditProfile;
