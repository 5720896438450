import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { EditProfileInfoApi, GetSubcategories } from "../../../constants/API";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SpecSpinner from "./SpecSpinner";
import { Language } from "../../../App";

function Specialization() {
  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;
  const Sub_Category = localData.user.subcategory;
  const [selectedItems, setSelectedItems] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpecLoading, setIsSpecLoading] = useState(false);
  const { lang } = useContext(Language);
  const navigate = useNavigate("");

  const toggleItemSelection = (index) => {
    const selectedItem = subcategories[index];
    if (selectedItems.includes(index)) {
      setSelectedItems(
        selectedItems.filter((itemIndex) => itemIndex !== index)
      );
      setSelectedSubcategories(
        selectedSubcategories.filter((item) => item.id !== selectedItem.id)
      );
    } else {
      setSelectedItems([...selectedItems, index]);
      setSelectedSubcategories([...selectedSubcategories, selectedItem]);
    }
  };

  const isAnyItemSelected = selectedItems.length > 0;

  useEffect(() => {
    setIsSpecLoading(true);
    axios
      .get(GetSubcategories)
      .then((res) => {
        const fetchedSubcategories = res.data.data;

        // Check if a subcategory from localData exists in the fetched data
        const matchingSubcategories = fetchedSubcategories.filter((item) =>
          Sub_Category.includes(item.name)
        );

        setSubcategories(fetchedSubcategories);
        setSelectedSubcategories(matchingSubcategories);
        setSelectedItems(
          matchingSubcategories.map((item) =>
            fetchedSubcategories.findIndex((sub) => sub.id === item.id)
          )
        );
        setIsSpecLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSpecLoading(false);
      });
  }, [Sub_Category]);

  // Add a function to check if an item is selected
  const isItemSelected = (index) => selectedItems.includes(index);

  const handleSave = () => {
    setIsLoading(true);

    const selectedSubcategoryNames = selectedSubcategories
      .map((item) => item.name)
      .join(",");

    axios
      .post(EditProfileInfoApi + login_id, {
        subcategory: selectedSubcategoryNames,
      })
      .then((response) => {
        console.log("update sub cate", response.data.status);
        setIsLoading(false); // Set loading to false when the request is completed
        if (response.data.status) {
          navigate("/profile/" + login_id);
        }
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        setIsLoading(false);
      });
  };

  return (
    <div className="rounded pxy-20 bg-white specialization-w box-shadow1 m-xl-0 m-lg-auto m-md-auto m-sm-auto m-auto">
      <h3 className="mt--12 fs-40 fs-md-15 fw-bold text-blue">
        {lang?.chose_your_specialization}
      </h3>
      <p className="fs-18 fs-md-14 text-dark-gray mt-3">
        {lang?.get_personalized_content_recomdation}
      </p>
      <div className="mt-4">
        {isSpecLoading ? (
          <div className="specloading">
            <SpecSpinner />
          </div>
        ) : (
          <ul className="list-inline d-flex flex-wrap gap-3 specialization">
            {subcategories.map((item, index) => (
              <li
                key={item.id}
                className={`${
                  isItemSelected(index) ? "bg-blue text-white" : ""
                }`}
                onClick={() => toggleItemSelection(index)}
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      <button
        disabled={isLoading}
        className={`bg-blue p-10 w-50 rounded text-white text-center border-0 mt-5 m-auto d-block ${
          isAnyItemSelected ? "" : "opacity-50"
        }`}
        onClick={handleSave}
      >
        {isLoading ? <Spinner /> : lang?.update}
      </button>
    </div>
  );
}

export default Specialization;
