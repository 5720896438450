import React from "react";
import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import ContestMain from "../../components/sponsor-post/ContestMain";

const Contest = () => {
  return (
    <>
      <Container fluid className="vh-100 overflowY-auto scroll-hide bg-grey">
        <Row>
          <NavBar />
        </Row>
        <Row className="justify-content-center space-top">
          <ContestMain />
        </Row>
      </Container>
    </>
  );
};

export default Contest;
