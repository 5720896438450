import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { BaseURL, EnrolEventApi, EventDetailApi } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EVENTS, PROFILE } from "../../constants/RoutesUrl";
import { BsCalendar3Week } from "react-icons/bs";
import { RiGlobalLine } from "react-icons/ri";
import { AiOutlineWarning } from "react-icons/ai";
import {
  MdOutlineAppRegistration,
  MdOutlineEventAvailable,
  MdOutlineLocationOn,
} from "react-icons/md";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";
import { toast } from "react-toastify";

const EventDetail = () => {
  const Navigate = useNavigate();
  // URL param
  const event = useParams();
  const event_id = event.id;

  // sessionStorage
  const user = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = user.user.id;

  // State
  const [data, setData] = useState({});
  const [spin, setSpin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [formattedPrice, setFormattedPrice] = useState(0);
  const [passModal, setPassModal] = useState(false);
  const [passInput, setPassInput] = useState("");
  const [questionModal, setQuestionModal] = useState(false);
  const [userAnswers, setUserAnswers] = useState({});
  const { lang } = useContext(Language);

  // Functions
  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleEnrolAfterQs = () => {
    const userAnswersArray = [];
    for (const question of data.questions) {
      const questionId = question.id;
      const userAnswer = userAnswers[questionId];
      if (userAnswer !== undefined && userAnswer !== "") {
        userAnswersArray.push({
          question_id: questionId,
          answer: userAnswer,
        });
      }
    }

    var questions = [];
    var answers = [];

    userAnswersArray.map((value) => {
      questions = [...questions, value.question_id];
      answers = [...answers, value.answer];
    });

    const QuestionsString = questions.map(String).join(",");
    const AnswersString = answers.map(String).join("@@@");

    setIsLoading(true);
    axios
      .post(EnrolEventApi, {
        event_id,
        user_id,
        questions: QuestionsString,
        answers: AnswersString,
      })
      .then((res) => {
        if (res.data.status) {
          setQuestionModal(false);
          setIsLoading(false);
          window.location.reload();
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEnrol = () => {
    // password is present -> password modal
    if (data.password !== "") {
      setPurchaseModal(false);
      setPassModal(true);
      // questions are present -> question modal
    } else if (data.questions && data.questions.length > 0) {
      setPurchaseModal(false);
      setQuestionModal(true);
    }

    // if no password & no questions -> Event join Api hitted
    if (data.password === "" && data.questions && data.questions.length === 0) {
      setIsLoading(true);
      axios
        .post(EnrolEventApi, {
          event_id,
          user_id,
          questions: "",
          answers: "",
        })
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
            setPurchaseModal(false);
            window.location.reload();
          } else {
            toast.error(res.data.action);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const handleEnrolAfterPass = () => {
    if (passInput !== data.password) {
      toast.error(lang?.incorect_password);
      return;
    }

    if (data.questions && data.questions.length > 0) {
      setPassModal(false);
      setQuestionModal(true);
      return;
    }

    if (data.questions && data.questions.length === 0) {
      setIsLoading(true);
      axios
        .post(EnrolEventApi, {
          event_id,
          user_id,
          questions: "",
          answers: "",
        })
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
            setPassModal(false);
            window.location.reload();
          } else {
            toast.error(res.data.action);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const handleDetails = () => {
    setSpin(true);
    axios
      .get(EventDetailApi + event_id + "/" + user_id)
      .then((res) => {
        const strNumber = res.data.data.price;
        const doubleNumber = parseFloat(strNumber);
        const hasDecimal = strNumber.includes(".");
        var formattedNumber = hasDecimal
          ? doubleNumber.toString()
          : doubleNumber.toFixed(2);
        setData(res.data.data);
        setFormattedPrice(formattedNumber);
        setTimeout(() => {
          setSpin(false);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        setSpin(false);
      });
  };
  useEffect(() => {
    if (event_id === "undefined") {
      toast.error(lang?.event_doesn_exist);
      Navigate(EVENTS);
      return;
    } else {
      handleDetails();
    }
  }, []);
  return (
    <>
      <div className="bg-offwhite">
        <Row>
          {spin ? (
            <div className="specloading">
              <Spinner />
            </div>
          ) : (
            <Col
              xl={7}
              lg={12}
              md={12}
              sm={12}
              className="bg-white rounded p-0 ms-xl-5 border border-1 border-inherit"
            >
              <div>
                <div>
                  <ImageComponent
                    src={BaseURL + data?.cover}
                    compClassName="rounded-top w-100"
                    loader_height="418px"
                    loader_width="100%"
                  />
                </div>
                <div className="px-4 pt-3">
                  <p
                    className="fs-14 roboto-medium mb-0"
                    style={{ color: "#2677E5" }}
                  >
                    {data?.category} - {data?.subcategory}
                  </p>
                  <p className="roboto-regular text-gray fs-12">
                    {lang?.organized_by} {data?.organizer}
                  </p>
                  <h3
                    className="fs-24 fs-md-16 roboto-bold"
                    style={{ color: "rgba(0, 0, 52, 0.7)" }}
                  >
                    {data?.title}
                  </h3>
                </div>
                <hr style={{ opacity: "15%" }} />

                <div className="px-4">
                  {/* Start_Date & End_date */}
                  <div className="d-flex gap-3 my-2">
                    <div className="px-1 rounded dropdown-avatar-shadow d-flex justify-content-center align-items-center">
                      <BsCalendar3Week />
                    </div>
                    <div className="align-self-center">
                      <p className="fs-14 mb-0 roboto-regular mt-1">
                        <ConvertedUnixTimestamp
                          timestamp={data?.start_timestamp}
                        />{" "}
                        -{" "}
                        <ConvertedUnixTimestamp
                          timestamp={data?.end_timestamp}
                        />
                      </p>
                    </div>
                  </div>
                  {/* Website */}
                  {data?.website && (
                    <div className="d-flex gap-3 my-2">
                      <div className="px-1 rounded dropdown-avatar-shadow d-flex justify-content-center align-items-center">
                        <RiGlobalLine />
                      </div>
                      <div className="align-self-center">
                        <p className="fs-14 mb-0 roboto-regular mt-1">
                          <a
                            href={
                              data?.website.includes("http") ||
                              data?.website.includes("https")
                                ? data?.website
                                : `https://${data?.website}`
                            }
                          >
                            {data?.website.includes("http") ||
                            data?.website.includes("https")
                              ? data?.website
                              : `https://${data?.website}`}
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                  {/* Registration Link */}
                  {data?.link && (
                    <div className="d-flex gap-3 my-2">
                      <div className="px-1 rounded dropdown-avatar-shadow d-flex justify-content-center align-items-center">
                        <MdOutlineAppRegistration />
                      </div>
                      <div className="align-self-center">
                        <p className="fs-14 mb-0 roboto-regular mt-1">
                          <a
                            href={
                              data?.link.includes("http") ||
                              data?.link.includes("https")
                                ? data?.link
                                : `https://${data?.link}`
                            }
                          >
                            {data?.link.includes("http") ||
                            data?.link.includes("https")
                              ? data?.link
                              : `https://${data?.link}`}
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                  {/* Availability */}
                  <div className="d-flex gap-3 my-2">
                    <div className="px-1 rounded dropdown-avatar-shadow d-flex justify-content-center align-items-center">
                      <MdOutlineEventAvailable />
                    </div>
                    <div className="align-self-center">
                      <p className="fs-14 mb-0 roboto-regular mt-1">
                        {data?.availability}
                      </p>
                    </div>
                  </div>
                  {/* Joined Member */}
                  {data?.limit !== 0 && (
                    <div className="d-flex gap-3 my-2">
                      <div className="px-1 rounded dropdown-avatar-shadow d-flex justify-content-center align-items-center">
                        <AiOutlineWarning />
                      </div>
                      <div className="align-self-center">
                        <p className="fs-14 mb-0 roboto-regular mt-1">
                          {data?.joined} of {data?.limit}{" "}
                          {lang?.member_have_joined}
                        </p>
                      </div>
                    </div>
                  )}
                  {/* Location */}
                  {data.short_location || data.lat_lng ? (
                    <div className="d-flex gap-3 my-2">
                      <div className="px-1 rounded dropdown-avatar-shadow d-flex justify-content-center align-items-center">
                        <MdOutlineLocationOn />
                      </div>
                      <div className="align-self-center">
                        <p className="fs-14 mb-0 roboto-regular mt-1">
                          {data.short_location && (
                            <>{data?.short_location} - </>
                          )}
                          {data?.lat_lng && (
                            <a
                              target="_blank"
                              href={`https://www.google.com/maps?q=${data?.lat_lng}`}
                            >
                              {lang?.get_direction}
                            </a>
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <hr style={{ opacity: "15%" }} />
                <div className="px-4">
                  <div>
                    <h5>{lang?.details}</h5>
                  </div>

                  <p
                    className="fs-16 roboto-regular lh-20 m-0"
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  >
                    {data?.description &&
                    !expanded &&
                    data.description.length > 364
                      ? data.description.slice(0, 364)
                      : data.description}
                  </p>
                  {data?.description && data?.description.length > 364 && (
                    <div className="pb-3">
                      <span
                        className="fs-14 roboto-regular cursor"
                        style={{ color: "#5BA4FC" }}
                        onClick={() => {
                          setExpanded(!expanded);
                        }}
                      >
                        {expanded ? lang?.show_less : lang?.show_more}
                      </span>
                    </div>
                  )}

                  {user_id !== data?.user?.id && (
                    <h3
                      className="fs-28 fs-md-16 roboto-bold py-3"
                      style={{ color: "rgba(0, 0, 52, 0.7)" }}
                    >
                      {data?.price === ""
                        ? `€0.0 - ${lang?.free}`
                        : "€" + formattedPrice}
                    </h3>
                  )}
                </div>
                {!data?.is_joined && user_id !== data?.user?.id && (
                  <div className="px-4">
                    <button
                      onClick={() => setPurchaseModal(true)}
                      className="bg-blue fs-20 fs-md-16 roboto-bold btn btn-block br-4 text-white w-100 py-3"
                    >
                      <strong>
                        {lang?.join_now} -
                        {data?.price === ""
                          ? lang?.free
                          : " $" + formattedPrice}
                      </strong>
                    </button>
                  </div>
                )}
                <hr style={{ opacity: "15%" }} />
                <div className="px-4 py-4">
                  <h3
                    className="fs-24 fs-md-16 roboto-bold"
                    style={{ color: "rgba(0, 0, 52, 0.7)" }}
                  >
                    {lang?.published_by}
                  </h3>
                  <div className="d-flex pt-2 gap-3 align-items-start">
                    <Link
                      to={PROFILE + "/" + data?.user?.id}
                      className="rounded bg-white dropdown-avatar-shadow"
                      style={{
                        padding: "2px",
                        width: "80px",
                      }}
                    >
                      <ImageComponent
                        src={
                          data?.user?.image
                            ? BaseURL + data?.user?.image
                            : Avatar
                        }
                        loader_height="100%"
                        loader_width="100%"
                        compClassName="rounded img-fluid"
                      />
                    </Link>
                    <div className="align-self-center">
                      <Link to={PROFILE + "/" + data?.user?.id}>
                        <h4
                          className="fs-16 roboto-bold mb-0"
                          style={{ color: "rgba(0, 0, 52, 0.7)" }}
                        >
                          {data?.user?.name}
                        </h4>
                      </Link>
                      <span
                        className="fs-12 roboto-regular"
                        style={{ color: "#555555", wordBreak: "break-all" }}
                      >
                        {data?.user?.category} {`(${data?.user?.subcategory})`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
      {/* Modal */}
      <Modal
        isOpen={purchaseModal}
        toggle={() => setPurchaseModal(!purchaseModal)}
        zIndex={9}
        centered
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_enrol_event}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.do_want_enrol_event_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setPurchaseModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              disabled={isLoading}
              onClick={handleEnrol}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.enrol_now}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Modal Ended */}

      <Modal
        isOpen={passModal}
        toggle={() => setPassModal(!passModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="p-2">
            <form>
              <div>
                <label className="mb-1">{lang?.password}</label>
                <input
                  type="password"
                  className="form-control"
                  autoComplete="off"
                  placeholder="******"
                  onChange={(e) => setPassInput(e.target.value)}
                  required
                />
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary rounded"
            onClick={() => setPassModal(false)}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 rounded px-4 py-2"
            disabled={passInput === ""}
            onClick={() => {
              handleEnrolAfterPass();
            }}
          >
            {isLoading ? <Spinner size="sm" /> : lang?.save}
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={questionModal}
        toggle={() => setQuestionModal(!questionModal)}
        zIndex={9}
        centered
      >
        <ModalHeader toggle={() => setQuestionModal(false)}></ModalHeader>
        <ModalBody>
          {data.questions &&
            data.questions.map((value, index) => {
              return (
                <div className="my-3" key={index}>
                  <label className="mb-1">{value.question}</label>
                  {value.answers && value.answers.length > 1 ? (
                    <select
                      className="form-select"
                      disabled={isLoading}
                      onChange={(e) =>
                        handleAnswerChange(value.id, e.target.value)
                      }
                      value={userAnswers[value.id] || ""}
                    >
                      <option value="" selected hidden disabled>
                        {lang?.select_answer}
                      </option>
                      {value.answers.map((value, keyIndex) => (
                        <option value={value} key={keyIndex}>
                          {value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className="form-control"
                      value={userAnswers[value.id] || ""}
                      disabled={isLoading}
                      onChange={(e) =>
                        handleAnswerChange(value.id, e.target.value)
                      }
                    />
                  )}
                </div>
              );
            })}
        </ModalBody>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary rounded"
            onClick={() => setQuestionModal(false)}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 roudned px-4 py-2"
            onClick={handleEnrolAfterQs}
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="sm" /> : lang?.save}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EventDetail;

const ConvertedUnixTimestamp = ({ timestamp }) => {
  const date = new Date(timestamp * 1000);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
};
