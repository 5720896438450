import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/ChatMod.scss";
import "./assets/css/upload.css";
import "./assets/css/Profile.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
