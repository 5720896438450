import React, { useContext } from "react";
import ImageComponent from "../common/ImageComponent.jsx";
import { PROFILE } from "../../constants/RoutesUrl.js";
import { BaseURL } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Dots from "../../assets/images/icon/post-dropdown-dots.png";
import { Link } from "react-router-dom";
import { UnixToRelative } from "../notification/Notification.jsx";
import { Collapse } from "reactstrap";
import { Language } from "../../App.js";

const PostHeader = ({
  Id,
  img,
  name,
  time,
  postDropDown,
  setPostDropDown,
  setReportModal,
  setDropDownId,
  original_id,
  dropDownId,
  setEditPostInput,
  caption,
  setEditPostModal,
  setPostDeleteModal,
  profile_current_url,
}) => {
  const profile_save_url = sessionStorage.getItem("profile_url");
  const { lang } = useContext(Language);

  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative px-3">
        <div className="d-flex gap-3">
          <div
            className="bg-white dropdown-avatar-shadow rounded-3 align-self-center"
            style={{ padding: "2px", width: "50px", height: "50px" }}
          >
            <Link to={PROFILE + "/" + Id}>
              <ImageComponent
                src={img ? BaseURL + img : Avatar}
                img_height="100%"
                img_width="100%"
                loader_height="100%"
                loader_width="100%"
                compClassName="rounded-3"
              />
            </Link>
          </div>
          <div className="align-self-center">
            <div>
              <Link to={PROFILE + "/" + Id}>
                <h1 className="fs-16 roboto-bold hover-blue mb-0 text-ellips-162px">
                  {name}
                </h1>
              </Link>
              <p
                className="mb-0 fs-12 roboto-regular"
                style={{
                  color: "#434343",
                  opacity: "80%",
                }}
              >
                <UnixToRelative unixTimestamp={time} />
              </p>
            </div>
          </div>
        </div>
        <div
          className="manue-hover cursor d-flex justify-content-center align-items-center p-2 rounded"
          onClick={() => {
            setPostDropDown(!postDropDown);
            setDropDownId(original_id);
          }}
        >
          <img width={20} src={Dots} alt="..." />
        </div>
        {/* Post DropDown */}
        <div
          className="position-absolute w-25 end-0"
          style={{ zIndex: "9", marginTop: "5rem" }}
        >
          <Collapse
            isOpen={postDropDown && original_id === dropDownId ? true : false}
            className="bg-white rounded p-1 text-center"
            style={{
              boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
            }}
          >
            {Id !== user_id && (
              <h6
                className="mb-0 text-red p-2 rounded cursor fs-md-12 manue-hover"
                onClick={() => {
                  setReportModal(true);
                  setPostDropDown(false);
                }}
              >
                {lang?.report}
              </h6>
            )}
            {Id === user_id && (
              <>
                <h6
                  onClick={() => {
                    setPostDeleteModal(true);
                    setPostDropDown(false);
                  }}
                  className="mb-0 text-red p-2 rounded cursor fs-md-12 manue-hover"
                >
                  {lang?.delete}
                </h6>
                {profile_current_url !== profile_save_url && (
                  <h6
                    className="mb-0 text-red p-2 rounded cursor fs-md-12 manue-hover mt-1"
                    onClick={() => {
                      setEditPostInput(caption ? caption : "");
                      setEditPostModal(true);
                    }}
                  >
                    {lang?.edit}
                  </h6>
                )}
              </>
            )}
          </Collapse>
        </div>
        {/* End Post DropDown */}
      </div>
    </>
  );
};

export default PostHeader;
