import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar from "../components/NavBar";
import { UnixToRelative } from "../components/notification/Notification";
import {
  AddCommentApi,
  AddCommentRepliesApi,
  BaseURL,
  BlockUnblockUserApi,
  DeletePostApi,
  FollowApi,
  Followersapidata,
  Followingapidata,
  GetAllMessages,
  GetCommentsApi,
  GetRepliesApi,
  LikeApi,
  ProfileApi,
  ReportJobApi,
  RepostApi,
  ReserveApi,
  SaveApi,
  Sendindiviualchats,
} from "../constants/API";
import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Row,
  Spinner,
  Collapse,
  Col,
  Container,
} from "reactstrap";
import axios from "axios";
import world from "../assets/images/Website.png";
import calender from "../assets/images/Events.png";
import verified from "../assets/images/profile_verified.png";
import location from "../assets/images/location.png";
import { FaArrowLeft } from "react-icons/fa";
import covermr from "../assets/images/coverMR.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import chat from "../assets/images/chatbubble.png";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import {
  COURSE_DETAILS,
  FEED,
  PROFILE,
  SETTINGMODULE,
} from "../constants/RoutesUrl";
import InfiniteScroll from "react-infinite-scroller";
import SpinnerGif from "../assets/images/Spinner.gif";
import SpecSpinner from "../components/manage_account/changeInterest/SpecSpinner";
import { BsSendFill } from "react-icons/bs";
import { HiDotsVertical } from "react-icons/hi";
import EventPreview from "../components/events/EventPreview";
import ImageWithAspectRatio from "../components/common/ImagewithAspectRatio.tsx";
import VideoWithAspectRatio from "../components/common/VideowithAspectRatio.tsx";
import { Carousel } from "react-responsive-carousel";
import Dots from "../assets/images/icon/post-dropdown-dots.png";
import Liked from "../assets/images/icon/liked.png";
import Like from "../assets/images/icon/like.png";
import Comment from "../assets/images/icon/comment.png";
import ReshareActive from "../assets/images/icon/repost-active.png";
import Reshare from "../assets/images/icon/reshare.png";
import Saved from "../assets/images/icon/saved.png";
import Save from "../assets/images/icon/save.png";
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
  IoMdVideocam,
} from "react-icons/io";
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import ImageComponent from "../components/common/ImageComponent.jsx";
import { Language } from "../App.js";
import PostHeader from "../components/feeds/PostHeader.jsx";
import PostFooter from "../components/feeds/PostFooter.jsx";

const Profile = () => {
  sessionStorage.setItem("profile_url", window.location.href);
  const { lang } = useContext(Language);
  const btns = [
    { id: 1, type: "post", name: lang?.posts },
    { id: 2, type: "webinar", name: lang?.webinars },
    {
      id: 3,
      type: "clinical",
      name: lang?.clinical_studies,
    },
    { id: 6, type: "poll", name: lang?.poll },
    { id: 4, type: "course", name: lang?.courses },
    { id: 5, type: "event", name: lang?.events },
  ];

  const param = useParams();
  const user_id = param.id;
  const Navigate = useNavigate();

  const [postDeleteModal, setPostDeleteModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportInput, setReportInput] = useState("");
  const [postDropDown, setPostDropDown] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const id = parseInt(searchParams.get("id"), 10);
  const type = searchParams.get("type");

  const [activeBtn, setActiveBtn] = useState(id ? id : 1);
  const [notificationsType, setNotificationsType] = useState(
    type ? type : "post"
  );

  const [postsData, setPostsData] = useState([]);
  const [postInfo, setPostInfo] = useState({});
  const [spin, setSpin] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [postIndex, setPostIndex] = useState(null);
  const [commentsModal, setCommentsModal] = useState(false);
  const [postId, setPostId] = useState("");

  const [load, setLoad] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingchat, setLoadingChat] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");
  const [profileInfo, setProfileInfo] = useState({});
  const [page, setPage] = useState(0);
  const [pagefollowing, setPagefollowing] = useState(1);
  const [pagefollowers, setPagefollowers] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingfollowing, setIsLoadingfollowing] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingModalOpen, setFollowingModalOpen] = useState(false);
  const [followersModalOpen, setFollowersModalOpen] = useState(false);
  const [Following, setFollowing] = useState({});
  const [followingAcc, setFollowingAcc] = useState([]);
  const [followersAcc, setFollowersAcc] = useState([]);
  const [Followers, setFollowers] = useState([]);
  const [hasMoreFollowing, setHasMoreFollowing] = useState(true);
  const [hasMoreFollowers, setHasMoreFollowers] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [isLoadingchat, setIsLoadingChat] = useState(false);
  const [ticketChat, setTicketChat] = useState([]);
  const lastMessageRef = useRef(null);
  const [offcanvasIsOpen, setOffcanvasIsOpen] = useState(false);

  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isLoadingblock, setIsLoadingBlock] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isreportLoading, setIsReportLoading] = useState(false);
  const [reportData, setReportData] = useState("");
  const [isReportButtonDisabled, setIsReportButtonDisabled] = useState(true);

  const [postComments, setPostComments] = useState({});
  const [isReply, setIsReply] = useState(false);
  const [replyUser, setReplyUser] = useState({});
  const [commentId, setCommentId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentReplies, setCommentReplies] = useState([]);
  const [replyDropdown, setReplyDropdown] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedPoll, setSelectedPoll] = useState(0);

  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;

  const handleDeletePost = () => {
    setIsLoading(true);
    axios
      .get(DeletePostApi + `${dropDownId}/delete`)
      .then((res) => {
        if (res.data.status) {
          toast.success(lang?.post_deleted);
          setIsLoading(false);
          setPostDeleteModal(false);
          window.location.reload();
        } else {
          toast.error(res.data.action);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handlePostReport = () => {
    setIsLoading(true);
    const reportData = {
      report_id: dropDownId,
      user_id,
      report: reportInput,
      type: "post",
    };
    axios
      .post(ReportJobApi, reportData)
      .then((res) => {
        if (res.data.status) {
          toast.success(lang?.reported_successfully);
          setIsLoading(false);
          setReportModal(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handlePollAnswer = (post_id, answer) => {
    axios
      .post(BaseURL + "api/poll/answer/submit", {
        post_id,
        user_id,
        answer: answer + 1,
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleReserveWebinar = (post_id) => {
    const updatedData = postsData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isReserve: !value.isReserve };
      }
      return value;
    });

    setPostsData(updatedData);

    axios
      .get(ReserveApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddComment = () => {
    const data = {
      comment,
    };
    axios
      .post(AddCommentApi + postId + "/" + user_id, data)
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddReplies = (comment_id) => {
    axios
      .post(AddCommentRepliesApi + postId + "/" + user_id, {
        parent: comment_id,
        comment,
      })
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments(postId);
          handleGetReplies(commentId);
          setIsReply(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGetReplies = (comment_id) => {
    axios
      .get(GetRepliesApi + comment_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setCommentReplies(res.data.data);
          setReplyDropdown(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGetComments = (post_id) => {
    setIsLoading(true);
    const id = post_id ? post_id : postId;
    axios
      .get(GetCommentsApi + id + "/" + user_id + "?page=1")
      .then((res) => {
        if (res.data.status) {
          setPostComments(res.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLike = (post_id) => {
    const updatedData = postsData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isLike: !value.isLike };
      }
      return value;
    });

    setPostsData(updatedData);

    axios
      .get(LikeApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };
  const handleSave = (post_id) => {
    const updatedData = postsData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isSave: !value.isSave };
      }
      return value;
    });

    setPostsData(updatedData);
    axios
      .get(SaveApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleRepost = (post_id) => {
    const updatedData = postsData.map((value) => {
      if (value.id === post_id) {
        return { ...value, isShare: !value.isShare };
      }
      return value;
    });

    setPostsData(updatedData);
    axios
      .get(RepostApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
        } else {
          toast.error(res.data.actionI);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    if (offcanvasIsOpen && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [offcanvasIsOpen, ticketChat]);

  const toggleChatOffcanvas = () => {
    setLoadingChat(true);
    setOffcanvasIsOpen(true);
    setIsChatOpen(!isChatOpen);
    axios
      .post(GetAllMessages, {
        from: login_id,
        to: user_id,
      })
      .then((res) => {
        setTicketChat(res.data.data.data);
        setLoadingChat(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingChat(false);
      });
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      // Prevent the default behavior of the Enter key (e.g., form submission)
      event.preventDefault();

      // Call the function to send the message
      HandlemessageSend();
    }
  }

  const handleFollow = (id) => {
    setIsLoading(true);
    axios
      .get(FollowApi + user_id + "/" + login_id)
      .then((res) => {
        setIsFollowing((prevState) => !prevState);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          Followingapidata + user_id + "/" + login_id
        );
        setFollowing(response.data.data);
        setFollowingAcc(response.data.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          Followersapidata + user_id + "/" + login_id
        );
        setFollowers(response.data.data);
        setFollowersAcc(response.data.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleSharedData = (type, pageIndex) => {
    setLoad(true);
    const page_no = pageIndex + 1;
    axios
      .post(ProfileApi + JSON.stringify(page_no), {
        user_id,
        login_id,
        type,
      })
      .then((res) => {
        setPostInfo(res.data.data);
        const fetcheddata = res.data.data.data;
        if (fetcheddata && fetcheddata.length > 0) {
          const concatData = postsData.concat(fetcheddata);
          setPostsData(concatData);
        } else {
          setPostsData([]);
        }
        setPage(page_no);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleChangeTab = (type) => {
    setSpin(true);
    axios
      .post(ProfileApi + JSON.stringify(1), {
        user_id,
        login_id,
        type,
      })
      .then((res) => {
        setPostInfo(res.data.data);
        setPostsData(res.data.data.data);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setSpin(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post(ProfileApi + "1", {
        user_id,
        login_id,
        type: notificationsType,
      })
      .then((res) => {
        setPostInfo(res.data.data);
        setPostsData(res.data.data.data);
        setProfileInfo(res.data.user);
        sessionStorage.setItem("profileData", JSON.stringify(res.data));
        setIsFollowing(res.data.user.follow);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleFollowingList = () => {
    setIsLoadingfollowing(true);
    axios
      .get(
        Followingapidata +
          user_id +
          "/" +
          login_id +
          "?page=" +
          JSON.stringify(pagefollowing + 1)
      )
      .then((res) => {
        const updatedValue = res.data.data.data;
        setFollowingAcc(followingAcc.concat(updatedValue));
        setPagefollowing(pagefollowing + 1);
        setIsLoadingfollowing(false);

        if (res.data.data.current_page >= res.data.data.last_page) {
          setHasMoreFollowing(false); // No more pages to load
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleFollowersList = () => {
    setIsLoadingfollowing(true);
    axios
      .get(
        Followersapidata +
          user_id +
          "/" +
          login_id +
          "?page=" +
          JSON.stringify(pagefollowers + 1)
      )
      .then((res) => {
        const updatedValue = res.data.data.data;
        setFollowersAcc(followersAcc.concat(updatedValue));
        setPagefollowers(pagefollowers + 1);
        setIsLoadingfollowing(false);

        if (res.data.data.current_page >= res.data.data.last_page) {
          setHasMoreFollowers(false); // No more pages to load
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // send message
  const HandlemessageSend = () => {
    setIsLoadingChat(true);

    if (messageInput.trim() !== "") {
      axios
        .post(Sendindiviualchats, {
          from: login_id,
          to: user_id,
          message: messageInput,
        })
        .then((res) => {
          // Create a new message object
          const newMessage = {
            from: login_id,
            to: user_id,
            message: messageInput,
          };

          // Append the new message to the existing ticketChat array
          setTicketChat((prevChat) => [newMessage, ...prevChat]);
          setMessageInput("");
        })
        .catch((err) => {
          console.log("error sending message", err);
        })
        .finally(() => {
          setIsLoadingChat(false); // Set isLoadingChat to false whether the message is sent or there's an error
        });
    } else {
      setIsLoadingChat(false); // Set isLoadingChat to false when the message is empty
    }
  };

  // block acc
  const handleBlock = () => {
    setIsLoadingBlock(true);
    axios
      .get(BlockUnblockUserApi + user_id + "/" + login_id)
      .then((res) => {
        if (res.data.status) {
          setIsLoadingBlock(false);
          toast.success(res.data.action);
          setIsBlockModalOpen(false);
          Navigate(FEED);
        }
      })
      .catch((err) => {
        toast.error(lang?.error_blocking_account, err);
        setIsLoadingBlock(false);
      });
  };

  // report
  const handleReport = () => {
    setIsReportLoading(true);
    axios
      .post(ReportJobApi, {
        report_id: user_id,
        user_id: login_id,
        report: reportData,
        type: "user",
      })
      .then((res) => {
        if (res.data.status) {
          setIsReportLoading(false);
          toast.success(res.data.action);
          setIsReportModalOpen(!isReportModalOpen);
        } else {
          toast.error(lang?.report_action_failed);
          setIsReportLoading(false);
        }
      })
      .catch((err) => {
        toast.error(lang?.error_making_report + err);
        setIsReportLoading(false);
      });
  };

  // collapse auto of
  const [typeDropdown, setTypeDropdown] = useState(false);
  const [closing, setClosing] = useState(false);

  const collapseTypeDropdown = useRef(null);

  const handelTypeDropdown = (e) => {
    if (
      collapseTypeDropdown.current &&
      !collapseTypeDropdown.current.contains(e.target)
    ) {
      setClosing(true);
      setTimeout(() => {
        setTypeDropdown(false);
        setClosing(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (typeDropdown)
      document.addEventListener("mousedown", handelTypeDropdown);
    return () => document.removeEventListener("mousedown", handelTypeDropdown);
  }, [typeDropdown]);

  return (
    <>
      <div className="Profile">
        <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
          <Row>
            <NavBar />
          </Row>
          <Row className="justify-content-center overflow-y-scroll pb-5 h-100">
            <div style={{ width: "935px" }}>
              {loading ? (
                <div className="spinner">
                  <SpecSpinner />
                </div>
              ) : (
                <div className="card my-3">
                  <div className="bluerimg">
                    <div className="cover-photo image-texts position-relative">
                      <div className="m-auto position-absolute start-0 end-0 bottom-0 top-0 blurcoverimage"></div>
                      <img
                        className="card-img-top"
                        src={
                          profileInfo?.image !== ""
                            ? BaseURL + profileInfo?.image
                            : covermr
                        }
                        alt="Card image cap"
                      />
                      <div
                        onClick={() => window.history.back()}
                        className="image-text roboto-medium cursor"
                        style={{ borderRadius: "0 0 10px 0" }}
                      >
                        <FaArrowLeft />
                      </div>
                      {login_id == user_id ? (
                        ""
                      ) : (
                        <div
                          className="image-text-block roboto-medium cursor"
                          onClick={() => setTypeDropdown(!typeDropdown)}
                          style={{ borderRadius: "0 0 0 10px" }}
                        >
                          <HiDotsVertical />
                        </div>
                      )}

                      {/* Dropdown */}
                      <Collapse
                        isOpen={typeDropdown && !closing}
                        innerRef={collapseTypeDropdown}
                        className="position-absolute image-text-block-dropdown"
                        style={{ zIndex: "99999" }}
                      >
                        <div className="bg-white p-1 rounded dropdown-avatar-shadow d-flex flex-column">
                          <label
                            className="mb-0 text-red px-5 py-2 rounded cursor fs-md-12 roboto-bold manue-hover"
                            onClick={() => {
                              setIsReportModalOpen(!isReportModalOpen);
                              setTypeDropdown(false); // Close the dropdown
                            }}
                          >
                            {lang?.report}
                          </label>
                          <label
                            className="mb-0 text-red px-5 py-2 rounded cursor fs-md-12 roboto-bold manue-hover"
                            onClick={() => {
                              setIsBlockModalOpen(!isBlockModalOpen);
                              setTypeDropdown(false); // Close the dropdown
                            }}
                          >
                            {lang?.block}
                          </label>
                        </div>
                      </Collapse>
                      {/* end dropdown */}
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="editprofile position-absolute  d-flex justify-content-between">
                      <div className="p-1 profilepicborder  dropdown-avatar-shadow ">
                        <img
                          className={`profilepic cursor img-fluid ${
                            imageLoaded ? "loaded" : "hidden"
                          }`}
                          src={
                            profileInfo?.image
                              ? BaseURL + profileInfo.image
                              : Avatar
                          }
                          alt="picture"
                          onLoad={() => setImageLoaded(true)}
                          onClick={() => {
                            setClickedImageUrl(
                              profileInfo?.image !== ""
                                ? BaseURL + profileInfo?.image
                                : Avatar
                            );
                            setIsModalOpen(!isModalOpen);
                          }}
                        />
                        {/* Image Modal */}
                        <Modal
                          isOpen={isModalOpen}
                          toggle={() => setIsModalOpen(!isModalOpen)}
                          contentLabel="Image Modal"
                          centered
                          zIndex={9}
                        >
                          <img
                            src={clickedImageUrl}
                            alt="..."
                            className="img-fluid rounded"
                          />
                        </Modal>
                        {/* end image modal */}
                      </div>
                      <div>
                        {login_id == user_id ? (
                          <Link to={SETTINGMODULE + "/1"}>
                            <button className="editprofilebtn edit transition fw-bold border-4">
                              {lang?.edit_profile}
                            </button>
                          </Link>
                        ) : (
                          <div className="d-flex align-items-center gap-2">
                            <div
                              className="chat mt-1 cursor"
                              onClick={toggleChatOffcanvas}
                            >
                              {/* <img
                                src={chat}
                                alt="..."
                                width={25}
                                className="img-fluid mb-1"
                              /> */}
                              <IoChatbubbleEllipsesSharp size={25} />
                            </div>
                            <div>
                              <button
                                style={{ marginRight: "10px" }}
                                className={`editprofilebtn transition mt-1 ${
                                  isFollowing
                                    ? "following-button"
                                    : "follow-button"
                                }`}
                                onClick={handleFollow}
                                disabled={isLoading}
                              >
                                {isFollowing ? lang?.following : lang?.follow}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <h5 className="roboto-bold  mb-1 name">
                      {profileInfo?.name}{" "}
                      {user_id == 1 ? (
                        <img
                          src={verified}
                          className="img-fluid"
                          width={17}
                          alt="picture"
                        />
                      ) : (
                        ""
                      )}
                    </h5>
                    <p className=" roboto-medium username">
                      @{profileInfo?.user_name}
                    </p>

                    <p className="roboto-medium biotext mb-2 lh-20">
                      {profileInfo?.bio}
                    </p>
                    <p className="roboto-medium category">
                      {profileInfo?.category && profileInfo?.subcategory
                        ? `${profileInfo.category} - ${profileInfo.subcategory}`
                        : ""}
                    </p>

                    {profileInfo.link ? (
                      <div className="d-flex">
                        <div className="image-div rounded">
                          <img
                            className="mb-1"
                            width={30}
                            src={world}
                            alt="..."
                          />
                        </div>
                        &nbsp; &nbsp;
                        <div className="link-div mt-1">
                          <a
                            className="roboto-regular linktext link-profile"
                            target="_blank"
                            href={`https://${profileInfo?.link}`}
                          >
                            {profileInfo?.link}
                          </a>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {profileInfo.location ? (
                      <div className="d-flex mt-3">
                        <div className="image-div-loc rounded">
                          <img
                            className="mt-1  img-fluid "
                            width={30}
                            src={location}
                            alt=".."
                          />
                        </div>
                        &nbsp; &nbsp;
                        <div className="link-div mt-1 ">
                          <p className="roboto-regular linktext " href="">
                            {profileInfo?.location}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {profileInfo.created_at ? (
                      <div className="d-flex mt-2">
                        <div className="image-div-cal rounded">
                          <img
                            className="mt-1 img-fluid "
                            width={30}
                            src={calender}
                            alt="picture"
                          />
                        </div>
                        &nbsp; &nbsp;
                        <div className="link-div mt-2 ">
                          <p className="roboto-regular linktext" href="">
                            {lang?.joined} {profileInfo?.created_at}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="d-flex gap-3">
                      <p className="roboto-bold mt-3 hover-blue transition">
                        {profileInfo?.following_count}
                        <span
                          className="roboto-regular fs-18 ms-2 cursor"
                          onClick={() => {
                            setFollowingModalOpen(!followingModalOpen);
                          }}
                        >
                          {lang?.following}
                        </span>
                      </p>
                      <p className="roboto-bold mt-3 hover-blue transition">
                        {profileInfo?.followers_count}
                        <span
                          className="roboto-regular fs-18 ms-2 cursor"
                          onClick={() => {
                            setFollowersModalOpen(!followersModalOpen);
                          }}
                        >
                          {lang?.followers}
                        </span>
                      </p>
                    </div>
                    {/* Followers Modal */}
                    <Modal
                      isOpen={followersModalOpen}
                      toggle={() => {
                        setFollowersModalOpen(!followersModalOpen);
                      }}
                      centered
                      zIndex={9}
                      scrollable
                    >
                      <ModalHeader
                        toggle={() => {
                          setFollowersModalOpen(!followersModalOpen);
                        }}
                      >
                        {lang?.followers}
                      </ModalHeader>
                      <ModalBody className="scroll-hide">
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={handleFollowersList}
                          hasMore={hasMoreFollowers}
                          loader={
                            <div className="w-100 text-center" key={0}>
                              <img src={SpinnerGif} alt="picture" width={70} />
                            </div>
                          }
                          useWindow={false}
                        >
                          {followersAcc &&
                            followersAcc.map((value, index) => (
                              <>
                                <Link
                                  onClick={() => {
                                    Navigate(PROFILE + "/" + value.id);
                                    window.location.reload();
                                  }}
                                  className="d-flex p-2 justify-content-between manue-hover rounded text-dark"
                                  key={index}
                                >
                                  <div className="d-flex gap-2">
                                    <div
                                      className="bg-white dropdown-avatar-shadow align-self-center rounded d-flex justify-content-center align-items-center"
                                      style={{
                                        maxWidth: "4rem",
                                        maxHeight: "4rem",
                                        padding: "1.8px",
                                      }}
                                    >
                                      <ImageComponent
                                        src={
                                          value?.image
                                            ? BaseURL + value.image
                                            : Avatar
                                        }
                                        compClassName="img-fluid rounded"
                                        loader_height="4rem"
                                        loader_width="4rem"
                                      />
                                    </div>

                                    <div className="align-self-center">
                                      <div className="fs-17 fs-md-16 roboto-bold text-dark search-post-name">
                                        {value.name}
                                      </div>
                                      <p className="mb-0 fs-12 roboto-regular search-post-name">
                                        {value.user_name}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                                {index !== 3 && (
                                  <hr
                                    className="m-0"
                                    style={{ opacity: "6%" }}
                                  />
                                )}
                              </>
                            ))}
                        </InfiniteScroll>
                      </ModalBody>
                    </Modal>
                    {/* End Followers Modal */}

                    {/* Tabs menu */}
                    <div>
                      <div className="scroll-hide d-flex gap-2 mb-3 overflowX-auto">
                        {btns &&
                          btns.map((value) => (
                            <div key={value.id}>
                              <button
                                type="button"
                                style={{ whiteSpace: "nowrap" }}
                                onClick={() => {
                                  setActiveBtn(value.id);
                                  setNotificationsType(value.type);
                                  handleChangeTab(value.type);
                                }}
                                className={`rounded px-5 hover-blue py-2 fw-bold manue-hover ${
                                  activeBtn === value.id
                                    ? "bg-dark text-white border-0"
                                    : "border border-1 border-inherit"
                                }`}
                              >
                                {value.name} {value.count !== 0 && value.count}
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                    {/* Tabs menu ended */}

                    {spin ? (
                      <Row>
                        <Col className="text-center">
                          <Spinner />
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <div>
                          <>
                            <div>
                              {notificationsType === "course" ? (
                                <Row className="justify-content-between">
                                  {postsData && postsData.length > 0 ? (
                                    postsData.map((course) => (
                                      <>
                                        <Col md={6}>
                                          <CourseCard
                                            image={course.cover}
                                            price={course.price}
                                            name={course.title}
                                            course_id={course.id}
                                          />
                                        </Col>
                                      </>
                                    ))
                                  ) : (
                                    <div className="w-100 text-center">
                                      <h6 className="text-secondary">
                                        {lang?.no_course_posted_yet}
                                      </h6>
                                    </div>
                                  )}
                                </Row>
                              ) : notificationsType === "event" ? (
                                <>
                                  {postsData && postsData.length > 0 ? (
                                    postsData.map((value) => (
                                      <EventPreview
                                        id={value.id}
                                        image={value.cover}
                                        title={value.title}
                                        location={value.short_location}
                                        time={value.start_timestamp}
                                        available={value.availability}
                                      />
                                    ))
                                  ) : (
                                    <div className="w-100 text-center">
                                      <h6 className="text-secondary">
                                        {lang?.no_event_posted_yet}
                                      </h6>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div
                                  className="d-flex flex-wrap justify-content-center align-items-center"
                                  style={{ gap: "10px" }}
                                >
                                  {postsData && postsData.length > 0 ? (
                                    postsData.map((value, index) => (
                                      <>
                                        <div
                                          className="cursor position-relative"
                                          style={{
                                            backgroundColor: "#f5f5f5",
                                            width: "285px",
                                            height: "285px",
                                          }}
                                          onClick={() => {
                                            setPostIndex(index);
                                            setPostModal(true);
                                          }}
                                          key={index}
                                        >
                                          {value.type === "video" ? (
                                            <>
                                              <IoMdVideocam
                                                className="position-absolute top-0 end-0 mt-2 me-2 z-1"
                                                color="white"
                                                size={30}
                                              />
                                              <ImageComponent
                                                src={
                                                  BaseURL + value?.thumbnail[0]
                                                }
                                                loader_height="285px"
                                                loader_width="285px"
                                                compClassName="w-100 h-100 object-cover"
                                              />
                                            </>
                                          ) : (
                                            <ImageComponent
                                              src={BaseURL + value?.media[0]}
                                              loader_height="285px"
                                              loader_width="285px"
                                              compClassName="w-100 h-100 object-cover"
                                            />
                                          )}
                                        </div>
                                      </>
                                    ))
                                  ) : (
                                    <div className="w-100 text-center">
                                      <h6 className="text-secondary">
                                        {lang?.no_content_posted_yet}
                                      </h6>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            {postInfo?.current_page !== postInfo?.last_page ? (
                              <Col className="text-center mt-3">
                                <button
                                  className="btn-blue border-0 px-5 py-2 "
                                  onClick={() => {
                                    handleSharedData(notificationsType, page);
                                  }}
                                >
                                  {load ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    lang?.load_more
                                  )}
                                </button>
                              </Col>
                            ) : (
                              <></>
                            )}
                          </>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Row>
        </Container>
      </div>

      {/* Post Detail Modal */}
      <Modal
        isOpen={postModal}
        zIndex={9}
        style={{ maxWidth: "650px" }}
        contentClassName="bg-transparent border-0"
        toggle={() => {
          setPostModal(false);
        }}
      >
        <Row className="position-relative justify-content-center">
          <Col
            md={1}
            className="position-absolute start-0"
            style={{ marginTop: "20rem" }}
          >
            {postIndex && postIndex > 0 ? (
              <IoIosArrowDropleftCircle
                size={30}
                color="white"
                className="cursor"
                onClick={() => {
                  setPostIndex(postIndex - 1);
                }}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col style={{ maxWidth: "579px" }}>
            {postIndex !== null &&
              (postsData[postIndex && postIndex]?.type === "video" ||
              postsData[postIndex && postIndex]?.type === "image" ? (
                <div
                  className="bg-white br-6 py-2"
                  style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                >
                  <PostHeader
                    Id={postsData[postIndex && postIndex]?.user.id}
                    img={postsData[postIndex && postIndex]?.user.image}
                    name={postsData[postIndex && postIndex]?.user.name}
                    time={postsData[postIndex && postIndex]?.time}
                    setPostDropDown={setPostDropDown}
                    postDropDown={postDropDown}
                    setDropDownId={setDropDownId}
                    original_id={postsData[postIndex && postIndex]?.original_id}
                    dropDownId={dropDownId}
                    setReportModal={setReportModal}
                    setPostDeleteModal={setPostDeleteModal}
                    profile_current_url={window.location.href}
                  />
                  <Row className="pt-3 pb-2">
                    <Col>
                      {postsData[postIndex && postIndex]?.type === "video" ? (
                        <>
                          {postsData[postIndex && postIndex]?.media &&
                            postsData[
                              postIndex && postIndex
                            ]?.media.map((value) => (
                              <VideoWithAspectRatio
                                src={BaseURL + value}
                                aspectRatio={value.sizes}
                              />
                            ))}
                        </>
                      ) : postsData[postIndex].media &&
                        postsData[postIndex].media.length === 1 ? (
                        postsData[postIndex].media.map((value) => (
                          <ImageWithAspectRatio
                            aspectRatio={value.sizes}
                            src={BaseURL + value}
                            alt="picture"
                          />
                        ))
                      ) : (
                        <Carousel
                          renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                              <IoIosArrowDroprightCircle
                                className="position-absolute m-auto top-0 bottom-0 end-0 me-3"
                                size={30}
                                color="white"
                                onClick={onClickHandler}
                              />
                            )
                          }
                          renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                              <IoIosArrowDropleftCircle
                                className="position-absolute m-auto start-0 ms-3 top-0 bottom-0"
                                size={30}
                                style={{ zIndex: "1" }}
                                color="white"
                                onClick={onClickHandler}
                              />
                            )
                          }
                          autoPlay={false}
                          infiniteLoop={false}
                          showIndicators={true}
                          showThumbs={false}
                          showStatus={false}
                        >
                          {postsData[postIndex && postIndex]?.media &&
                            postsData[
                              postIndex && postIndex
                            ]?.media.map((data) => (
                              <ImageWithAspectRatio
                                aspectRatio={
                                  postsData[postIndex && postIndex]?.sizes
                                }
                                src={BaseURL + data}
                                alt="picture"
                              />
                            ))}
                        </Carousel>
                      )}
                    </Col>
                  </Row>
                  {postsData[postIndex && postIndex]?.caption && (
                    <>
                      <Row className="px-3 py-2">
                        <Col>
                          <p className="fs-16 roboto-regular lh-20">
                            {postsData[postIndex && postIndex]?.caption}
                          </p>
                        </Col>
                      </Row>
                      <hr className="m-0" />
                    </>
                  )}
                  <PostFooter
                    likes_count={postsData[postIndex && postIndex]?.likes_count}
                    like_users_images={
                      postsData[postIndex && postIndex]?.like_users_images
                    }
                    isLike={postsData[postIndex && postIndex]?.isLike}
                    handleLike={handleLike}
                    original_id={postsData[postIndex && postIndex]?.original_id}
                    setPostId={setPostId}
                    handleGetComments={handleGetComments}
                    setCommentsModal={setCommentsModal}
                    comments_count={
                      postsData[postIndex && postIndex]?.comments_count
                    }
                    handleRepost={handleRepost}
                    isShare={postsData[postIndex && postIndex]?.isShare}
                    isSave={postsData[postIndex && postIndex]?.isSave}
                    handleSave={handleSave}
                    profile_current_url={window.location.href}
                  />
                </div>
              ) : postsData[postIndex && postIndex]?.type === "webinar" ? (
                <>
                  <div
                    className="bg-white br-6 py-2"
                    style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                  >
                    <Row className="justify-content-between px-3 position-relative">
                      <Col md={10} className="d-flex gap-3">
                        <div
                          className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                          style={{
                            maxHeight: "4rem",
                            maxWidth: "4rem",
                          }}
                        >
                          <Link
                            to={
                              PROFILE +
                              "/" +
                              postsData[postIndex && postIndex]?.user.id
                            }
                          >
                            <img
                              src={
                                postsData[postIndex && postIndex]?.user.image
                                  ? BaseURL +
                                    postsData[postIndex && postIndex]?.user
                                      .image
                                  : Avatar
                              }
                              alt="picture"
                              className="br-16 img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="align-self-end ">
                          <Link
                            to={
                              PROFILE +
                              "/" +
                              postsData[postIndex && postIndex]?.user.id
                            }
                          >
                            <h1 className="fs-16 roboto-medium text-dark">
                              {postsData[postIndex && postIndex]?.user?.name}
                            </h1>
                          </Link>
                          <span className="fs-12 roboto-regular">
                            <UnixToRelative
                              unixTimestamp={
                                postsData[postIndex && postIndex]?.time
                              }
                            />
                          </span>
                        </div>
                      </Col>
                      <Col md={1} className="align-self-center">
                        <img
                          onClick={() => {
                            setPostDropDown(!postDropDown);
                            setDropDownId(
                              postsData[postIndex && postIndex]?.original_id
                            );
                          }}
                          width={25}
                          className="cursor"
                          src={Dots}
                          alt="picture"
                        />
                      </Col>
                      {/* Post DropDown */}
                      <div className="position-absolute w-25 end-0 mt-5">
                        <Collapse
                          isOpen={
                            postDropDown &&
                            postsData[postIndex && postIndex]?.original_id ===
                              dropDownId
                              ? true
                              : false
                          }
                          className="bg-white br-16 p-3 text-center"
                          style={{
                            boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                          }}
                        >
                          {postsData[postIndex && postIndex]?.user.id ==
                            user_id && (
                            <>
                              <h6
                                onClick={() => {
                                  setPostDeleteModal(true);
                                  setPostDropDown(false);
                                }}
                                className="mb-0 text-red cursor"
                              >
                                {lang?.delete}
                              </h6>
                            </>
                          )}
                        </Collapse>
                      </div>
                      {/* End Post DropDown */}
                    </Row>
                    <Row className="pt-3 pb-2">
                      <Col>
                        {postsData[postIndex && postIndex]?.media.map(
                          (value) => (
                            <ImageWithAspectRatio
                              aspectRatio={
                                postsData[postIndex && postIndex]?.sizes
                              }
                              src={BaseURL + value}
                              alt="picture"
                            />
                          )
                        )}
                      </Col>
                    </Row>
                    {postsData[postIndex && postIndex]?.caption ||
                    postsData[postIndex && postIndex]?.title ? (
                      <>
                        <Row className="px-3 py-2">
                          <Col>
                            <h4>
                              {postsData[postIndex && postIndex]?.title &&
                              postsData[postIndex && postIndex]?.title.length >
                                26
                                ? postsData[
                                    postIndex && postIndex
                                  ]?.title.slice(0, 26) + "..."
                                : postsData[postIndex && postIndex]?.title}
                            </h4>
                            <p className="fs-16 roboto-regular lh-20">
                              {postsData[postIndex && postIndex]?.caption &&
                              postsData[postIndex && postIndex]?.caption
                                .length > 100
                                ? postsData[
                                    postIndex && postIndex
                                  ]?.caption.slice(0, 100) + "..."
                                : postsData[postIndex && postIndex]?.caption}
                            </p>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    <Row className="px-3 justify-content-between">
                      <Col className="align-self-center">
                        <p className="mb-0 text-warning">
                          {lang?.scheduled_on}
                        </p>
                        <small className="mb-0">
                          {postsData[postIndex && postIndex]?.scheduled}
                        </small>
                      </Col>
                      <Col className="text-end align-self-center">
                        <button
                          className="btn btn-warning fw-bold text-white px-4 py-2"
                          onClick={
                            postsData[postIndex && postIndex]?.user?.id ==
                            user_id
                              ? postsData[postIndex && postIndex]?.status === 0
                                ? null
                                : postsData[postIndex && postIndex]?.status ===
                                  2
                                ? lang?.webinar_ended
                                : () => {
                                    sessionStorage.setItem(
                                      "webinar",
                                      JSON.stringify({
                                        user_id:
                                          postsData[postIndex && postIndex]
                                            ?.user?.id,
                                        title:
                                          postsData[postIndex && postIndex]
                                            ?.title,
                                        post_id:
                                          postsData[postIndex && postIndex]?.id,
                                      })
                                    );
                                    Navigate("/webinar");
                                  }
                              : postsData[postIndex && postIndex]?.status === 0
                              ? postsData[postIndex && postIndex]?.isReserve
                                ? () =>
                                    handleReserveWebinar(
                                      postsData[postIndex && postIndex]?.id
                                    )
                                : () =>
                                    handleReserveWebinar(
                                      postsData[postIndex && postIndex]?.id
                                    )
                              : postsData[postIndex && postIndex]?.status === 1
                              ? postsData[postIndex && postIndex]?.isReserve
                                ? () => {
                                    sessionStorage.setItem(
                                      "webinar",
                                      JSON.stringify({
                                        user_id:
                                          postsData[postIndex && postIndex]
                                            ?.user?.id,
                                        title:
                                          postsData[postIndex && postIndex]
                                            ?.title,
                                        post_id:
                                          postsData[postIndex && postIndex]?.id,
                                      })
                                    );
                                    Navigate("/webinar");
                                  }
                                : () =>
                                    handleReserveWebinar(
                                      postsData[postIndex && postIndex]?.id
                                    )
                              : () => alert(lang?.webinar_is_ended)
                          }
                        >
                          {postsData[postIndex && postIndex]?.user.id == user_id
                            ? postsData[postIndex && postIndex]?.status === 0
                              ? null
                              : postsData[postIndex && postIndex]?.status === 2
                              ? lang?.webinar_ended
                              : lang?.start_webinar
                            : postsData[postIndex && postIndex]?.status === 0
                            ? postsData[postIndex && postIndex]?.isReserve
                              ? lang?.unreserve
                              : lang?.reserve_seat
                            : postsData[postIndex && postIndex]?.status === 1
                            ? postsData[postIndex && postIndex]?.isReserve
                              ? lang?.join_webinar
                              : lang?.reserve_seat
                            : lang?.webinar_ended}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : postsData[postIndex && postIndex]?.type ===
                "clinical-image" ? (
                <>
                  <div
                    className="bg-white br-6 py-2"
                    style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                  >
                    <Row className="justify-content-between px-3 position-relative">
                      <Col lg={10} className="d-flex gap-3">
                        <div
                          className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                          style={{
                            maxHeight: "4rem",
                            maxWidth: "4rem",
                          }}
                        >
                          <img
                            src={
                              postsData[postIndex && postIndex]?.user?.image
                                ? BaseURL +
                                  postsData[postIndex && postIndex]?.user?.image
                                : Avatar
                            }
                            alt="picture"
                            className="br-16 img-fluid"
                          />
                        </div>
                        <div className="align-self-end">
                          <h1 className="fs-16 roboto-medium">
                            {postsData[postIndex && postIndex]?.user?.name}
                          </h1>
                          <span className="fs-12 roboto-regular">
                            <UnixToRelative
                              unixTimestamp={
                                postsData[postIndex && postIndex]?.time
                              }
                            />
                          </span>
                        </div>
                      </Col>
                      <Col md={1} className="align-self-center">
                        <img
                          onClick={() => {
                            setPostDropDown(!postDropDown);
                            setDropDownId(
                              postsData[postIndex && postIndex]?.original_id
                            );
                          }}
                          width={25}
                          className="cursor"
                          src={Dots}
                          alt="picture"
                        />
                      </Col>
                      {/* Post DropDown */}
                      <div className="position-absolute w-25 end-0 mt-5">
                        <Collapse
                          isOpen={
                            postDropDown &&
                            postsData[postIndex && postIndex]?.original_id ===
                              dropDownId
                              ? true
                              : false
                          }
                          className="bg-white br-16 p-3 text-center"
                          style={{
                            boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                          }}
                        >
                          {postsData[postIndex && postIndex]?.user.id ==
                            user_id && (
                            <>
                              <h6
                                onClick={() => {
                                  // setPostDeleteModal(true);
                                  setPostDropDown(false);
                                }}
                                className="mb-0 text-red cursor"
                              >
                                {lang?.delete}
                              </h6>
                            </>
                          )}
                        </Collapse>
                      </div>
                      {/* End Post DropDown */}
                    </Row>
                    <Row className="pt-3 pb-2">
                      <Col>
                        {postsData[postIndex && postIndex]?.media &&
                        postsData[postIndex && postIndex]?.media.length ===
                          1 ? (
                          postsData[
                            postIndex && postIndex
                          ]?.media.map((data) => (
                            <ImageWithAspectRatio
                              aspectRatio={
                                postsData[postIndex && postIndex]?.sizes
                              }
                              src={BaseURL + data}
                              alt="picture"
                            />
                          ))
                        ) : (
                          <Carousel
                            autoPlay={false}
                            infiniteLoop={false}
                            showArrows={false}
                            showStatus={true}
                            showIndicators={true}
                            showThumbs={false}
                          >
                            {postsData[postIndex && postIndex]?.media &&
                              postsData[
                                postIndex && postIndex
                              ]?.media.map((data) => (
                                <ImageWithAspectRatio
                                  aspectRatio={
                                    postsData[postIndex && postIndex]?.sizes
                                  }
                                  src={BaseURL + data}
                                  alt="picture"
                                />
                              ))}
                          </Carousel>
                        )}
                      </Col>
                    </Row>
                    {postsData[postIndex && postIndex]?.caption ||
                    postsData[postIndex && postIndex]?.title ? (
                      <>
                        <Row className="px-3 py-2 justify-content-between">
                          <Col>
                            <h4>
                              {postsData[postIndex && postIndex]?.title &&
                              postsData[postIndex && postIndex]?.title.length >
                                26
                                ? postsData[
                                    postIndex && postIndex
                                  ]?.title.slice(0, 26) + "..."
                                : postsData[postIndex && postIndex]?.title}
                            </h4>
                            <p className="fs-16 roboto-regular lh-20">
                              {postsData[postIndex && postIndex]?.caption &&
                              postsData[postIndex && postIndex]?.caption
                                .length > 100
                                ? postsData[
                                    postIndex && postIndex
                                  ]?.caption.slice(0, 100) + "..."
                                : postsData[postIndex && postIndex]?.caption}
                            </p>
                          </Col>
                          {postsData[postIndex && postIndex]?.pdf && (
                            <Col className="text-end align-self-center">
                              <button
                                className="btn-blue border-0 fw-bold text-white px-4 py-2"
                                onClick={() => {
                                  window.open(
                                    BaseURL +
                                      postsData[postIndex && postIndex]?.pdf,
                                    "_blank"
                                  );
                                }}
                              >
                                {lang?.view_Case_Study}
                              </button>
                            </Col>
                          )}
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    {postsData[postIndex && postIndex]?.likes_count > 0 && (
                      <>
                        <Row className="ps-4 pe-3 py-2">
                          <Col className="d-flex">
                            {postsData[postIndex && postIndex]
                              ?.like_users_images &&
                              postsData[
                                postIndex && postIndex
                              ]?.like_users_images.map((data) => (
                                <div>
                                  <img
                                    src={
                                      data && data !== ".."
                                        ? BaseURL + data
                                        : Avatar
                                    }
                                    width={30}
                                    className="rounded-circle"
                                    style={{ marginLeft: "-10px" }}
                                    alt="picture"
                                  />
                                </div>
                              ))}

                            <p className="ps-2 fs-14 roboto-regular mb-0 align-self-center">
                              {postsData[postIndex && postIndex]?.likes_count}{" "}
                              {lang?.other_have_liked}
                            </p>
                          </Col>
                        </Row>
                        <hr className="m-0" />
                      </>
                    )}
                    <Row className="px-3 py-2 justify-content-between">
                      <Col lg={8} className="align-self-center">
                        <Row>
                          <Col className="d-flex gap-2 cursor">
                            <div>
                              <img
                                width={25}
                                src={
                                  postsData[postIndex && postIndex]?.isLike
                                    ? Liked
                                    : Like
                                }
                                alt="picture"
                                onClick={() =>
                                  handleLike(
                                    postsData[postIndex && postIndex]?.id
                                  )
                                }
                              />
                            </div>
                            <div className="align-self-end">
                              <p className="mb-0">{lang?.like}</p>
                            </div>
                          </Col>
                          <Col
                            className="d-flex gap-2"
                            onClick={() => {
                              setPostId(
                                postsData[postIndex && postIndex]?.original_id
                              );
                              handleGetComments(
                                postsData[postIndex && postIndex]?.original_id
                              );
                              setPostModal(true);
                            }}
                          >
                            <div>
                              <img width={25} src={Comment} alt="picture" />
                            </div>
                            <div className="align-self-end">
                              <p className="mb-0">
                                {postsData[postIndex && postIndex]
                                  ?.comments_count > 0
                                  ? postsData[postIndex && postIndex]
                                      ?.comments_count
                                  : ""}{" "}
                                {lang?.comments}
                              </p>
                            </div>
                          </Col>
                          <Col
                            className="d-flex gap-2 cursor"
                            onClick={() => {
                              handleRepost(
                                postsData[postIndex && postIndex]?.original_id
                              );
                            }}
                          >
                            <div>
                              <img
                                width={25}
                                src={
                                  postsData[postIndex && postIndex]?.isShare
                                    ? ReshareActive
                                    : Reshare
                                }
                                alt="picture"
                              />
                            </div>
                            <div className="align-self-end">
                              <p
                                className={`mb-0 ${
                                  postsData[postIndex && postIndex]?.isShare &&
                                  "text-blue"
                                }`}
                              >
                                {postsData[postIndex && postIndex]?.isShare
                                  ? lang?.reposted
                                  : lang?.repost}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={1} className="align-self-center">
                        <img
                          width={20}
                          src={
                            postsData[postIndex && postIndex]?.isSave
                              ? Saved
                              : Save
                          }
                          alt="picture"
                          className="cursor"
                          onClick={() =>
                            handleSave(postsData[postIndex && postIndex]?.id)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              ) : postsData[postIndex && postIndex]?.type === "poll" ? (
                <>
                  <div
                    className="bg-white br-6 py-2"
                    style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                  >
                    <Row className="justify-content-between px-3 position-relative">
                      <Col md={10} className="d-flex gap-3">
                        <div
                          className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                          style={{
                            maxHeight: "4rem",
                            maxWidth: "4rem",
                          }}
                        >
                          <img
                            src={
                              postsData[postIndex && postIndex]?.user?.image
                                ? BaseURL +
                                  postsData[postIndex && postIndex]?.user?.image
                                : Avatar
                            }
                            alt="picture"
                            className="br-16 img-fluid"
                          />
                        </div>
                        <div className="align-self-end">
                          <h1 className="fs-16 roboto-medium">
                            {postsData[postIndex && postIndex]?.user?.name}
                          </h1>
                          <span className="fs-12 roboto-regular">
                            <UnixToRelative
                              unixTimestamp={
                                postsData[postIndex && postIndex]?.time
                              }
                            />
                          </span>
                        </div>
                      </Col>
                      <Col md={1} className="align-self-center">
                        <img
                          onClick={() => {
                            setPostDropDown(!postDropDown);
                            setDropDownId(
                              postsData[postIndex && postIndex]?.original_id
                            );
                          }}
                          width={25}
                          className="cursor"
                          src={Dots}
                          alt="picture"
                        />
                      </Col>
                      {/* Post DropDown */}
                      <div
                        className="position-absolute w-25 end-0 mt-5"
                        style={{ zIndex: "9" }}
                      >
                        <Collapse
                          isOpen={
                            postDropDown &&
                            postsData[postIndex && postIndex]?.original_id ===
                              dropDownId
                              ? true
                              : false
                          }
                          className="bg-white br-16 p-3 text-center"
                          style={{
                            boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                          }}
                        >
                          {postsData[postIndex && postIndex]?.user.id ==
                            user_id && (
                            <>
                              <h6
                                onClick={() => {
                                  setPostDeleteModal(true);
                                  setPostDropDown(false);
                                }}
                                className="mb-0 text-red cursor"
                              >
                                {lang?.delete}
                              </h6>
                            </>
                          )}
                        </Collapse>
                      </div>
                      {/* End Post DropDown */}
                    </Row>
                    <Row className="pt-3 pb-2">
                      <Col>
                        {postsData[postIndex && postIndex]?.media &&
                          postsData[
                            postIndex && postIndex
                          ]?.media.map((data) => (
                            <ImageWithAspectRatio
                              aspectRatio={data?.sizes}
                              src={BaseURL + data}
                              alt="picture"
                            />
                          ))}
                      </Col>
                    </Row>
                    {postsData[postIndex && postIndex]?.title && (
                      <>
                        <Row className="px-3 py-2">
                          <Col>
                            <p className="fs-20 fs-md-15 roboto-medium">
                              {postsData[postIndex && postIndex]?.title}
                            </p>
                          </Col>
                        </Row>
                        <hr className="m-0" />
                      </>
                    )}

                    {[
                      postsData[postIndex && postIndex]?.option_1,
                      postsData[postIndex && postIndex]?.option_2,
                      postsData[postIndex && postIndex]?.option_3,
                      postsData[postIndex && postIndex]?.option_4,
                    ].map((data, index) => (
                      <Row className="mx-3 my-3" key={index}>
                        {data !== "" && (
                          <Col
                            className="py-2 px-4 cursor"
                            onClick={() => {
                              setSelectedPoll(index);
                              handlePollAnswer(
                                postsData[postIndex && postIndex]?.original_id,
                                index
                              );
                            }}
                            style={{
                              border: "1px solid #00c5de",
                              borderRadius: "10px",
                              background: "rgba(0, 197, 222,0.1)",
                            }}
                          >
                            <Row className="justify-content-between">
                              <Col>
                                <h5>{data}</h5>
                              </Col>
                              <Col className="d-flex gap-2 justify-content-end">
                                <label className="form-check-label">
                                  {
                                    postsData[postIndex && postIndex]
                                      ?.answer_percentage[index]
                                  }{" "}
                                  %
                                </label>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    checked={selectedPoll === index}
                                    value={index}
                                    name="poll"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    ))}
                    <hr />

                    {postsData[postIndex && postIndex]?.likes_count > 0 && (
                      <>
                        <Row className="ps-4 pe-3 py-2">
                          <Col className="d-flex">
                            {postsData[postIndex && postIndex]
                              ?.like_users_images &&
                              postsData[
                                postIndex && postIndex
                              ]?.like_users_images.map((data) => (
                                <div>
                                  <img
                                    src={
                                      data && data !== ".."
                                        ? BaseURL + data
                                        : Avatar
                                    }
                                    width={30}
                                    className="rounded-circle"
                                    style={{ marginLeft: "-10px" }}
                                    alt="picture"
                                  />
                                </div>
                              ))}

                            <p className="ps-2 fs-14 roboto-regular mb-0 align-self-center">
                              {postsData[postIndex && postIndex]?.likes_count}{" "}
                              {lang?.other_have_liked}
                            </p>
                          </Col>
                        </Row>
                        <hr className="m-0" />
                      </>
                    )}
                    <Row className="px-3 py-2 justify-content-between">
                      <Col md={8} className="align-self-center">
                        <Row>
                          <Col className="d-flex gap-2 cursor">
                            <div>
                              <img
                                width={25}
                                src={
                                  postsData[postIndex && postIndex]?.isLike
                                    ? Liked
                                    : Like
                                }
                                alt="picture"
                                onClick={() =>
                                  handleLike(
                                    postsData[postIndex && postIndex]?.id
                                  )
                                }
                              />
                            </div>
                            <div className="align-self-end">
                              <p className="mb-0">{lang?.like}</p>
                            </div>
                          </Col>
                          <Col
                            className="d-flex gap-1 cursor"
                            onClick={() => {
                              setPostId(
                                postsData[postIndex && postIndex]?.original_id
                              );
                              handleGetComments(
                                postsData[postIndex && postIndex]?.original_id
                              );
                              setPostModal(true);
                            }}
                          >
                            <div>
                              <img width={25} src={Comment} alt="picture" />
                            </div>
                            <div className="align-self-end">
                              <p className="mb-0">
                                {postsData[postIndex && postIndex]
                                  ?.comments_count > 0
                                  ? postsData[postIndex && postIndex]
                                      ?.comments_count
                                  : ""}{" "}
                                {lang?.comments}
                              </p>
                            </div>
                          </Col>
                          <Col
                            className="d-flex gap-2 cursor"
                            onClick={() => {
                              handleRepost(
                                postsData[postIndex && postIndex]?.original_id
                              );
                            }}
                          >
                            <div>
                              <img
                                width={25}
                                src={
                                  postsData[postIndex && postIndex]?.isShare
                                    ? ReshareActive
                                    : Reshare
                                }
                                alt="picture"
                              />
                            </div>
                            <div className="align-self-end">
                              <p
                                className={`mb-0 ${
                                  postsData[postIndex && postIndex]?.isShare &&
                                  "text-blue"
                                }`}
                              >
                                {postsData[postIndex && postIndex]?.isShare
                                  ? lang?.reposted
                                  : lang?.repost}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={1} className="align-self-center">
                        <img
                          width={20}
                          src={
                            postsData[postIndex && postIndex]?.isSave
                              ? Saved
                              : Save
                          }
                          alt="picture"
                          className="cursor"
                          onClick={() =>
                            handleSave(postsData[postIndex && postIndex]?.id)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              ))}
          </Col>
          <Col
            md={1}
            className="position-absolute end-0"
            style={{ marginTop: "20rem" }}
          >
            {postIndex >= 0 &&
            postsData &&
            postsData.length !== postIndex + 1 ? (
              <IoIosArrowDroprightCircle
                size={30}
                color="white"
                className="cursor"
                onClick={() => {
                  setPostIndex(postIndex + 1);
                }}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal>
      {/* End Post Detail Modal */}

      {/* Posts Comments */}
      {/* Post Modal */}
      <Offcanvas
        direction="end"
        scrollable
        isOpen={commentsModal}
        zIndex={9}
        style={{ width: "480px" }}
        toggle={() => {
          setCommentsModal(false);
        }}
      >
        <OffcanvasHeader
          className="bg-grey"
          toggle={() => {
            setCommentsModal(false);
          }}
        >
          {lang?.comments}
        </OffcanvasHeader>
        <hr className="m-0" style={{ opacity: "0.1" }} />
        <OffcanvasBody
          className="bg-grey scroll-hide"
          style={{ paddingBottom: "5rem" }}
        >
          {isLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                {postComments?.data &&
                  postComments.data.map((content, index) => (
                    <div className="bg-white br-12 p-2 my-3" key={index}>
                      <div className="d-flex gap-3">
                        {/* Profile Image */}
                        <div>
                          <div
                            className="cursor bg-white br-16 dropdown-avatar-shadow"
                            style={{
                              maxWidth: "3rem",
                              maxHeight: "3rem",
                              padding: "0.8px",
                            }}
                          >
                            <img
                              src={
                                content.user.image
                                  ? BaseURL + content.user.image
                                  : Avatar
                              }
                              className="br-14 img-fluid"
                              alt="picture"
                            />
                          </div>
                        </div>
                        {/* Div End Profile Image */}
                        {/* Profile Detials */}
                        <div>
                          <p className="roboto-bold fs-16 mb-0 cursor">
                            {content.user.name}
                          </p>
                          <p className="roboto-regular fs-14 text-gray">
                            {content.comment}
                          </p>
                          <div className="d-flex gap-4">
                            <p className="roboto-medium fs-12">
                              {<TimestampConverter timestamp={content.time} />}
                            </p>
                            <p
                              className="roboto-medium fs-12 cursor text-blue"
                              onClick={() => {
                                setIsReply(true);
                                setReplyUser(content.user);
                                setCommentId(JSON.stringify(content.id));
                              }}
                            >
                              {lang?.reply}
                            </p>
                            <p
                              className="roboto-medium fs-12 cursor text-red"
                              onClick={() => {
                                setCommentId(JSON.stringify(content.id));
                                setDeleteModal(true);
                              }}
                            >
                              {lang?.delete}
                            </p>
                          </div>
                        </div>
                        {/* Div ended Profile Detials */}
                      </div>

                      {content.replies > 0 && (
                        <div>
                          <p
                            className="fw-bold text-gray fs-12 text-end me-3 cursor mb-0"
                            onClick={() => {
                              if (content.id === commentId) {
                                setCommentId("");
                                setCommentReplies([]);
                              } else {
                                setCommentId(content.id);
                                handleGetReplies(content.id);
                              }
                            }}
                          >
                            {content.id === commentId ? "Hide" : "View"}{" "}
                            {content.replies}{" "}
                            {content.replies > 1 ? "replies" : "reply"}
                          </p>

                          {content.id === commentId &&
                            commentReplies.map((val) => {
                              return (
                                <>
                                  <div>
                                    <Collapse horizontal isOpen={true}>
                                      <div
                                        className="pt-3 ms-5 mt-4"
                                        key={val.id}
                                      >
                                        <div className="d-flex gap-3">
                                          {/* Profile Image */}
                                          <div>
                                            <div
                                              className="cursor bg-white br-16 dropdown-avatar-shadow"
                                              style={{
                                                maxWidth: "3rem",
                                                maxHeight: "3rem",
                                                padding: "0.8px",
                                              }}
                                            >
                                              <img
                                                src={
                                                  val.user.image
                                                    ? BaseURL + val.user.image
                                                    : Avatar
                                                }
                                                className="br-14 img-fluid"
                                                alt="..."
                                              />
                                            </div>
                                          </div>
                                          {/* Div End Profile Image */}
                                          {/* Profile Detials */}
                                          <div>
                                            <p className="roboto-bold fs-16 mb-0 cursor">
                                              {val.user.name}
                                            </p>
                                            <p className="roboto-regular fs-14 text-gray">
                                              {val.comment}
                                            </p>
                                            <div className="d-flex gap-4">
                                              <p className="roboto-medium fs-12">
                                                {
                                                  <TimestampConverter
                                                    timestamp={val.time}
                                                  />
                                                }
                                              </p>
                                              <p
                                                className="roboto-medium fs-12 cursor text-blue"
                                                onClick={() => {
                                                  setIsReply(true);
                                                  setReplyUser(val.user);
                                                  setCommentId(
                                                    JSON.stringify(content.id)
                                                  );
                                                }}
                                              >
                                                {lang?.reply}
                                              </p>
                                              <p
                                                className="roboto-medium fs-12 cursor text-red"
                                                onClick={() => {
                                                  setCommentId(
                                                    JSON.stringify(val.id)
                                                  );
                                                  setDeleteModal(true);
                                                }}
                                              >
                                                {lang?.delete}
                                              </p>
                                            </div>
                                          </div>
                                          {/* Div ended Profile Detials */}
                                        </div>
                                      </div>
                                    </Collapse>
                                    <hr
                                      className="ms-5"
                                      style={{ opacity: "0.1" }}
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              {/* if NO comments available */}
              {postComments.data && postComments.data.length === 0 && (
                <div className="text-center">
                  <h3 className="text-gray">{lang?.there_no_comment}</h3>
                  <p className="text-gray">{lang?.no_one_commented_post}</p>
                </div>
              )}
            </>
          )}

          {/* Comment input section */}
          <div className="position-absolute bottom-0 m-auto start-0 end-0">
            {isReply && (
              <Row>
                <Col
                  className="bg-blue br-12 d-flex justify-content-between"
                  style={{ marginLeft: "1.3rem", marginRight: "6.3rem" }}
                >
                  <h6 className="text-white mb-0 py-2">
                    {lang?.replying_to} {replyUser.name}
                  </h6>
                  <div
                    className="align-self-center cursor"
                    onClick={() => setIsReply(false)}
                  >
                    <MdOutlineCancel color="white" size={20} />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex">
                <input
                  type="text"
                  placeholder={lang?.write_your_coment}
                  className="form-control m-2 py-3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div className="align-self-center">
                  <button
                    type="button"
                    disabled={comment === "" ? true : false}
                    className="btn-blue border-0 ms-1 me-2 py-3 px-4"
                    onClick={() => {
                      isReply
                        ? handleAddReplies(commentId)
                        : handleAddComment();
                    }}
                  >
                    <BsSendFill size={20} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          {/* Comment input section */}
        </OffcanvasBody>
      </Offcanvas>
      {/* End */}

      <Modal
        isOpen={followingModalOpen}
        toggle={() => {
          setFollowingModalOpen(!followingModalOpen);
        }}
        centered
        zIndex={9}
        scrollable
      >
        <ModalHeader
          toggle={() => {
            setFollowingModalOpen(!followingModalOpen);
          }}
        >
          {lang?.following}
        </ModalHeader>
        <ModalBody className="scroll-hide">
          <InfiniteScroll
            pageStart={0}
            loadMore={handleFollowingList}
            hasMore={hasMoreFollowing}
            loader={
              <div className="w-100 text-center" key={0}>
                <img src={SpinnerGif} alt="picture" width={70} />
              </div>
            }
            useWindow={false}
          >
            {followingAcc &&
              followingAcc.map((value, index) => (
                <>
                  <Link
                    onClick={() => {
                      Navigate(PROFILE + "/" + value.id);
                      window.location.reload();
                    }}
                    className="d-flex p-2 justify-content-between manue-hover rounded text-dark"
                    key={index}
                  >
                    <div className="d-flex gap-2">
                      <div
                        className="bg-white dropdown-avatar-shadow align-self-center rounded d-flex justify-content-center align-items-center"
                        style={{
                          maxWidth: "4rem",
                          maxHeight: "4rem",
                          padding: "1.8px",
                        }}
                      >
                        <ImageComponent
                          src={value?.image ? BaseURL + value.image : Avatar}
                          compClassName="img-fluid rounded"
                          loader_height="4rem"
                          loader_width="4rem"
                        />
                      </div>

                      <div className="align-self-center">
                        <div className="fs-17 roboto-bold text-dark search-post-name">
                          {value.name}
                        </div>
                        <p className="mb-0 fs-12 roboto-regular search-post-name">
                          {value.user_name}
                        </p>
                      </div>
                    </div>
                  </Link>
                  {index !== 3 && (
                    <hr className="m-0" style={{ opacity: "6%" }} />
                  )}
                </>
              ))}
          </InfiniteScroll>
        </ModalBody>
      </Modal>
      {/* chat */}
      <Offcanvas
        zIndex={9}
        style={{ width: "480px" }}
        direction="end"
        isOpen={isChatOpen}
        toggle={toggleChatOffcanvas}
        placement="end"
        username={profileInfo.name}
      >
        <div className="d-flex justify-content-between p-3">
          <h5 className="m-0">{profileInfo.name}</h5>
          <div
            className="manue-hover rounded-circle cursor p-1 d-flex justify-content-center align-items-center"
            onClick={() => setIsChatOpen(!isChatOpen)}
          >
            <CiCircleRemove size={22} />
          </div>
        </div>
        <hr className="mb-2 mt-0" />
        <OffcanvasBody className="ticketchat scroll-hide">
          {loadingchat ? (
            <div className="ticketchatspinner">
              <SpecSpinner />
            </div>
          ) : (
            ticketChat
              .slice()
              .reverse()
              .map((message, index) => (
                <div
                  key={message}
                  ref={index === ticketChat.length - 1 ? lastMessageRef : null}
                >
                  <div
                    className={
                      message.from === login_id
                        ? "right-message"
                        : message.to == login_id
                        ? "left-message"
                        : ""
                    }
                  >
                    <div>
                      <p className="m-0">{message.message}</p>
                    </div>
                  </div>
                  <div
                    className={
                      message.sender && message.sender.name // Check if message.sender and name exist
                        ? message.from === login_id
                          ? "right-name"
                          : message.to == login_id
                          ? "left-name"
                          : ""
                        : ""
                    }
                  >
                    <p className="profilechat">
                      {message.sender ? message.sender.name : ""}
                    </p>
                  </div>
                </div>
              ))
          )}
          <Row>
            <div className="d-flex position-absolute bottom-0 bg-white">
              <input
                type="text"
                placeholder={lang?.write_your_message}
                className="form-control m-2 py-3"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <div className="align-self-center">
                <button
                  type="button"
                  className="btn-blue border-0 ms-1 me-2 py-3 px-4"
                  onClick={HandlemessageSend}
                  disabled={isLoadingchat || messageInput.trim() === ""}
                >
                  {isLoadingchat ? (
                    <div>
                      <Spinner size={"sm"} />
                    </div>
                  ) : (
                    <BsSendFill size={20} />
                  )}
                </button>
              </div>
            </div>
          </Row>
        </OffcanvasBody>
      </Offcanvas>

      {/* block modal */}
      <Modal
        isOpen={isBlockModalOpen}
        toggle={() => {
          setIsBlockModalOpen(!isBlockModalOpen);
        }}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.block_user}</ModalHeader>
        <ModalBody>
          <div>
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_block_account}
            </h2>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setIsBlockModalOpen(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleBlock}
            >
              {isLoadingblock ? (
                <div>
                  <Spinner size={"sm"} />
                </div>
              ) : (
                lang?.yes_block
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* report Job modal */}
      <Modal
        centered
        zIndex={9}
        isOpen={isReportModalOpen}
        toggle={() => setIsReportModalOpen(!isReportModalOpen)}
      >
        <ModalHeader>{lang?.report_this_user}</ModalHeader>
        <ModalBody>
          <h3 className="fs-16">{lang?.report_this_user_para}</h3>

          <form className="w-100">
            <textarea
              cols="30"
              rows="7"
              className="form-control fs-16 w-100"
              placeholder={lang?.enter_your_report}
              onChange={(e) => {
                setReportData(e.target.value);
                setIsReportButtonDisabled(!e.target.value.trim());
              }}
            ></textarea>
            <div className="justify-content-end gap-3 d-flex mt-3">
              <div
                className="btn manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                onClick={() => setIsReportModalOpen(!isReportModalOpen)}
              >
                {lang?.cancel}
              </div>
              <button
                type="button"
                className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
                onClick={handleReport}
                disabled={isReportButtonDisabled}
              >
                {isreportLoading ? <Spinner /> : lang?.submit_report}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Report Modal */}
      <Modal
        isOpen={reportModal}
        toggle={() => {
          setReportModal(!reportModal);
        }}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.report_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.modal_enter_report_para}</h6>
          <textarea
            rows="7"
            value={reportInput}
            className="form-control"
            placeholder={lang?.enter_your_report}
            onChange={(e) => setReportInput(e.target.value)}
          ></textarea>

          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setReportModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              onClick={() => {
                handlePostReport();
              }}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.submit_report}
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Post Delete */}
      <Modal
        isOpen={postDeleteModal}
        toggle={() => {
          setPostDeleteModal(!postDeleteModal);
        }}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setPostDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Profile;

const CourseCard = (props) => {
  const navigate = useNavigate();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { lang } = useContext(Language);

  const strNumber = props.price;
  const doubleNumber = parseFloat(strNumber);
  const hasDecimal = strNumber.includes(".");
  const formattedNumber = hasDecimal
    ? doubleNumber.toString()
    : doubleNumber.toFixed(2);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleClick = () => {
    sessionStorage.setItem("CourseId", props?.course_id);
    navigate(COURSE_DETAILS);
  };

  return (
    <div className="border border-1 cursor mb-3 rounded" onClick={handleClick}>
      <div className="position-relative">
        <div
          style={{
            borderRadius: "7px 7px 0px 0px",
            maxHeight: "495px",
            minWidth: "300px",
          }}
        >
          <img
            src={BaseURL + props?.image}
            className={isImageLoaded ? "d-block" : "d-none"}
            style={{
              borderRadius: "7px 7px 0px 0px",
              height: "495px",
              minWidth: "300px",
              objectFit: "fill",
            }}
            onLoad={handleImageLoad}
            alt="picture"
          />
        </div>
        {!isImageLoaded && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              borderRadius: "7px 7px 0px 0px",
              background: "#f9f9f9",
              height: "495px",
              minWidth: "300px",
              backgroundSize: "cover",
            }}
          >
            <div className="d-flex justify-content-center">
              <div className="text-center">
                <img src={SpinnerGif} alt="picture" width={100} height={100} />
              </div>
            </div>
          </div>
        )}

        <div
          className="position-absolute top-0 start-0 px-4 py-2 bg-green"
          style={{ borderRadius: "7px 0px 7px 0px" }}
        >
          <span className="fs-16 roboto-medium text-white">
            {props?.price === "0" ? lang?.free : "$" + formattedNumber}
          </span>
        </div>
      </div>
      <div
        className="p-3 bg-white br-b-5"
        style={{ borderRadius: "0px 0px 7px 7px" }}
      >
        <h3 className="fs-20 fs-md-15 roboto-bold text-start">
          {props?.name?.length > 20
            ? props.name.substring(0, 25) + "..."
            : props.name}
        </h3>
      </div>
    </div>
  );
};

function TimestampConverter({ timestamp }) {
  const dt = new Date(timestamp * 1000); // Convert to milliseconds

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = dt.toLocaleString("en-US", options);

  return <div>{formattedDate}</div>;
}
