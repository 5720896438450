import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Media from "../../assets/images/icon/post.png";
import Poll from "../../assets/images/icon/polls.png";
import Webinar from "../../assets/images/icon/webinar.png";
import ClinicalStudy from "../../assets/images/icon/clinal-studies.png";
import { BaseURL, SplashApi } from "../../constants/API";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ADDPOST } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";

const PostForm = () => {
  const { lang } = useContext(Language);

  const Navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("SignupData"));
  const [profilePic, setProfilePic] = useState("");
  useEffect(() => {
    axios
      .post(SplashApi + data.data.id, {
        type: "all",
      })
      .then((res) => {
        setProfilePic(res.data.user.image);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div
        className="bg-white feed-form-shadow rounded cursor"
        style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
      >
        <>
          <Row className="p-4 pb-1">
            <Col className="d-flex gap-4">
              <div>
                <div
                  className="bg-white rounded dropdown-avatar-shadow"
                  style={{
                    maxWidth: "3rem",
                    maxHeight: "3rem",
                    padding: "0.8px",
                  }}
                >
                  <ImageComponent
                    src={profilePic === "" ? Avatar : BaseURL + profilePic}
                    compClassName="rounded img-fluid"
                    loader_height="3rem"
                    loader_width="3rem"
                  />
                </div>
              </div>
              <div className="w-100 align-self-center">
                <textarea
                  onClick={() => {
                    Navigate(ADDPOST + "/1");
                  }}
                  className="form-control border-0 cursor fs-md-14"
                  placeholder={lang?.post_form_placeholder}
                  rows="1"
                ></textarea>
              </div>
            </Col>
          </Row>
          <div>
            <div className="mx-2 overflow-auto scroll-hide d-flex justify-content-between px-2 top-div-img">
              <div
                className="d-flex my-3 py-1 align-self-center justify-content-center cursor w-120px rounded manue-hover hover-blue"
                onClick={() => {
                  Navigate(ADDPOST + "/1");
                }}
              >
                <div>
                  <img src={Media} alt="..." />
                </div>
                <div>
                  <span className="roboto-regular ps-2">{lang?.media}</span>
                </div>
              </div>
              <div
                className="d-flex my-3 py-1 align-self-center justify-content-center cursor w-120px rounded manue-hover hover-blue"
                onClick={() => {
                  Navigate(ADDPOST + "/2");
                }}
              >
                <div>
                  <img src={Poll} alt="picture" />
                </div>
                <div>
                  <span className="roboto-regular ps-2">{lang?.poll}</span>
                </div>
              </div>
              <div
                className="d-flex my-3 py-1 align-self-center justify-content-center cursor w-120px rounded manue-hover hover-blue"
                onClick={() => {
                  Navigate(ADDPOST + "/3");
                }}
              >
                <div>
                  <img src={Webinar} alt="picture" />
                </div>
                <div>
                  <span className="roboto-regular ps-2">{lang?.webinars}</span>
                </div>
              </div>
              <div
                onClick={() => {
                  Navigate(ADDPOST + "/4");
                }}
                className="d-flex my-3 py-1 align-self-center justify-content-center cursor w-120px rounded manue-hover hover-blue"
              >
                <div>
                  <img src={ClinicalStudy} alt="picture" />
                </div>
                <div>
                  <span className="roboto-regular ps-2">
                    {lang?.clinical_study}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default PostForm;
