import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Link } from "react-router-dom";
import {
  BaseURL,
  FollowApi,
  RecomendationApi,
  SplashApi,
} from "../../constants/API";
import axios from "axios";
import { PROFILE } from "../../constants/RoutesUrl";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import SpinnerGif from "../../assets/images/Spinner.gif";
import { toast } from "react-toastify";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";

const SuggestedProfiles = () => {
  const { lang } = useContext(Language);

  const [suggestionsData, setSuggestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("SignupData"));
  const user_id = userToken.data.id;
  const [modalOpen, setModalOpen] = useState(false);
  const [hasMoreFollowing, setHasMoreFollowing] = useState(true);
  const [followingAcc, setFollowingAcc] = useState([]);
  const [pagefollowing, setPagefollowing] = useState(1);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleRecommendationList = () => {
    axios
      .get(
        RecomendationApi +
          user_id +
          "?page=" +
          JSON.stringify(pagefollowing + 1)
      )
      .then((res) => {
        const updatedValue = res.data.data.data;
        setSuggestionsData((prevData) => [...prevData, ...updatedValue]);
        setFollowingAcc(followingAcc.concat(updatedValue));
        setPagefollowing(pagefollowing + 1);

        if (res.data.data.current_page >= res.data.data.last_page) {
          setHasMoreFollowing(false); // No more pages to load
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(RecomendationApi + user_id);

        setFollowingAcc(response.data.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleFollow = (id) => {
    setIsLoading(true);
    axios
      .get(FollowApi + id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          const updatedProfiles = suggestionsData.map((profile) => {
            if (profile.id === id) {
              return { ...profile, follow: !profile.follow };
            }
            return profile;
          });

          setSuggestionsData(updatedProfiles);
          setIsLoading(false);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .post(SplashApi + userToken.data.id, {
        type: "all",
      })
      .then((res) => {
        sessionStorage.setItem("SplashData", JSON.stringify(res.data));
        setSuggestionsData(res.data.home.suggestion);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div
      className="d-xl-block d-none mt-3 position-fixed w-100"
      style={{
        maxWidth: "315px",
      }}
    >
      <div
        className="bg-white rounded px-1"
        style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
      >
        <div className="px-3 pt-4">
          <h4 className="fs-16 roboto-bold">{lang?.people_suggestions}</h4>
        </div>
        {suggestionsData &&
          suggestionsData.slice(0, 4)?.map((value, index) => (
            <>
              <div
                className="d-flex py-3 px-2 justify-content-between manue-hover rounded"
                key={index}
              >
                <Link
                  to={PROFILE + "/" + value.id}
                  className="d-flex gap-2 text-dark w-100"
                >
                  <div
                    className="bg-white dropdown-avatar-shadow align-self-center rounded"
                    style={{
                      maxWidth: "3rem",
                      maxHeight: "3rem",
                      padding: "1.8px",
                    }}
                  >
                    <ImageComponent
                      src={value?.image ? BaseURL + value.image : Avatar}
                      compClassName="img-fluid rounded"
                      loader_height="2.8rem"
                      loader_width="2.8rem"
                    />
                  </div>
                  <div className="align-self-center">
                    <p className="fs-14 roboto-bold suggest_name m-0">
                      {value.name}
                    </p>
                    <p className="mb-0 fs-12 roboto-regular suggest_name">
                      @{value.user_name}
                    </p>
                  </div>
                </Link>
                <div className="align-self-center">
                  <button
                    disabled={isLoading}
                    className="btn btn-white border border-1 px-3 py-1"
                    onClick={() => {
                      handleFollow(value.id);
                    }}
                  >
                    <span className="roboto-medium">
                      {value.follow ? lang?.following : lang?.follow}
                    </span>
                  </button>
                </div>
              </div>
              {index !== 3 && (
                <hr className="mx-4 my-0" style={{ opacity: "6%" }} />
              )}
            </>
          ))}
        <hr style={{ opacity: "25%" }} />
        <div className="text-center cursor pb-3">
          <h6
            className="fs-14 roboto-bold hover-blue m-0"
            onClick={toggleModal}
          >
            {lang?.view_all_recommendations}
          </h6>

          <Modal
            scrollable
            centered
            zIndex={9}
            isOpen={modalOpen}
            toggle={toggleModal}
          >
            <ModalHeader toggle={toggleModal}>
              {lang?.recommendations}
            </ModalHeader>
            <ModalBody className="scroll-hide">
              <InfiniteScroll
                pageStart={0}
                loadMore={handleRecommendationList}
                hasMore={hasMoreFollowing}
                loader={
                  <div className="w-100 text-center" key={0}>
                    <img
                      src={SpinnerGif}
                      alt="picture"
                      width={30}
                      height={30}
                    />
                  </div>
                }
                useWindow={false}
              >
                {suggestionsData &&
                  suggestionsData.map((value, index) => (
                    <>
                      <div
                        className="d-flex p-2 justify-content-between manue-hover rounded"
                        key={index}
                      >
                        <Link
                          to={PROFILE + "/" + value.id}
                          className="d-flex gap-2 w-100 text-dark"
                        >
                          <div
                            className="bg-white dropdown-avatar-shadow align-self-center rounded"
                            style={{
                              maxWidth: "3rem",
                              maxHeight: "3rem",
                              padding: "1.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? BaseURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded"
                              loader_height="3rem"
                              loader_width="3rem"
                            />
                          </div>

                          <div className="align-self-center">
                            <div className="fs-17 roboto-bold text-dark text-ellips-110px">
                              {value.name}
                            </div>
                            <p className="mb-0 fs-12 roboto-regular text-ellips-110px">
                              {value.user_name}
                            </p>
                          </div>
                        </Link>
                        <div className="align-self-center">
                          <button
                            disabled={isLoading}
                            className="btn btn-white border border-1 px-3 py-1"
                            onClick={() => {
                              handleFollow(value.id);
                            }}
                          >
                            <span className="roboto-medium">
                              {value.follow ? lang?.following : lang?.follow}
                            </span>
                          </button>
                        </div>
                      </div>
                      {index !== 3 && (
                        <hr className="m-0" style={{ opacity: "6%" }} />
                      )}
                    </>
                  ))}
              </InfiniteScroll>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SuggestedProfiles;
