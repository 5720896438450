import React, { useEffect, useContext, useState } from "react";
import NavBar from "../NavBar";
import CoursePreview from "./CoursePreview";
import { MyCoursesApi } from "../../constants/API";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { Language } from "../../App";

function ProfileCourses() {
  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const { lang } = useContext(Language);
  const user_id = localData?.user?.id;
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);

  const handleFetch = () => {
    setSpin(true);

    fetch(`${MyCoursesApi}${user_id}/${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setData(data.data.data);
        } else {
          toast.error(data.error || lang?.an_error_occured_fetching_date);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      })
      .finally(() => {
        setSpin(false);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="profilejobs">
          <div className="row job-scroll scroll-hide">
            <div className="col-md-6 mt-3 card">
              <>
                {spin ? (
                  <div className="profilejobspinner">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div>
                      <h1 className="fs-20 fs-md-16 roboto-medium text-center mt-2">
                        {lang?.courses}
                      </h1>
                    </div>
                    {data.length > 0 ? (
                      <div
                        className="d-flex gap-2 my-4"
                        style={{ flexWrap: "wrap", flexDirection: "row" }}
                      >
                        {data.map((course, index) => (
                          <div
                            style={{ minWidth: "32%", flex: "0 0 auto" }}
                            key={index}
                          >
                            <CoursePreview
                              certificates={course.certificates}
                              owner_username={course.owner_username}
                              owner_image={course.owner_image}
                              thumbnail={course.thumbnail}
                              image={course.cover}
                              price={course.price}
                              name={course.title}
                              course_id={course.id}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="fw-bold mt-3">
                        {lang?.no_course_added_user}
                      </p>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCourses;
