import React from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import CoursePost from "../../components/posts-details/CoursePost";

const CourseSharedPost = () => {
  const { course_id } = useParams();
  return (
    <>
      <Container fluid>
        <div
          className="vh-100 bg-offwhite space-top overflowY-auto scroll-hide overflowX-hidden"
          style={{ paddingBottom: "3rem" }}
        >
          <Row className="justify-content-center mt-3 mb-5">
            <Col xl={9}>
              <CoursePost course_id={course_id} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default CourseSharedPost;
