import React from "react";
import { Container, Row } from "reactstrap";
import SpecializationComponent from "../../components/auth/SpecializationComponent";

const Specialization = () => {
  return (
    <Container fluid>
      <Row>
        <SpecializationComponent />
      </Row>
    </Container>
  );
};

export default Specialization;
