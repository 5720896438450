import React, { useEffect, useContext, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import NavBar from "../NavBar";
import { BaseURL } from "../../constants/API";
import CoursePreview from "./CoursePreview";
import { toast } from "react-toastify";
import { IoMdArrowRoundDown } from "react-icons/io";
import { Language } from "../../App";

const SeeAllCourses = () => {
  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;
  const { lang } = useContext(Language);
  const [dataDetails, setDataDetails] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [load, setLoad] = useState(false);

  const HandleLoadMore = async (page_num) => {
    setLoad(true);
    let page = page_num + 1;
    try {
      const response = await fetch(
        `${BaseURL}api/course/v1/all/${user_id}?page=${JSON.stringify(page)}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const fetchdata = await response.json();

      setDataDetails(fetchdata.data);
      const recommended = data.concat(fetchdata.data.data);
      setData(recommended);
      setLoad(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error(error.message || lang?.an_error_occured_fetching_date);
      throw error;
    }
  };

  const handleData = async () => {
    try {
      const response = await fetch(BaseURL + "api/course/v1/all/" + user_id);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const jsonData = await response.json();
      setDataDetails(jsonData.data);
      setData(jsonData.data.data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleData();
  }, []);
  return (
    <Container fluid className="bg-offwhite overflow-y-hidden">
      <Row>
        <NavBar />
      </Row>
      <Row className="mt-3 vh-100 overflowY-auto scroll-hide overflowX-none pb-5">
        {isLoading ? (
          <Col className="text-center">
            <Spinner />
          </Col>
        ) : (
          <>
            <div>
              <h1 className="fs-20 fs-md-16 roboto-medium">
                {lang?.all_courses}
              </h1>
            </div>
            <div
              className="d-flex gap-3 mb-5 justify-content-center"
              style={{ flexWrap: "wrap", flexDirection: "row" }}
            >
              {data?.map((course, index) => (
                <div key={index}>
                  <CoursePreview
                    certificates={course.certificates}
                    owner_username={course.owner_username}
                    owner_image={course.owner_image}
                    thumbnail={course.thumbnail}
                    image={course.cover}
                    price={course.price}
                    name={course.title}
                    course_id={course.id}
                  />
                </div>
              ))}
            </div>

            {dataDetails?.current_page !== dataDetails?.last_page ? (
              <Col className="text-center mb-5">
                <button
                  className="btn-blue border-0 px-5 py-2"
                  disabled={load}
                  onClick={() => {
                    HandleLoadMore(dataDetails.current_page);
                  }}
                >
                  {load ? (
                    <Col className="text-center">
                      <Spinner size="sm" color="black" />
                    </Col>
                  ) : (
                    lang?.load_more
                  )}{" "}
                  <IoMdArrowRoundDown
                    size={20}
                    className={load ? "d-none" : "d-inline"}
                  />
                </button>
              </Col>
            ) : (
              <></>
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default SeeAllCourses;
