import React, { useContext, useEffect, useState } from "react";
import { Col, Collapse, Container, Row, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
// Components
import NavBar from "../../components/NavBar";
import { BaseURL } from "../../constants/API";
import { CLINICAL_SESSIONS_DETAILS, PROFILE } from "../../constants/RoutesUrl";
// Images & Icons
import { IoIosPlayCircle } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import AddClinicalSession from "../../components/create-post/AddClinicalSession";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../../App";
function ClinicalSessions() {
  const { lang } = useContext(Language);
  const Navigate = useNavigate();
  // sessionStorage
  const SplashData = JSON.parse(sessionStorage.getItem("SplashData"));
  var user_id = SplashData?.user?.id;

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState("1");
  const [started, setStarted] = useState([]);
  const [data, setData] = useState([]);
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );

  // Functions
  const handleGetData = () => {
    setIsLoading(true);
    let type =
      tabs === "1" ? "all" : tabs === "2" ? "my" : tabs === "3" && "invited";

    fetch(BaseURL + `api/clinical-session/list/${type}/${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          if (type === "all") {
            sessionStorage.setItem(
              "prevClinicalSessionsData",
              JSON.stringify(data.data)
            );
            sessionStorage.setItem(
              "prevStartedClinicalSessionsData",
              JSON.stringify(data.started)
            );
          }

          setStarted(data.started);
          setData(data.data);
          setIsLoading(false);
        } else {
          toast.error(data.error);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // useEffect
  useEffect(() => {
    const clearSessionStorageOnReload = (event) => {
      sessionStorage.removeItem("prevClinicalSessionsData");
      sessionStorage.removeItem("prevStartedClinicalSessionsData");
      delete event["returnValue"];
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };
  }, []);

  useEffect(() => {
    let jsonData = null;
    let startedJsonData = null;

    try {
      const prevData = sessionStorage.getItem("prevClinicalSessionsData");
      const prevStarted = sessionStorage.getItem(
        "prevStartedClinicalSessionsData"
      );
      jsonData = prevData ? JSON.parse(prevData) : null;
      startedJsonData = prevStarted ? JSON.parse(prevStarted) : null;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    let type =
      tabs === "1" ? "all" : tabs === "2" ? "my" : tabs === "3" && "invited";

    if (startedJsonData !== null && jsonData !== null && type === "all") {
      setStarted(startedJsonData);
      setData(jsonData);
    } else {
      handleGetData();
    }
  }, [tabs]);
  return (
    <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
      <Row>
        <NavBar />
      </Row>
      <Row className="px-lg-5 px-md-4 px-sm-2 px-2">
        <Col xl={3} className="pt-3">
          <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
            <div
              onClick={() => setOpenSidebar(!openSidebar)}
              className="p-3 d-flex justify-content-between align-items-center cursor"
            >
              <h1 className="fs-20 fs-sm-16 m-0 roboto-bold">
                {lang?.clinical_sessions}
              </h1>
              <span className="d-xl-none">
                {openSidebar ? (
                  <CiCircleRemove size={22} />
                ) : (
                  <MdOutlineKeyboardArrowRight size={26} />
                )}
              </span>
            </div>
            <Collapse isOpen={openSidebar}>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "1" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("1");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("1");
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.all_sessions}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "2" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("2");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("2");
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.my_sessions}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "3" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("3");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("3");
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.invited_sessions}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs === "4" && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs("4");
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs("4");
                  }
                }}
              >
                <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                  {lang?.create_clinical_session}
                </h1>
              </div>
            </Collapse>
          </div>
        </Col>
        <Col
          xl={9}
          lg={12}
          md={12}
          sm={12}
          className="overflowY-auto scroll-hide vh-100 pt-3"
          style={{ paddingBottom: "9rem" }}
        >
          {tabs === "4" ? (
            <AddClinicalSession />
          ) : (
            <Col className="pb-3 vh-100 overflowY-auto scroll-hide">
              <div className="bg-white py-3 rounded border border-1 border-inherit">
                <Row className="px-3">
                  <Col className="text-center roboto-bold">
                    <h5 className="mb-0">
                      {tabs === "1"
                        ? lang?.all_sessions
                        : tabs === "2"
                        ? lang?.my_sessions
                        : tabs === "3"
                        ? lang?.invited_sessions
                        : ""}
                    </h5>
                  </Col>
                </Row>

                {isLoading ? (
                  <Row className="my-3 px-3">
                    <Col className="text-center">
                      <Spinner />
                    </Col>
                  </Row>
                ) : (
                  <>
                    {started.length > 0 && (
                      <StartedClinicalSessionCard data={started} />
                    )}

                    <Row className="px-3">
                      {data.map((value) => (
                        <Col
                          lg={4}
                          className="my-2 cursor"
                          onClick={() =>
                            Navigate(CLINICAL_SESSIONS_DETAILS + value.id)
                          }
                          key={value.id}
                        >
                          <div
                            className="p-3 rounded"
                            style={{
                              backgroundColor: "rgba(0, 198, 224,0.08)",
                            }}
                          >
                            <div className="d-flex gap-2">
                              <Link
                                to={PROFILE + "/" + value.user.id}
                                className="bg-white dropdown-avatar-shadow align-self-center rounded"
                                style={{
                                  maxWidth: "3rem",
                                  maxHeight: "3rem",
                                  padding: "1.8px",
                                }}
                              >
                                <img
                                  src={
                                    value.user.image
                                      ? BaseURL + value.user.image
                                      : Avatar
                                  }
                                  alt="picture"
                                  className="img-fluid rounded"
                                />
                              </Link>
                              <div className="align-self-center">
                                <Link
                                  to={PROFILE + "/" + value.user.id}
                                  className="fs-14 roboto-bold text-dark"
                                >
                                  {value.user.name}
                                </Link>
                                <p className="mb-0 fs-12 roboto-regular">
                                  @{value.user.user_name}
                                </p>
                              </div>
                            </div>

                            <hr style={{ opacity: "0.1" }} />

                            <div>
                              <h5 className="fs-20 roboto-bold">
                                {value.title.length > 25
                                  ? `${value.title.slice(0, 25)}...`
                                  : value.title}
                              </h5>
                              <p className="fs-14 roboto-regular">
                                {value.visibility} - {value.category}
                              </p>

                              <div className="d-flex gap-3">
                                <div>
                                  <p className="mb-0 text-secondary fs-14 roboto-medium">
                                    <FaCalendarAlt className="mb-1" />{" "}
                                    <UnixToDate
                                      timeStamp={value.start_timestamp}
                                    />
                                  </p>
                                </div>
                                {value.schedule_time && (
                                  <div>
                                    <p className="mb-0 text-secondary fs-14 roboto-medium">
                                      <AiFillClockCircle className="mb-1" />{" "}
                                      {value.schedule_time}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="mt-3">
                              <button className="btn-blue px-3 border-0 rounded py-2">
                                {lang?.view_session}
                              </button>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </div>
            </Col>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ClinicalSessions;

const StartedClinicalSessionCard = (props) => {
  const { lang } = useContext(Language);

  const Navigate = useNavigate();

  const { data } = props;
  return (
    <>
      <div className="d-flex gap-3 my-3 px-3 overflowX-auto scroll-hide">
        {data &&
          data.map((value) => (
            <div className="clinical-sec-card" key={value.id}>
              <div
                className="p-3 rounded cursor position-relative"
                onClick={() => {
                  Navigate(CLINICAL_SESSIONS_DETAILS + value.id);
                }}
                style={{ backgroundColor: "rgba(0, 198, 224,0.2)" }}
              >
                <h5 className="roboto-bold">{lang?.session_started}</h5>
                <p className="roboto-regular text-gray fs-14 mb-2">
                  {value.title}
                </p>
                <h6 className="roboto-medium">
                  {value.visibility} - {value.schedule_time} - {value.duration}{" "}
                  {lang?.mins}
                </h6>
                <h6 className="roboto-bold text-blue fs-15 mb-0">
                  {lang?.join_now}{" "}
                  <IoIosPlayCircle className="mb-1" size={20} />
                </h6>

                <div
                  className="position-absolute bottom-0 end-0"
                  style={{
                    backgroundColor: "rgba(245, 247, 249,0.5)",
                    borderTopLeftRadius: "5rem",
                    borderBottomRightRadius: "16px",
                    padding: "2.3rem",
                  }}
                ></div>
              </div>
            </div>
          ))}
      </div>
      <hr style={{ opacity: "0.2" }} />
    </>
  );
};

const UnixToDate = ({ timeStamp }) => {
  const unixTimestamp = timeStamp * 1000;
  const dateObject = new Date(unixTimestamp);

  // Define options for formatting the date
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  // Convert the date to the desired format
  const formattedDateString = dateObject.toLocaleString("en-US", options);

  return formattedDateString;
};
