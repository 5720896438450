import React, { FC } from 'react';

interface Props {
	className?: string;
}

export const PortraitAspectRatioIcon: FC<Props> = ({ className }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="33" height="43" xmlSpace="preserve" stroke="none">
			<path d="M33 43H0V0h33v43zM1 42h31V1H1v41z" />
			<path d="M14.2 19.1c0 .5-.1.9-.4 1.3-.3.3-.7.6-1.3.8 1.3.2 2 .8 2 1.9 0 .7-.3 1.3-.9 1.7-.6.4-1.4.6-2.4.6-.4 0-.8 0-1.2-.1-.4-.1-.7-.2-1.2-.3v-1.8c.3.2.7.3 1 .4.4.1.7.1 1 .1.5 0 .8-.1 1-.2.2-.1.3-.3.3-.6 0-.2-.1-.4-.2-.5s-.3-.2-.5-.3c-.2-.1-.6-.1-1-.1h-.5v-1.6h.5c1.1 0 1.6-.3 1.6-.8 0-.2-.1-.4-.2-.5 0-.1-.2-.1-.5-.1-.5 0-1 .2-1.6.5L8.9 18c.4-.3.8-.5 1.3-.6.4-.1.9-.2 1.5-.2.8 0 1.5.2 1.9.5s.6.8.6 1.4zM15.4 20.1c0-.3.1-.6.3-.8s.5-.3.8-.3c.4 0 .6.1.8.3s.3.4.3.8c0 .3-.1.6-.3.8s-.5.3-.8.3c-.4 0-.6-.1-.8-.3-.2-.2-.3-.5-.3-.8zm0 4.2c0-.3.1-.6.3-.8.2-.2.5-.3.9-.3s.6.1.8.3.3.4.3.8c0 .3-.1.6-.3.8-.2.2-.5.3-.8.3-.4 0-.6-.1-.8-.3-.3-.2-.4-.4-.4-.8zM24.4 23.7h-.9v1.5h-2.1v-1.5h-3.1v-1.6l3.3-4.8h2V22h.9v1.7zm-3-1.6V20h-.1c-.1.3-.3.5-.4.8l-.9 1.3h1.4z" />
		</svg>
	);
};
