import styled from 'styled-components';

const Text = styled.span`
  &,
  &:visited {
    color: red;
  }
  display: inline-block;
  font-family: inherit;
`;

Text.defaultProps = {
  color: 'green',
  fontWeight: 'normal'
};

export default Text;
