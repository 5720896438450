import React, { useContext, useState } from "react";
import NavBar from "../../components/NavBar";
import { FaArrowLeft } from "react-icons/fa";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Modal } from "reactstrap";
import { IoIosArrowForward } from "react-icons/io";
import { Language } from "../../App";

function GroupSettings() {
  const { lang } = useContext(Language);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  // Function to open the image modal
  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  // Function to close the image modal
  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  return (
    <div className="container-fluid  creategroupheight scroll-hide">
      <div className="row ">
        <NavBar />
      </div>
      <div className="row justify-content-center">
        <div className="col-md-5 mb-4 ">
          <div className="groupsettingheight">
            <div className="edit-profile br-8 pxy-20 bg-white box-shadow1 m-xl-0 m-lg-auto m-md-auto">
              <div className="arrow">
                {" "}
                <FaArrowLeft />
              </div>
              <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
                {lang?.group_info}
              </h3>

              <div className="d-flex justify-content-center mt-4">
                <div className="profile_img position-relative">
                  <img
                    src={Avatar}
                    alt="picture"
                    id="profileimage"
                    onClick={openImageModal}
                  />
                  <Modal
                    isOpen={isImageModalOpen}
                    centered
                    zIndex={9}
                    toggle={closeImageModal}
                  >
                    <img
                      src={Avatar}
                      alt="Profile Image"
                      className="img-fluid"
                    />
                  </Modal>
                </div>
                <div></div>
              </div>

              <div className="text-center">
                <h6 className="mt-2">dfsdf</h6>
                <h6>dfsdf</h6>
                <h6>dfsdf</h6>
              </div>

              <hr />

              <div className="d-flex flex-column ">
                <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4 ">
                  <div className="d-flex align-items-center gap-4 ">
                    <span></span>
                    <div>
                      <h3 className="fs-16  m-0">{lang?.edit_group_info}</h3>
                    </div>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>
                <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4 ">
                  <div className="d-flex align-items-center gap-4 ">
                    <span></span>
                    <div>
                      <h3 className="fs-16  m-0">{lang?.media_photo_videos}</h3>
                    </div>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>
              </div>

              <div className="d-flex flex-column mt-3 ">
                <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4 ">
                  <div className="d-flex align-items-center gap-4 ">
                    <span></span>
                    <div>
                      <h3 className="fs-16  m-0">
                        {lang?.manage_notifications}
                      </h3>
                    </div>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>
              </div>

              <div className="d-flex flex-column mt-3 ">
                <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4 ">
                  <div className="d-flex align-items-center gap-4 ">
                    <span></span>
                    <div>
                      <h3 className="fs-16  m-0">{lang?.add_participants}</h3>
                    </div>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>
                <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4 ">
                  <div className="d-flex align-items-center gap-4 ">
                    <span></span>
                    <div>
                      <h3 className="fs-16  m-0">{lang?.participants}</h3>
                    </div>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>
              </div>

              <div className="d-flex flex-column mt-3 ">
                <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4 ">
                  <div className="d-flex align-items-center gap-4 ">
                    <span></span>
                    <div>
                      <h3 className="fs-16  m-0" style={{ color: "red" }}>
                        {lang?.leave_group}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4 ">
                  <div className="d-flex align-items-center gap-4 ">
                    <span></span>
                    <div>
                      <h3 className="fs-16  m-0" style={{ color: "red" }}>
                        {lang?.report_group}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupSettings;
