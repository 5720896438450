import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FEED, SPECIALIZATION } from "./RoutesUrl";
const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUser = () => {
    const userToken = JSON.parse(localStorage.getItem("SignupData"));
    if (userToken?.data?.id) {
      setIsLoggedIn(false);
      if (
        userToken?.data?.user_type === "doctor" &&
        userToken?.data?.subcategory === ""
      ) {
        return navigate(SPECIALIZATION);
      } else {
        return navigate(FEED);
      }
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUser();
  }, [isLoggedIn]);
  return <>{isLoggedIn ? props.children : null}</>;
};
export default ProtectedRoute;
